import React, { useEffect, useRef, useState } from 'react';
import { Box} from '@chakra-ui/react';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';

const IndicatorsGauge = ({  data }) => {
  const chartRef = useRef(null);
  const [isLargerThan480, setIsLargerThan480] = useState(window.innerWidth > 480);

  const handleResize = () => {
    setIsLargerThan480(window.innerWidth > 480);
    const chart = echarts.getInstanceByDom(chartRef.current);
    if (chart) {
      chart.resize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const chart = echarts.init(chartRef.current);
    const option = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '75%'],
          radius: '100%',
          min: 0,
          max: 1,
          splitNumber: 40,
          itemStyle: {
            color: '#58D9F9',
            shadowColor: 'rgba(0,138,255,0.45)',
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
          axisLine: {
            lineStyle: {
              width: 46,
              color: [
                [0.25, '#aac0fe'],
                [0.45, '#728fea'],
                [0.55, '#3652ba'],
                [0.75, '#24388a'],
                [1, '#111c44']
              ]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '10%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 0,
            lineStyle: {
              color: 'auto',
              width: 0
            }
          },
          splitLine: {
            length: 0,
            lineStyle: {
              color: 'auto',
              width: 0
            }
          },
          axisLabel: {
            color: '#a3b9f8',
            fontSize: isLargerThan480 ? 14 : 12,
            fontWeight: 450,
            distance: isLargerThan480 ? -30 : -15,
            rotate: 'tangential',
            formatter: function(value) {
              if (value=== 0.875) {
                return 'Strong Buy';
              } else if (value=== 0.675) {
                return 'Buy';
              } else if (value=== 0.5) {
                return 'Neutral';
              } else if (value=== 0.325) {
                return 'Sell';
              } else if (value=== 0.125) {
                return 'Strong Sell';
              }
              return '';
            }
          },
          title: {
            offsetCenter: [0, '10%'],
            fontSize: isLargerThan480 ? 20 : 15,
            fontWeight: 600,
            backgroundColor : 'auto',
            borderRadius: 10,
            padding: 20,
            color: 'white',
          },
          detail: {
            fontSize: 18,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            formatter: function(value) {
              return 'Score: ' + Math.round(value * 100) + '%';
            },
            color: 'inherit'
          },
          data: [
            {
              value: data.score,
              name: (function(value) {
                if (value >= 0.75 && value <= 1) {
                  return 'Strong Buy';
                } else if (value >= 0.56 && value < 0.75) {
                  return 'Buy';
                } else if (value >= 0.46 && value < 0.56) {
                  return 'Neutral';
                } else if (value >= 0.25 && value < 0.46) {
                  return 'Sell';
                } else if (value >= 0 && value < 0.25) {
                  return 'Strong Sell';
                }
                return '';
              })(data.score) // Pass the initial value here
            }
          ]
        }
      ]
    };

    chart.setOption(option, true);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [data.score, isLargerThan480, data.negative, data.neutral, data.positive]);

  return (
    <Box
      ref={chartRef}
      w={isLargerThan480 ? "100%" : "80%"}
      h={isLargerThan480 ? '350px' : '250px'}
      marginLeft={isLargerThan480 ? "0" : "15%"}
      marginTop={isLargerThan480 ? "0" : "-10"}// This centers the chart by adding equal margin on both sides
    />

  );


}

export default IndicatorsGauge;
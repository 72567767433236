import React, { useEffect } from 'react';

const ForexHeatMapWidget = ({colorTheme}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "100%",
            "height": "100%",
            "currencies": [
                "EUR",
                "USD",
                "JPY",
                "GBP",
                "CHF",
                "AUD",
                "CAD",
                "NZD",
                "CNY"
            ],
            "isTransparent": false,
            "colorTheme": colorTheme,
            "locale": "en"
        });
        const widgetContainer = document.getElementById('tradingview_d29ad');
        if (widgetContainer) {
            widgetContainer.appendChild(script);
        }

        return () => {
            // Cleanup the script when the component unmounts
            if (widgetContainer && widgetContainer.contains(script)) {
                widgetContainer.removeChild(script);
            }
        };
    }, [colorTheme]);

    return (
        <div id='tradingview_d29ad' style={{'height': '450px', 'width': '100%', 'border': 'none'}}/>
    );
};

export default ForexHeatMapWidget;

import React from "react";
import ReactApexChart from "react-apexcharts";

class CurrencyVolatilityLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef()
        this.state = {
            chartRef: this.props.ref,
            chartData: [],
            chartOptions: this.props.chartOptions || {}, // Default to empty object if no options provided
        };
    }
    toggleSeries = (seriesName) => {
        const chart = this.chartRef.current;
        if (chart) {
            chart.chart.toggleSeries('AUD');
            chart.chart.toggleSeries('CAD');
            chart.chart.toggleSeries('CHF');
            chart.chart.toggleSeries('JPY');
            chart.chart.toggleSeries('NZD');
        }
    }
    componentDidMount() {
        this.processChartData(this.props.chartData);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.chartData) !== JSON.stringify(prevProps.chartData)) {
            this.processChartData(this.props.chartData);
        }
    }

    processChartData(rawData) {
        const forexChartData = rawData.map(currencyObj => {
            const currencyCode = currencyObj['Asset'];
            const data = currencyObj['Volatility'].map(entry => {
                const timestamp = parseInt(Object.keys(entry)[0], 10) * 1000; // Convert to milliseconds
                const strength = entry[Object.keys(entry)[0]];
                return { x: new Date(timestamp), y: strength*100 };
            });
            return { name: currencyCode, data };
        });
        this.setState({ chartData: forexChartData }, () => {
        });
    }


    render() {
        return (
            <>
                <ReactApexChart
                    ref={this.chartRef}
                    options={this.state.chartOptions}
                    series={this.state.chartData}
                    type="line"
                    width="100%"
                    height="100%"
                />
            </>
        );
    }
}

export default CurrencyVolatilityLineChart;

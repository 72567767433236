import React from 'react';
import { Image, keyframes, useColorMode, useColorModeValue, usePrefersReducedMotion } from '@chakra-ui/react';
import logoDark from './logo-dark.png';
import logoLight from './logo-light.png';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const AmetricaLogo = props => {
    const { colorMode } = useColorMode();
    const imageSrc = colorMode === 'light' ? logoDark : logoLight;
    const prefersReducedMotion = usePrefersReducedMotion();

    return(
        <a  href={`${process.env.PUBLIC_URL}/#/ametrica-fx/home`}>
            <Image src={imageSrc} {...props} />
        </a>
    );
};

// Chakra imports
import {
    Alert, AlertIcon,
    Avatar,
    Box,
    Button, Container,
    Flex, Grid, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Select, Text, useColorMode,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";
// Custom components
import React, {useEffect, useRef, useState} from "react";
import {FaCube, FaPenFancy} from "react-icons/fa";
import {IoDocumentsSharp} from "react-icons/io5";
import PairView from "../../components/PairView/PairView";
import PairAnalyticsOscilator from "../../components/PairAnalyticsComponents/PairAnalyticsOscilator";
import PairAnalyticsPricePrediction from "../../components/PairAnalyticsComponents/PairAnalyticsPricePrediction";
import SelectBar from "../../components/SelectBar/SelectBar";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SignInModal from "../../components/Modals/SignInModal";
import AmetricaFooter from "../../components/Footer/AmetricaFooter";
import GPTBanner from "../../components/MarketDynamicsComponents/GPTBanner";
import {useTranslation} from "react-i18next";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}
function PairAnalytics() {
    const query = useQuery();
    const [selectedPair, setSelectedPair] = useState("EURUSD"); // Add state for selected pair
    // Chakra color mode
    const { colorMode } = useColorMode();
    const textColor = useColorModeValue("gray.700", "white");
    const [userPlan, setUserPlan] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const history = useHistory();
    const oscilatorRef = useRef(null);
    const predictionRef = useRef(null);
    const { t } = useTranslation()
    // A single function that accepts a ref and scrolls to it
    const scrollToRef = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // Check if the 'pair' query parameter exists and update the state
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        if (localUserPlan === "") {
            onOpen();
        }
        const pairParam = query.get('pair');
        if (pairParam) {
            setSelectedPair(pairParam);
        }
    }, []); // Empty dependency array means this effect runs once on mount

    const handleSelectChange = (selectedValue) => {
        setSelectedPair(selectedValue)
        localStorage.setItem('selectedAsset', selectedValue);
        history.push(`/fx/pair-analytics?pair=${selectedValue}`);
    };

    return (
        <Container maxW={'7xl'}>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                mb='24px'
                maxH='330px'
                justifyContent={{ sm: "center", md: "space-between" }}
                borderColor={"gray.700"}
                border='1px solid'
                bg={useColorModeValue("white", "black")}
                p='24px'
                borderRadius='20px'>
                <SelectBar onSelectChange={handleSelectChange} value={selectedPair} />
                <Flex
                    align='center'
                    mb={{ sm: "10px", md: "0px" }}
                    direction={{ sm: "column", md: "row" }}
                    w={{ sm: "100%" }}
                    justifyContent={"center"}
                    textAlign={{ sm: "center", md: "center" }}>
                    <PairView pair = {selectedPair} textColor={textColor}/>
                    <Text fontSize='md' color={'gray.600'} fontWeight='bold' ml={1}>
                        {" "} {t('PairyAnalytics')}
                    </Text>
                </Flex>
                <Flex
                    direction={{ sm: "column", lg: "row" }}
                    w={{ sm: "100%", md: "50%", lg: "auto" }}>
                    <Button p='0px' bg='transparent' variant='no-effects'>
                        <Flex
                            align='center'
                            w={{ sm: "100%", lg: "135px" }}
                            bg={colorMode === "dark" ? "navy.900" : "#fff"}
                            borderRadius='8px'
                            justifyContent='center'
                            onClick={() => scrollToRef(oscilatorRef)}
                            py='10px'
                            boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.06)'
                            cursor='pointer'>
                            <Icon color={textColor} as={FaCube} me='6px' />
                            <Text fontSize='xs' color={textColor} fontWeight='bold'>
                                {t('Indicators')}
                            </Text>
                        </Flex>
                    </Button>
                    <Button p='0px' bg='transparent' variant='no-effects'>
                        <Flex
                            align='center'
                            w={{ lg: "135px" }}
                            borderRadius='15px'
                            justifyContent='center'
                            py='10px'
                            onClick={() => scrollToRef(predictionRef)}
                            mx={{ lg: "1rem" }}
                            cursor='pointer'>
                            <Icon color={textColor} as={IoDocumentsSharp} me='6px' />
                            <Text fontSize='xs' color={textColor} fontWeight='bold'>
                                {t('PricePrediction')}
                            </Text>
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
            <Box>
                <Grid
                    templateColumns={{ sm: "1fr", lg: "1fr" }}
                    templateRows={{ lg: "repeat(2, auto)" }}
                    gap='20px'>
                </Grid>
                <Box ref={predictionRef}>
                    <PairAnalyticsPricePrediction selectedPair={selectedPair} />
                </Box>
                <GPTBanner/>
                <Box ref={oscilatorRef}>
                    <PairAnalyticsOscilator selectedPair={selectedPair} />
                </Box>
            </Box>
            <Alert status='info' variant='left-accent' mt={10}>
                <AlertIcon />
                {t('DataWarningMessage')}
            </Alert>
            <Box >
                <AmetricaFooter />
            </Box>
            <SignInModal isOpen={isOpen} onClose={onClose} headerText={t('LoginFreePair')}/>
        </Flex>
            </Container>
    );
}

export default PairAnalytics;

import React, { useState } from 'react';
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import {Box, Flex} from "@chakra-ui/react";


const GoogleSignUpComponent = () => {
    const history = useHistory();
    const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-auth/';
    const [loading, setLoading] = useState(false);

    const handleSignUp = async (googleData) => {
        setLoading(true);
        try {
            const res = await axios.post(cloudFunctionURL, {
                type: "signup",
                token: googleData.credential,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            localStorage.setItem('_Attributes', res.data.jwt);
            history.push('/fx/market-dynamics');
        } catch (error) {
            console.error('Error logging in', error.response ? error.response.data.error : error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            bg={"white"}
            borderRadius={"5rem"}
            w='100%'
            h='45'
            maxW='100%'
            mt='0px'>
            <GoogleLogin
                clientId="442107147924-e4db3lq627puld5sf6q8o6oi6lthvgf0.apps.googleusercontent.com"
                onSuccess={handleSignUp}
                onFailure={handleSignUp}
                text={"signup_with"}
                size={"large"}
                width={"100%"}
                shape={"pill"}
                cookiePolicy={'single_host_origin'}
            />
        </Flex>

    );
};

export default GoogleSignUpComponent;

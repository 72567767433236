import {
    Container,
    Heading,
    Stack,
    Text,
    Box, useColorModeValue
} from '@chakra-ui/react';
import {useEffect} from "react";

export default function Impressum() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 3, md: 5 }}
                mt={20}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={700}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}>
                    Impressum
                </Heading>
                <Box px={20} py={20}  bg={useColorModeValue('white', 'gray.900')}
                     borderRadius={"20px"}>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Address:</strong><br />
                        Ametrica Teknoloji Anonim Şirketi<br />
                        FENERBAHÇE MAH. İĞRİP SOK. NO:13 İÇ KAPI NO:1<br />
                        KADIKÖY - KADIKÖY / İSTANBUL / TÜRKİYE
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Registered:</strong><br />
                        UID(Ticaret Sicil/Dosya No): 458513-5<br />
                        VAT No(Mersis No): 0069146752800001<br />
                        Commercial Register: T.C ISTANBUL TİCARET SİCİLİ MÜDÜRLÜĞÜ
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Represented by the Board of Directors:</strong><br />
                        Batuhan Cevher<br />
                        Alp Dede<br />
                        Ozer Batu Kargili
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Contact:</strong><br />
                        E-Mail: info@ametrica.io
                    </Text>
                </Box>
            </Stack>
        </Container>
    );
}

import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const PairView = ({ pair, textColor}) => {
    // Extract the first and last three characters from the pair
    const firstCurrency = pair.substring(0, 3);
    const secondCurrency = pair.substring(3, 6);

    return (
        <Box display="flex" alignItems="center" ml={'-2'}>
            <Flex justifyContent="center" alignItems="center" position="relative" w="40px" h="40px" mr={'10px'}>
                <Box position="absolute" w="24px" h="24px" overflow="hidden" borderRadius="50%" zIndex="2" border="1px solid white">
                    <Image src={`${process.env.PUBLIC_URL}/currency-logo/${firstCurrency.toLowerCase()}-logo.svg`} boxSize="24px" objectFit="cover"/>
                </Box>
                <Box position="absolute" w="24px" h="24px" overflow="hidden" borderRadius="50%" right="-3px" top="-3px" zIndex="1" border="1px solid white">
                    <Image src={`${process.env.PUBLIC_URL}/currency-logo/${secondCurrency.toLowerCase()}-logo.svg`} boxSize="24px" objectFit="cover"/>
                </Box>
            </Flex>
            <Text fontSize='md' color={textColor} fontWeight='bold'>
                {pair}{" "}
            </Text>
        </Box>
    );
};

export default PairView;

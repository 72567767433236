import {Box, Flex, Icon, Image, Select, Text, useColorMode, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import AmetricaFXProModal from "../../Modals/AmetricaFXProModal";
import CurrencyVolatilityRow from "./CurrencyVolatilityRow";
import InfoPopover from "../../PairAnalyticsComponents/Indicators/InfoPopover";
import {useTranslation} from "react-i18next";

function CurrencyVolatilityTable({data,icon}) {
    const textColor = useColorModeValue("gray.700", "white");
    const [selectedKey, setSelectedKey] = useState('1d');
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dataList, setdataList] = useState(data);
    const [userPlan, setUserPlan] = useState(null);
    const { colorMode } = useColorMode();
    const { t } = useTranslation()
    const intervalsDict = {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };
    useEffect(() => {
        setdataList(data)
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        setLoading(false)
    }, [data]);

    const handleIntervalChange = (event) => {
        const selectedValue = event.target.value;

        if (userPlan !== 'Premium' && selectedValue !== '1d') {
            setSelectedKey('1d');
            onOpen(); // Show modal or notification
        } else {
            setSelectedKey(selectedValue); // Allow changing selection
        }
    };
    const isOptionDisabled = (key) => {
        // For standard users, disable all options except '1D'
        return userPlan !== 'Premium' && key !== '1d';
    };
    const selectedData = data[selectedKey] || [];
    return (
        <Card        bg={
            colorMode === "dark"
                ? "gray.800"
                : "white"
        }
        p='0px'
        h={"620px"}
        maxW={{ sm: "320px", md: "100%" }}>
             <Box position="absolute" top="4" right="0"  mb="15px" mr={1} maxH={'20px'} color={'gray.400'}>
                <Flex alignItems="center">
                <Select defaultValue="1d"  onChange={handleIntervalChange}>
                                {Object.entries(intervalsDict).map(([key, value]) => (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {isOptionDisabled(key) ? `${value} ✨` : value}
                                    </option>
                                ))}
                            </Select>
                    <InfoPopover
                        header={t('PairVolatilityPerformance')}
                        context={t('PairVolatilityPerformanceContext')}
                    />
                </Flex>
                <AmetricaFXProModal isOpen={isOpen} onClose={onClose} />
            </Box>
            <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
                <Text color={useColorModeValue("gray.700", "white")} fontSize='lg' fontWeight='bold' mb='6px'>
                    {t('PairVolatilityChartHeader')}
                </Text>
            </Flex>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <CardBody>
                    <Flex direction='column' w='100%'>
                        {selectedData.map((row, idx) => {
                            return (
                                <CurrencyVolatilityRow
                                    name={row.Asset}
                                    logo={icon}
                                    value={row.Volatility}
                                    key={idx}
                                />
                            );
                        })}
                    </Flex>
                </CardBody>
            )}
        </Card>
    );
}

export default CurrencyVolatilityTable;

import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Button, Divider } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-articles-api';

const ArticleDetailPage = () => {
    const { title } = useParams();
    const [article, setArticle] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const res = await axios.post(cloudFunctionURL, {
                    article: title
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setArticle(res.data.article.article_detail);
            } catch (error) {
                console.error('Error fetching article:', error.response ? error.response.data.error : error.message);
            }
        };
        fetchArticle();
    }, [title]);

    const renderContent = (content) => {
        const lines = content.split('\n').filter(line => line.trim() !== '');
        return lines.map((line, index) => {
            if (line.startsWith('**') && line.endsWith('**')) {
                return (
                    <Text key={index} fontWeight="bold" mt={4}>
                        {line.replace(/\*\*/g, '')}
                    </Text>
                );
            }
            return (
                <Text key={index} mt={4}>
                    {line}
                </Text>
            );
        });
    };

    if (!article) {
        return <Text>Loading...</Text>;
    }

    return (
        <Box p={5}>
            <Heading as="h2" size="md">{article.title}</Heading>
            <Divider my={4} />
            {renderContent(article.content)}
            <Button mt={4} colorScheme="teal" onClick={() => history.push('/fx/learn-fx')}>Back</Button>
        </Box>
    );
};

export default ArticleDetailPage;

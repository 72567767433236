import {
    SimpleGrid,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import HotCard from "./HotCurrencyCards/HotCard";
import { CiWavePulse1 } from "react-icons/ci";
import {
    FaArrowDown,
    FaArrowUp,
    FaHotjar,
    FaChartLine
} from "react-icons/fa";
import HotCardStrength from "./HotCurrencyCards/HotCardStrength";
import HotCardVolatility from "./HotCurrencyCards/HotCardVolatility";
import { useTranslation } from 'react-i18next';

const HotCurrencyCardLayout = ({data}) => {
    // Chakra Color Mode
    const [hotData, setHotData] = useState({});
    const [technicalBuyData, settechnicalBuyData] = useState({});
    const [technicalSellData, settechnicalSellData] = useState({});
    const [mostVolatile, setmostVolatile] = useState({});
    const [topStrengthCurrency, settopStrengthCurrency] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    useEffect(() => {
        const hot_data = data.data.mi.hot_data
        setHotData(hot_data)
        settechnicalBuyData(hot_data["TechnicalBuyPair"])
        settechnicalSellData(hot_data["TechnicalSellPair"])
        setmostVolatile(hot_data["MostVolatilePair"])
        settopStrengthCurrency(hot_data["TopOverallStrength"])
        setLoading(false)
    }, [data]);
    return (
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' mb='20px'>
            {loading ? (
                <div></div>
            ) : (
                <>
                <HotCardStrength data={topStrengthCurrency} text={t('HotCardStrengthHeader')} icon={<FaHotjar h={"24px"} w={"24px"} color="white" />} iconBgColor={"blue.300"}/>
                <HotCardVolatility data={mostVolatile} text={t('HotCardVolatilityHeader')}  icon={<FaChartLine h={"24px"} w={"24px"} color="white" />} iconBgColor={"blue.300"}/>
                <HotCard data={technicalBuyData} text={t('HotCardBuyHeader')} icon={<FaArrowUp h={"24px"} w={"24px"} color="white" />} iconBgColor={"green.400"}/>
                <HotCard data={technicalSellData} text={t('HotCardSellHeader')} icon={<FaArrowDown h={"24px"} w={"24px"} color="white" />} iconBgColor={"red.300"}/>
        
                </>
             )}
                    
        </SimpleGrid>
    );
}
export default HotCurrencyCardLayout;
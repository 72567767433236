// Chakra imports
import {
  Avatar,
  Box,
  Button, Divider,
  Flex,
  HStack,
  Link, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue
} from "@chakra-ui/react";
import {
  HomeIcon,
  PersonIcon,
  RocketIcon
} from "components/Icons/Icons";
import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import {googleLogout} from "@react-oauth/google";
import decodeJWT from "../../variables/functions";
import {useTranslation} from "react-i18next";
import {MdInsights} from "react-icons/md";
import {HiSparkles} from "react-icons/hi";
import {BsMoonStarsFill, BsSun} from "react-icons/bs";
import Hero3 from "assets/img/Hero3.png";
import {IoIosContact, IoIosExit} from "react-icons/io";
import LanguageDropdown from "../Profile/LanguageDropdown";
export default function AuthNavbar(props) {
  const handleSignUpClick = () => {
    // Call the openModal function passed from the parent component
    const signUpPath = `${process.env.PUBLIC_URL}/#/ametrica-fx/signup`;
    window.location.href = signUpPath; // Redirects to the specified path
  };
  const { logo, logoText, secondary, ...rest } = props;
  // Chakra color mode
  let mainText = "white";
  let navbarIcon = useColorModeValue("black", "white");
  const [profile, setProfile] = useState(null); // Store retrieved profile
  let navbarPosition = "absolute";
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation()

  const redirectToProfile = () => {
    const profilePath = `${process.env.PUBLIC_URL}/#/fx/profile`;
    window.location.href = profilePath; // Redirects to the specified path
  };
  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.removeItem('_Attributes');
    localStorage.removeItem('_UserTraits');
    history.push('/market-dynamics');
  };
  useEffect(() => {
    const userFromStorage = localStorage.getItem('_Attributes');
    if (userFromStorage) {
      const decodedToken = decodeJWT(userFromStorage);
      setProfile(decodedToken);
    }
  }, []);
  class ProfilePicture extends React.Component<{}> {
    render() {
      return (
          <Flex alignItems={'center'}>
            <Box>
              <Menu>
                  <MenuButton
                      as={Button}
                      rounded={'full'}
                      variant={'link'}
                      cursor={'pointer'}
                      sx={{
                        border: '4px solid',
                        borderColor: "white.900",
                      }}
                      minW={0}>
                    <Avatar
                        size={'md'}
                        src={profile.picture}
                    />
                  </MenuButton>
                <MenuList zIndex={3}>
                  <MenuList minW="0" w={'100%'}>
                    <MenuItem icon={<IoIosContact />} onClick={redirectToProfile} fontSize={"sm"}>Profile Settings</MenuItem>
                    <MenuItem icon={<IoIosExit />}  onClick={logOut} fontSize={"sm"} color={"red.500"}>Logout</MenuItem>
                  </MenuList>
                </MenuList>
              </Menu>
            </Box>
          </Flex>

      );
    }
  }
  let brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/`}
      target="_blank"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack direction="row" spacing="12px" align="center" justify="center">
        {colorMode === "dark" ? (
            <AmetricaLogo/>
        ) : (
            <AmetricaLogo/>
        )}
      </Stack>
    </Link>
  );
  var linksAuth = (
    <HStack display={{ sm: "none", lg: "flex" }}>
      <Link
          href={`${process.env.PUBLIC_URL}/#/fx/market-dynamics`}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="no-effects"
          leftIcon={<MdInsights color={navbarIcon} w="12px" h="12px" me="0px" />}
        >
          <Text>Analytics</Text>
        </Button>

      </Link>
      <Link
          href={`${process.env.PUBLIC_URL}/#/fx/premium`}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="no-effects"
          leftIcon={
            <HiSparkles color={navbarIcon} w="12px" h="12px" me="0px" />
          }
        >
          <Text>Premium</Text>
        </Button>
      </Link>
      <Link
          href={`${process.env.PUBLIC_URL}/#/fx/about`}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="no-effects"
          leftIcon={
            <HomeIcon color={navbarIcon} w="12px" h="12px" me="0px" />
          }
        >
          <Text>About</Text>
        </Button>
      </Link>
      <Button
          aria-label="Toggle Color Mode"
          onClick={toggleColorMode}
          size={"sm"}
          _focus={{ boxShadow: 'none' }}
          w="fit-content">
        {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
      </Button>
      <Flex align='center'>
        <LanguageDropdown/>
      </Flex>
    </HStack>
  );
  return (
        <Flex
          position={navbarPosition}
          top="0"
          left="50%"
          transform="translate(-50%, 0px)"
          px="16px"
          py="22px"
          mx="auto"
          width="1044px"
          maxW="100%"
          alignItems="center"
          zIndex="3"
          borderBottom="1px solid"
          borderBottomColor="gray.300"
        >
          <Flex w="100%" justifyContent={{ sm: "start", lg: "start" }}>
            {brand}
          </Flex>
          <Flex w="100%" justifyContent={{ sm: "end", lg: "end" }}>
            {linksAuth}
            {profile ? (
                <ProfilePicture />
            ) : (
                <Button
                    ml={{ sm: 10, md: "none", xl: "none" }}
                    bgColor={"teal"}
                    borderRadius="20px"
                    color={useColorModeValue("white", "white")}
                    onClick={handleSignUpClick}
                >
                  {t('AdminNavbarLinksStartFree')}
                </Button>
            )}
          </Flex>
        </Flex>
  );
}

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid, Icon,
    Progress, Select,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead, Tooltip,
    Tr,
    useColorMode,
    useColorModeValue, useDisclosure,
} from "@chakra-ui/react";

import React, {useEffect, useState} from "react";
import TablesProjectRow from "../Tables/TablesProjectRow";

const TopListTable = ({ data, interval }) => {
    // Chakra Color Mode
    const textColor = useColorModeValue("navy.900", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const { colorMode } = useColorMode();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userPlan, setUserPlan] = useState(null);

    useEffect(() => {
        const processedData = data.map(([pair, value]) => {
            let status = '';
            if (value >= 0.75 && value <= 1) {
                status = 'Strong Buy';
            } else if (value >= 0.56 && value < 0.75) {
                status = 'Buy';
            } else if (value >= 0.46 && value < 0.56) {
                status = 'Neutral';
            } else if (value >= 0.25 && value < 0.46) {
                status = 'Sell';
            } else if (value >= 0 && value < 0.25) {
                status = 'Strong Sell';
            }
            return {
                name: pair,
                status: status,
                interval: interval, // Fixed to "daily"
                progression: parseFloat(value * 100).toFixed(1), // Assuming progression is related to the value
            };
        });
        setDataList(processedData);
        setLoading(false);
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Table variant="simple" color={textColor}>
            <Thead>
                <Tr my=".8rem" pl="0px">
                    <Th color={textColor} borderColor={borderColor}>Pairs</Th>
                    <Th color={textColor} borderColor={borderColor}>Interval</Th>
                    <Th color={textColor} borderColor={borderColor}>Status</Th>
                    <Th color={textColor} borderColor={borderColor}>Score</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {dataList.map((row, index) => (
                    <TablesProjectRow
                        name={row.name}
                        status={row.status}
                        interval={row.interval}
                        progression={row.progression}
                        isLast={index === dataList.length - 1}
                        key={index}
                    />
                ))}
            </Tbody>
        </Table>
    );
};

export default TopListTable;



// chakra imports
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import PremiumUpgrade from "../views/Pages/PremiumUpgrade";
import SignIn from "../views/Pages/SignIn";
import SignUp from "../views/Pages/SignUp";
import Verify from "../views/Pages/Verify";
import Landing from "../views/Pages/Landing";
import AmetricaFooter from "../components/Footer/AmetricaFooter";
import CookiesPolicy from "../components/Legal/CookiesPolicy";
import Impressum from "../components/Legal/Impressum";
import PrivacyPolicy from "../components/Legal/PrivacyPolicy";
import TermsofService from "../components/Legal/TermsServices";


export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getActiveRoute = (routes) => {
    let activeRoute = "Premium";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/ametrica-fx") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const navRef = React.useRef();
  document.documentElement.dir = "ltr";
  return (
    <Box ref={navRef} w='100%'>
      <Portal containerRef={navRef}>
        <AuthNavbar secondary={getActiveNavbar(routes)} logoText='' />
      </Portal>
      <Box w='100%'>
        <Box ref={wrapper} w='100%'>
          <Switch>
            {getRoutes(routes)}
            <Route
                path={'/ametrica-fx/signup'}
                component={SignUp}
                key={'signup'}
            />
            <Route
                path={'/ametrica-fx/verify'}
                component={Verify}
                key={'verify'}
            />
            <Route
                path={'/ametrica-fx/home'}
                component={Landing}
                key={'home'}
            />
            <Route
                path={'/ametrica-fx/cookies-policy'}
                component={CookiesPolicy}
                key={'cookies-policy'}
            />
            <Route
                path={'/ametrica-fx/impressum'}
                component={Impressum}
                key={'impressum'}
            />
            <Route
                path={'/ametrica-fx/privacy-policy'}
                component={PrivacyPolicy}
                key={'privacy-policy'}
            />
            <Route
                path={'/ametrica-fx/terms-of-service'}
                component={TermsofService}
                key={'terms-of-service'}
            />
            <Redirect from='/ametrica-fx' to='/ametrica-fx/login-page' />
          </Switch>
        </Box>
      </Box>
      <Box mx='auto' maxW='100%' mt='60px'>
        <AmetricaFooter />
      </Box>
    </Box>
  );
}

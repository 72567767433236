import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    List,
    ListItem,
    ListIcon,
    useDisclosure, Box, Flex
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {AmetricaIconLogo} from "../Sidebar/AmetricaIconLogo";
import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import {useHistory} from "react-router-dom";





function AmetricaFXProModal({ isOpen, onClose }) {
    const history = useHistory();

    const upgradeButtonClick = () => {
        history.push("/fx/premium")
        onClose()
    };

    const handleCloseModal = () => {
        onClose
        history.push('/'); // Redirect to homepage
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size={"xl"}>
                <ModalOverlay />
                <ModalContent borderRadius="lg">
                    <ModalHeader
                        paddingY="6"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        background="black"
                        borderBottom="1px solid"
                        borderBottomColor="gray.200"
                    >
                        <Box>
                            <AmetricaLogo/>
                        </Box>
                        <ModalCloseButton color="gray.500" position="absolute" left="4" top="4" />
                    </ModalHeader>

                    <ModalBody p={6}
                               display="flex"
                               flexDirection="column"
                               alignItems="center"
                               bg={"black"}
                               justifyContent="center">
        
                     <Flex as="span" position="relative" >
                        <Text fontSize={{ sm: "xl", md: "2xl", lg: "3xl" }} fontWeight="bold" color="white">
                            AmetricaFX
                        </Text>
                        <Text
                            as="span"
                            fontSize={{ sm: "xl", md: "2xl", lg: "3xl" }}
                            fontWeight="bold"
                            bgGradient="linear-gradient(216.66deg, rgb(29, 155, 240) 2.89%, rgb(0, 131, 235) 97.26%);"
                            bgClip="text"
                            ml={2}
                        >
                            Premium
                        </Text>
                        <Text>

                        ✨
                        </Text>
                    </Flex>
                        <Text fontSize="lg" fontWeight="normal" color="gray.500">
                            Upgrade your AmetricaFX plan to get unlimited access.
                        </Text>
                        <Box mt={3} border="1px solid" borderColor={"#1D9BF0"} p={10} borderRadius="4">
                            <List spacing={3}>
                                <ListItem>
                                    ⌛ Effortlessly Analyze Market Movements.
                                </ListItem>
                                <ListItem>
                                    💱 Enjoy Unlimited Access to Every Asset.
                                </ListItem>
                                <ListItem>
                                    ❇️ AI Fine-Tuned Indicators, Providing a Strategic Edge in Market Analysis.
                                </ListItem>
                                {/* ... */}
                                <ListItem>
                                    🔬  Price Range Predictions, Clear Entry and Exit Targets.
                                </ListItem>
                            </List>
                        </Box>
                    </ModalBody>

                    <ModalFooter backgroundColor="black" borderRadius="0 0 lg 0" justifyContent="center"
                                 borderTop="1px solid"
                                 borderTopColor="gray.200">
                        <Button   bg={"#1D9BF0"}
                                  color="white" onClick={upgradeButtonClick}>Upgrade</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default AmetricaFXProModal;
// Chakra imports
import {
    Grid,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import MomentumOverviewTable from "./MomentumTables/MomentumOverviewTable";
import ForexCalendar from "./ForexCaLendar";
import { useTranslation } from 'react-i18next';


const MomentumOverviewLayout = ({data}) => {
    const [momentumData, setMomentumData] = useState({});
    const [gainerData, setMomentumGainerData] = useState({});
    const [loserData, setMomentumLoserData] = useState({});
    const [loading, setLoading] = useState(true);
    const textColor = useColorModeValue("navy.900", "white");
    const { t } = useTranslation()
    useEffect(() => {
        const momentum_data = data.data.mi.momentum
        setMomentumData(momentum_data)
        setMomentumGainerData(momentum_data["MomentumTopGainers"])
        setMomentumLoserData(momentum_data["MomentumTopLosers"])
        setLoading(false);

    }, [data]);
    return (
        <Grid
            templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap='20px'
            mt={5}>
            <MomentumOverviewTable data={gainerData} title={t("MomentumGainers")} icon={FaArrowUp} />
            <MomentumOverviewTable data={loserData}  title={t("MomentumLosers")} icon={FaArrowDown} />
        </Grid>
    );
}
export default MomentumOverviewLayout;



// slices/assetSlice.js
import { createSlice,createAsyncThunk  } from '@reduxjs/toolkit';
import axios from 'axios';

//overview.js
export const fetchOverviewResources = createAsyncThunk(
    'asset/fetchOverviewResources',
    async () => {
      try {
        let host = process.env.REACT_APP_BACKEND_HOST;
        const responses = await Promise.all([
          axios.get('https://backend.ametrica.io/api/market-insights/get_overview_momentum_data'),
          axios.get('https://backend.ametrica.io/api/market-insights/get_volatility_ranking'),
          axios.get('https://backend.ametrica.io/api/market-insights/get_price_prediction?asset=BTC'),
          axios.get('https://backend.ametrica.io/api/market-insights/get_correlation'),
          axios.get('https://backend.ametrica.io/api/market-insights/get_volume'),
          axios.get('https://backend.ametrica.io/api/market-insights/get_portfolio'),
        ]);
        return {
          momentum: responses[0].data["Momentum"],
          volatility: responses[1].data["Volatility"]["VolatilityTopGainers"],
          btcPrediction: responses[2].data,
          correlation: {
            gainers: responses[3].data["Corr"]["CorrTop10"],
            losers: responses[3].data["Corr"]["CorrBottom10"]
          },
          volume: responses[4].data["Volume"]["VolumeGainers"],
          portfolioWeights: responses[5].data["Portfolio"]["Weights"]
        };
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
);


//marketInsights.js
export const fetchOverviewData = createAsyncThunk(
    'asset/fetchOverviewData',
    async () => {
      try {
        const response = await axios.get('https://k9j2x2b329.execute-api.eu-central-1.amazonaws.com/default/market-insight-overview-api');
        return response.data.body;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
);


//assetOverview.js
export const fetchTAData = createAsyncThunk(
    'asset/fetchTAData',
    async (assetsymbol) => {
      const ta_response = await axios.get(`https://pckd0odu2g.execute-api.eu-central-1.amazonaws.com/beta-1/ametrica-fx-api-beta/?targetInsight=MarketOverview`);
      return ta_response.data;
    }
);


export const assetSlice = createSlice({
  name: 'asset',
  initialState: {
    asset: 'EURUSD',
    stats: {},
    ta: {},
    overview: {},
  },
  reducers: {
    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setTA: (state, action) => {
      state.ta = action.payload;
    },
    setOverview: (state, action) => {
      state.overview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchTAData.fulfilled, (state, action) => {
          state.ta = action.payload;
        })
        .addCase(fetchOverviewData.fulfilled, (state, action) => {
          state.overview = action.payload;
        })
        .addCase(fetchOverviewResources.fulfilled, (state, action) => {
          state.overviewResources = action.payload;
        });
  },
});

export const { setAsset, setStats, setTA, setOverview } = assetSlice.actions;
export const selectAsset = (state) => state.asset.asset;
export const selectStats = (state) => state.asset.stats;
export const selectTA = (state) => state.asset.ta;
export const selectOverview = (state) => state.asset.overview;
export default assetSlice.reducer;

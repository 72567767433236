import { Stack, Box, Divider, Flex, Spinner, Text } from '@chakra-ui/react';
import ChatMessage from './ChatMessage';
import { useEffect, useRef } from 'react';

const ChatHistory = ({ messages, isLoading }) => {
  const messageListRef = useRef(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight; // Scroll to bottom
    }
  }, [messages]);

  const calculateOpacity = (index, length) => 1 - index / (length - 1);

  return (
      <Stack ref={messageListRef} spacing={4} overflowY="auto" maxHeight="500px" mx={{ sm: 2, md: 40, xl: 40 }}
             sx={{
               '&::-webkit-scrollbar': {
                 width: '10px', // Thin scrollbar
                 borderRadius: '16px', // Radius
                 backgroundColor: `rgba(0, 0, 0, 0.05)`, // Scrollbar track color
               },
               '&::-webkit-scrollbar-thumb': {
                 backgroundColor: 'gray.800', // Scrollbar color (blue)
                 borderRadius: '16px', // Radius
               }
             }}
      >
        {messages.map((message, index) => (
            <Box key={index}>
              <ChatMessage
                  message={message.text}
                  isUser={message.isUser}
                  style={{ opacity: calculateOpacity(index, messages.length) }}
              />
              {index % 2 !== 0 && index !== messages.length - 1 && (
                  <Divider mt="2" mb="10" borderColor={"gray.700"} borderWidth="0.1" />
              )}
            </Box>
        ))}
        {isLoading && (
            <Flex justify="center" align="center" mt={4}>
              <Spinner size="lg" />
              <Text ml={2}>Analyzing...</Text>
            </Flex>
        )}
      </Stack>
  );
};

export default ChatHistory;

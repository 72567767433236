import {
    Container,
    Heading,
    Stack,
    Text,
    Box, useColorModeValue
} from '@chakra-ui/react';
import {useEffect} from "react";

export default function CookiesPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 3, md: 5 }}
                mt={20}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={700}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}>
                    Cookies Policy
                </Heading>
                <Box px={20} py={20}  bg={useColorModeValue('white', 'gray.900')}
                     borderRadius={"20px"}>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}>
                        This Cookie Policy explains how Ametrica Teknoloji Anonim Şirketi ("we", "us", "our") uses cookies and similar technologies to recognize you when you visit our website at fx.ametrica.io. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>1. What are cookies?</strong><br />
                        Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>2. Why do we use cookies?</strong><br />
                        We use first and third-party cookies for several reasons. Some cookies are required for technical reasons for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for advertising, analytics, and other purposes.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>3. Types of cookies we use</strong><br />
                        <strong>Essential Cookies:</strong> These cookies are strictly necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you that amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Performance and Functionality Cookies:</strong> These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Analytics and Customization Cookies:</strong> These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you. We use <a href="https://analytics.google.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Google Analytics</a> for this purpose.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>Advertising Cookies:</strong> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests. We use <a href="https://www.facebook.com/business/tools/meta-pixel" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Meta Pixel</a> for this purpose.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>4. How can I control cookies?</strong><br />
                        You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>5. Changes to this Cookie Policy</strong><br />
                        We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        <strong>6. Contact us</strong><br />
                        If you have any questions about our use of cookies or other technologies, please email us at info.ametrica.io
                    </Text>
                    <Text
                        fontWeight={400}
                        textAlign={"left"}
                        fontSize={{ base: 'xl', sm: 'lg', md: 'xl' }}
                        color={useColorModeValue('gray.700', 'gray.100')}
                        maxW={'4xl'}
                        mt={5}>
                        Ametrica Teknoloji Anonim Şirketi<br />
                        FENERBAHÇE MAH. İĞRİP SOK. NO:13 İÇ KAPI NO:1 KADIKÖY -<br />
                        KADIKÖY / İSTANBUL / TÜRKİYE
                    </Text>
                </Box>
            </Stack>
        </Container>
    );
}

import {
    Box,
    Flex,
    Stat,
    StatLabel,
    Text,
    useColorMode,
    useColorModeValue,Tooltip,
    Select, useDisclosure, StatHelpText
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import PairView from "../../PairView/PairView";
import IconBox from "../../Icons/IconBox";
import Card from "../../Card/Card";
import AmetricaFXProModal from "../../Modals/AmetricaFXProModal";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const HotCard = ({data, text, icon, iconBgColor}) => {
    // Chakra Color Mode
    const iconBoxInside = useColorModeValue("white", "white");
    const textColor = useColorModeValue("navy.900", "white");
    const [selectedKey, setSelectedKey] = useState('1d');
    const [loading, setLoading] = useState(true);
    const [topData, setTopData] = useState(data);
    const [userPlan, setUserPlan] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode } = useColorMode();
    const history = useHistory();
    const { t } = useTranslation();
    const intervalsDict = {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };

    useEffect(() => {
        setTopData(data)
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        setLoading(false)
    }, [data]);


    const handleSelectChange = (event) => {
        if (userPlan !== 'Premium' && event.target.value !== '1d') {
            onOpen()
        } else {
            setSelectedKey(event.target.value);
            // Update displayed data based on the selected time interval
        }
    };

    const isOptionDisabled = (key) => {
        // For standard users, disable all options except '1D'
        return userPlan !== 'Premium' && key !== '1d';
    };
    const handleIntervalChange = (event) => {
        const selectedValue = event.target.value;

        if (userPlan !== 'Premium' && selectedValue !== '1d') {
            setSelectedKey('1d');
            onOpen(); // Show modal or notification
        } else {
            setSelectedKey(selectedValue); // Allow changing selection
        }
    };
    const selectedData = data[selectedKey][0] || [];

    return (
            <Card h='150px' p={4}  bg={
                colorMode === "dark"
                    ? "gray.800"
                    : "white"
            }
            border="2px solid"
            borderColor={iconBgColor}
            boxShadow="xl"
            _hover={{ boxShadow: "2xl" }}
            >
                <Box>
                    <Box position="absolute" top="2" right="0"  mb="15px" mr={1} maxH={'20px'} color={'gray.400'}>
                    <Flex alignItems="center">
                            <Select defaultValue="1d"  onChange={handleIntervalChange}>
                                {Object.entries(intervalsDict).map(([key, value]) => (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {isOptionDisabled(key) ? `${value} ✨` : value}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                        <AmetricaFXProModal isOpen={isOpen} onClose={onClose} />
                    </Box>
                    <Flex direction='column'>
                        <Flex
                            flexDirection='row'
                            align='center'
                            justify='center'
                            w='100%'
                            mb='25px'>
                            <Stat me='auto'>
                                <StatLabel
                                    fontSize='sm'
                                    color={textColor}
                                    fontWeight='bold'
                                    mb={'4'}
                                    >
                                    {text}
                                </StatLabel>
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <Tooltip label={t("ShowMoreAboutPair")} openDelay={500} bg={useColorModeValue("navy.900", "gray.700")} color={"white"} borderRadius={"8px"}>
                                        <Box
                                            key={selectedKey}
                                            p={2}
                                            borderRadius={"8px"}
                                            onClick={() => history.push(`/fx/pair-analytics?pair=${selectedData[0]}`)}
                                            cursor="pointer"
                                            _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
                                        >
                                        <PairView pair = {selectedData[0]} textColor={textColor}/>
                                        </Box>
                                </Tooltip>
                                )}
                                <StatHelpText>
                                    <Text as='span' color={iconBgColor} fontWeight='bold' fontSize={"xl"}>
                                        {selectedData[1]*100}%
                                    </Text>
                                </StatHelpText>
                            </Stat>
                            
                            <IconBox borderRadius='50%' as='box' h={"45px"} w={"45px"} mt={'8'} bg={iconBgColor}>
                                {icon}
                            </IconBox>
                        </Flex>
             
                    </Flex>
                </Box>
            </Card>
    );
}
export default HotCard;
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, useColorMode, Button, Box, IconProps, useBreakpointValue, AvatarGroup, Avatar,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {MdInsights} from "react-icons/md";
import AIERA from "../../assets/img/Hero2.png";
import eur from "../../assets/img/currency-logo/eur-logo.svg";
import usd from "../../assets/img/currency-logo/usd-logo.svg";
import cad from "../../assets/img/currency-logo/cad-logo.svg";
import chf from "../../assets/img/currency-logo/chf-logo.svg";
import jpy from "../../assets/img/currency-logo/jpy-logo.svg";



const avatars = [
    {
        url: eur,
    },
    {
        url: usd,
    },
    {
        url: cad,
    },
    {
        url: chf,
    },
    {
        url: jpy,
    },
]

const Blur = (props: IconProps) => {
    return (
        <Icon
            width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
            zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
            height="560px"
            viewBox="0 0 528 560"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="71" cy="61" r="111" fill="#AACCFF" />
            <circle cx="244" cy="106" r="139" fill="#7788AA" />
            <circle cy="291" r="139" fill="#445588" />
            <circle cx="80.5" cy="189.5" r="101.5" fill="#334477" />
            <circle cx="196.5" cy="317.5" r="101.5" fill="#223366" />
            <circle cx="70.5" cy="458.5" r="101.5" fill="#112255" />
            <circle cx="426.5" cy="-0.5" r="101.5" fill="#001144" />
        </Icon>
    )
}

interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function CurrencyBanner() {
    const handleClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/fx/currency-overview`;
        window.location.href = navPath; // Redirects to the specified path
    };
    return (
        <Container maxW={'7xl'} mt={5} mb={5} py={2} px={10}
                   backgroundImage={AIERA} // Ensure this is the correct path to your image
                   backgroundSize="cover"
                   backgroundPosition="center"
                   borderRadius={"15px"} boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'} >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4} py={10}>
                    <Heading color="white" fontSize={'3xl'}>Get More Insights About Currencies</Heading>
                    <Button width={'80%'}
                            borderRadius={"15px"}
                            rightIcon={<MdInsights />}
                            bgColor='white' color={"teal"}
                            variant='solid'
                            onClick={handleClick}
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'sm',
                            }}
                    >
                        Currency Overview
                    </Button>
                </Stack>
                <Stack direction={'row'} spacing={2} align={'center'}>
                    <AvatarGroup>
                        {avatars.map((avatar) => (
                            <Avatar
                                key={avatar.name}
                                src={avatar.url}
                                size={useBreakpointValue({ base: 'md', md: 'lg' })}
                                position={'relative'}
                                zIndex={2}
                                _before={{
                                    content: '""',
                                    width: 'full',
                                    height: 'full',
                                    rounded: 'full',
                                    transform: 'scale(1.125)',
                                    bg: "teal",
                                    position: 'absolute',
                                    zIndex: -1,
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        ))}
                    </AvatarGroup>
                </Stack>

            </SimpleGrid>
        </Container>
    );
}
import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box, Spinner, Stack, Heading, Text, Divider, Flex,useColorModeValue
} from '@chakra-ui/react';
import IndicatorsGauge from './IndicatorsGauge';

const IndicatorsTable = ({data, asset}) => {
  const [taIndicatorValues, setTaIndicatorValues] = useState({});
  const [taStats, setTaStats] = useState({});
  const [loading, setLoading] = useState(true);
  const textColor = useColorModeValue("navy.900", "white");

  useEffect(() => {
      try {
        const response_data = data.data.ta
        setTaIndicatorValues(response_data["optimized_indicator_values"]);
        setTaStats(response_data["statistics"]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching new data:', error);
      }
  }, [data]);

  return (
        <Box>
          {loading ? (
              <Spinner mt={'5rem'} size="xl" color={"black"} thickness="2px"  />
          ) : (
              <>
              <IndicatorsGauge data={taStats}/>
          <Box
            height="150px"  // Set a specific height for the table container
            overflowY="auto"  // Enable vertical scrolling
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px', // Thin scrollbar
                borderRadius: '16px', // Radius
                backgroundColor: `rgba(0, 0, 0, 0.05)`, // Scrollbar track color
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#003776', // Scrollbar color (blue)
                borderRadius: '16px', // Radius
              }
            }}
          >
            <Heading mb={2} fontSize="md" fontWeight={600} fontFamily="body" color={textColor}  textAlign="left" borderBottom="1px solid #003776">
              Indicator Details
            </Heading>
            <Table size="sm" variant='striped' colorScheme='blackAlpha'>
              <Thead>
                <Tr>
                  <Th style={{ fontWeight: 'bold', fontSize: '12px', color: {textColor} }}>Indicator Name</Th>
                  <Th style={{ fontWeight: 'bold', fontSize: '12px', color: {textColor} }}>Indicator Value</Th>
                  <Th style={{ fontWeight: 'bold', fontSize: '12px', color: {textColor} }}>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {taIndicatorValues && Object.entries(taIndicatorValues).map(([key, value]) => (
                    <Tr key={key}>
                      <Td fontSize="12px">{value.name}</Td>
                      <Td fontSize="12px">{Number(value.value).toFixed(5)}</Td>
                      <Td fontSize="12px" fontWeight='bold'>
                      {value.status === '1' || value.status === '1.0' ? (
                          <Text color="green.400">Buy</Text>
                      ) : value.status === '-1' || value.status === '-1.0' ? (
                          <Text color="red.400">Sell</Text>
                      ) : (
                          <Text color="yellow.400">Neutral</Text>
                      )}
                    </Td>

                    </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
              </>
            )}
        </Box>
  );
};
export default IndicatorsTable;

/*eslint-disable*/
import {AddIcon, HamburgerIcon} from "@chakra-ui/icons";
// chakra imports
import {
  Avatar, Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex, Icon, Menu, MenuButton, MenuItem, MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderTrackRTL,
  renderView,
  renderViewRTL
} from "components/Scrollbar/Scrollbar";
import { HSeparator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React, {useEffect, useState} from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {FaEllipsis} from "react-icons/fa6";
import decodeJWT from "../../variables/functions";
import {ProfileIcon} from "../Icons/Icons";
import SignInModal from "../Modals/SignInModal";
import {googleLogout} from "@react-oauth/google";
import {IoIosContact, IoIosExit} from "react-icons/io";

import { useTranslation } from 'react-i18next';
import {BsMoonStarsFill, BsSun} from "react-icons/bs";
import LanguageDropdown from "../Profile/LanguageDropdown";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const [profile, setProfile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();

  function useRouteTranslation() {
    const { t } = useTranslation();
    return (key) => t(key);
  }
  
  const translateRouteName = useRouteTranslation(); // Call the function to translate
  
  useEffect(() => {
    const userFromStorage = localStorage.getItem('_Attributes');
    if (userFromStorage) {
      const decodedToken = decodeJWT(userFromStorage);
      setProfile(decodedToken);
      localStorage.setItem('_UserTraits', decodedToken.userPlan);
    }
    else {
      localStorage.setItem('_UserTraits', "");
    }
  }, []);
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  // Chakra Color Mode
  const redirectToProfile = () => {
    const profilePath = `${process.env.PUBLIC_URL}/#/fx/profile`;
    window.location.href = profilePath; // Redirects to the specified path
  };
  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.removeItem('_Attributes');
    localStorage.removeItem('_UserTraits');
    history.push('/market-dynamics');
  };

  const colorModeChange = () => {
    toggleColorMode()
    window.location.reload()
  };

  const handleSignInClick = () => {
    onOpen();
  };
  class ProfilePicture extends React.Component<{}> {
    render() {
      return (
          <Flex alignItems={'center'}  w="220px"
                maxW="220px">
            <Menu size={"sm"}>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    sx={{
                      width: '100%',
                      _hover: { bg: 'gray.900' },
                      p: '2', // This is a gold-like color; adjust as needed
                    }}
                    minW={0}>
                  <Flex mr={4} justifyContent="space-between" alignItems="center">
                    <Avatar
                        size={'sm'}
                        src={
                          profile.picture
                        }
                    />
                    <Box alignItems="left">
                      <Text fontSize={"10px"} color={"gray.400"}>{profile.mail}</Text>
                      <Badge align={"left"}>
                        {profile.userPlan}
                      </Badge>
                    </Box>
                    <Icon as={FaEllipsis} color="gray.400" cursor="pointer" ml={4} />
                  </Flex>
                </MenuButton>

              </Flex>
              <MenuList minW="0" w={'100%'}>
                <MenuItem icon={<IoIosContact />}onClick={redirectToProfile} fontSize={"sm"}>Profile Settings</MenuItem>
                <MenuItem icon={<IoIosExit />}onClick={logOut} fontSize={"sm"} color={"red.500"}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
      );
    }
  }
  const SignInButton = () => {
    return (
        <Button
            ms='0px'
            px='0px'
            me={{ sm: "2px", md: "16px" }}
            color={"gray.200"}
            onClick={handleSignInClick}
            variant='no-effects'
            rightIcon={
              document.documentElement.dir ? (
                  ""
              ) : (
                  <ProfileIcon color={"gray.200"} w='22px' h='22px' me='0px' />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                  <ProfileIcon color={useColorModeValue("gray.700", "gray.300")} w='22px' h='22px' me='0px' />
              ) : (
                  ""
              )
            }>
          <Text display={{ sm: "none", md: "flex" }} color={useColorModeValue("gray.700", "gray.300")}>Sign In</Text>
        </Button>
    );
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const { sidebarVariant } = props;
  const createLinks = (routes) => {
    // Chakra Color Mode
    let activeBg = useColorModeValue("white", "gray.700");
    let inactiveBg = useColorModeValue("white", "black");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "white");
    let borderColor = useColorModeValue("gray.400", "white");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <>
            <Text
              color="gray.500"
              fontWeight="bold"
              mb={{
                xl: "6px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : translateRouteName(prop.name)}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path} key={key}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              boxShadow={sidebarActiveShadow}
              bg={activeBg}
              transition={variantChange}
              mb={{
                xl: "6px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg="black"
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px"
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : translateRouteName(prop.name)}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "6px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color={useColorModeValue("navy.900", "white")}
                    h="30px"
                    w="30px"
                    me="12px"
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : translateRouteName(prop.name)}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };
  const { logo, routes } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "black");
  let sidebarRadius = "20px";
  let sidebarMargins = "0px";
  var brand = (
    <Box pt={"25px"} mb="12px" mr="6px">
      {logo}
      <HSeparator my="0px" />
    </Box>
  );

  // SIDEBAR
  return (
    <Box ref={mainPanel} overflowY="hidden">
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
          boxShadow={"0 2px 12px 0 rgb(0 0 0 / 16%)"}
          borderRadius={sidebarRadius}
          borderRight="1px solid #505050"
        >
          <Scrollbars
            autoHide
            renderTrackVertical={
              document.documentElement.dir === "rtl"
                ? renderTrackRTL
                : renderTrack
            }
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={
              document.documentElement.dir === "rtl"
                ? renderViewRTL
                : renderView
            }
          >
            <Box>{brand}</Box>
            <Stack direction="column" mb="10px">
              <Box>{links}</Box>
            </Stack>

            <Box  style={{ position: 'absolute', bottom: 10, left: 0 }}>
              {profile ? <ProfilePicture /> : <SignInButton />}
            </Box>
            <SignInModal isOpen={isOpen} onClose={onClose} headerText={"Welcome to Ametrica"}/>
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
}

// FUNCTIONS



export default Sidebar;

import {
    Box,
    Flex,
    Icon,
    Image,
    Select,
    Text,
    Tooltip,
    useColorMode,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import AmetricaFXProModal from "../../Modals/AmetricaFXProModal";
import MomentumRow from "./MomentumRow";
import {useHistory} from "react-router-dom";
import InfoPopover from "../../PairAnalyticsComponents/Indicators/InfoPopover";
import { useTranslation } from 'react-i18next';

function MomentumOverviewTable({data,title,icon}) {
    const textColor = useColorModeValue("gray.700", "white");
    const [selectedKey, setSelectedKey] = useState('1d');
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dataList, setdataList] = useState(data);
    const [userPlan, setUserPlan] = useState(null);
    const { colorMode } = useColorMode();
    const history = useHistory();
    const { t } = useTranslation()
    
    const intervalsDict =  {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };

    useEffect(() => {
        setdataList(data)
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        setLoading(false)
    }, [data]);


    const handleSelectChange = (event) => {
        if (userPlan !== 'Premium' && event.target.value !== '1d') {
            onOpen()
        } else {
            setSelectedKey(event.target.value);
            // Update displayed data based on the selected time interval
        }
    };
    const isOptionDisabled = (key) => {
        // For standard users, disable all options except '1D'
        return userPlan !== 'Premium' && key !== '1d';
    };
    const handleIntervalChange = (event) => {
        const selectedValue = event.target.value;

        if (userPlan !== 'Premium' && selectedValue !== '1d') {
            setSelectedKey('1d');
            onOpen(); // Show modal or notification
        } else {
            setSelectedKey(selectedValue); // Allow changing selection
        }
    };
    const selectedData = data[selectedKey] || [];
    return (
        <Card  p='0px' maxW={{ sm: "420px", md: "100%" }}       bg={
            colorMode === "dark"
                ? "gray.800"
                : "white"
        }>
            <Box position="absolute" top="4" right="0"  mb="15px" mr={1} maxH={'20px'} color={'gray.400'}>
                <Flex alignItems="center">
                    <Select defaultValue="1d"  onChange={handleIntervalChange}>
                                {Object.entries(intervalsDict).map(([key, value]) => (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {isOptionDisabled(key) ? `${value} ✨` : value}
                                    </option>
                                ))}
                            </Select>
                    <InfoPopover
                        header={t("MomentumGainerInfoHeader")}
                        context={t("MomentumGainerInfoText")}
                    />
                </Flex>
                <AmetricaFXProModal isOpen={isOpen} onClose={onClose} />
            </Box>
            <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
                <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                {t("Price")}
                </Text>
                <Text color={textColor} fontSize='lg' fontWeight='bold'>
                    {title}
                </Text>
            </Flex>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <CardBody>
                    <Flex direction='column' w='100%'>
                        {selectedData.map((row, idx) => {
                            return (
                                <Tooltip label={t("ShowMoreAboutPair")} openDelay={500} bg={useColorModeValue("navy.900", "gray.700")} color={"white"} borderRadius={"8px"}>
                                    <Box
                                        key={idx}
                                        p={2}
                                        borderRadius={"8px"}
                                        onClick={() => history.push(`/fx/pair-analytics?pair=${row.Asset}`)}
                                        cursor="pointer"
                                        _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
                                    >
                                        <MomentumRow
                                            name={row.Asset}
                                            logo={icon}
                                            value={row.Momentum}
                                            key={idx}
                                        />
                                    </Box>
                                </Tooltip>
                            );
                        })}
                    </Flex>
                </CardBody>
            )}
        </Card>
    );
}

export default MomentumOverviewTable;

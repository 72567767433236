'use client'

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import AboutLogo from 'assets/img/AboutLogo.png'
import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'
import { useTranslation } from 'react-i18next';

export default function AboutCoreValues() {
  const { t } = useTranslation()
  return (
    <Container maxW={'5xl'} py={12}>
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      <Stack spacing={4}>
        <Text
          textTransform={'uppercase'}
          color={'purple.400'}
          fontWeight={600}
          fontSize={'sm'}
          bg={useColorModeValue('purple.50', 'purple.900')}
          p={2}
          alignSelf={'flex-start'}
          rounded={'md'}>
          {t('AboutCoreValuesTitle')}
        </Text>
        <Heading>{t("AboutCoreValuesSubTitle")}</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
       {t("AboutCoreValuesDescription")}</Text>
        <Stack
          spacing={4}
          divider={
            <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />
          }>
          <Stack direction={'row'} align={'center'}>
            <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('purple.100', 'purple.500')}>
            <Icon as={IoAnalyticsSharp} color={'purple.200'} w={5} h={5} />
            </Flex>
            <Text fontWeight={600}>{t("AboutCoreValuesSimple")}</Text>
          </Stack>
          <Stack direction={'row'} align={'center'}>
            <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('purple.100', 'purple.500')}>
            <Icon as={IoAnalyticsSharp} color={'purple.200'} w={5} h={5} />
            </Flex>
            <Text fontWeight={600}>{t("AboutCoreValuesData-Driven")}</Text>
          </Stack>
          <Stack direction={'row'} align={'center'}>
            <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('purple.100', 'purple.500')}>
            <Icon as={IoAnalyticsSharp} color={'purple.200'} w={5} h={5} />
            </Flex>
            <Text fontWeight={600}>{t("AboutCoreValuesImpactful")}</Text>
          </Stack>
        </Stack>
      </Stack>
      <Flex>
        <Image
          rounded={'md'}
          alt={'feature image'}
          src={
            AboutLogo
        }
          objectFit={'cover'}
        />
      </Flex>
    </SimpleGrid>
  </Container>
  )
}
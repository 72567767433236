// Chakra imports
import {
    Box,
    Flex,
    Grid,
    Select,
    Text,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import { CiWavePulse1 } from "react-icons/ci";
import React, {useEffect, useState} from "react";
import LineChart from "../Charts/LineChart";
import {forexChartData, lineChartOptions} from "../../variables/charts";
import VolatilityTable from "./Volatility/VolatilityTable";
import VolatilityPerformance from "./Volatility/VolatilityPerformance";

const VolatilityOverviewLayout = ({data}) => {
    const textColor = useColorModeValue("navy.900", "white");
    const [volatilityData, setVolatilityData] = useState({});
    const [volatilityRankingData, setVolatilityRankingData] = useState({});
    const [volatilityPerformanceData, setVolatilityPerformanceData] = useState({});
    const [loading, setLoading] = useState(true);
    const { colorMode } = useColorMode();
    useEffect(() => {
        const volatility_data = data.data.mi.volatility
        setVolatilityData(volatility_data)
        setVolatilityRankingData(volatility_data["VolatilityRanking"])
        setVolatilityPerformanceData(volatility_data["VolatilityPerformance"])
        setLoading(false);

    }, [data]);
    return (
        <Grid
            templateColumns={{ sm: "1fr", lg: "1fr 2fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap='20px'>
            <VolatilityTable data={volatilityRankingData} icon={CiWavePulse1} />
            {loading ? (
                <div>Loading...</div>
            ) : (
                <VolatilityPerformance data={volatilityPerformanceData}/>
            )}
        </Grid>
    );
}
export default VolatilityOverviewLayout;



import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    Box,
    Text,
    IconButton,
    InputGroup,
    InputRightElement,
    useToast, useDisclosure
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import SignInModal from "../Modals/SignInModal";
import { useTranslation } from 'react-i18next';

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-auth';

const AmetricaSignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure(); // Modal state
    const { t } = useTranslation()

    const handleLogin = async () => {
        setLoading(true);
        setError('');

        try {
            const res = await axios.post(
                cloudFunctionURL,
                {
                    type: 'signup',
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            onOpen();
            localStorage.setItem('_Attributes', res.data.jwt);
            toast({
                title: t('AmetricaSignUpEmailPlaceHolder'),
                description: t('AmetricaSignUpWelcomeDescription'),
                status: 'success',
                duration: 10000, // Toast will be displayed for 10 seconds
                isClosable: true,
                position: 'top', // Display toast at the top of the screen
            });
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message); // Capture and set the error message from the API response
            } else {
                setError(t('AmetricaSignUpWelcomeErrorMessage'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl>
            {error && (
                <Box mb="24px">
                    <Text color="red" fontSize="sm" textAlign="center">
                        {error}
                    </Text>
                </Box>
            )}

            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
            </FormLabel>
            <Input
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="text"
                placeholder={t('AmetricaSignUpEmailPlaceHolder')}
                mb="24px"
                size="md"
                value={email}
                onChange={handleEmailChange}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            {t('AmetricaSignUpPasswordHeader')}
            </FormLabel>
            <InputGroup>
                <Input
                    variant="auth"
                    fontSize="sm"
                    ms="4px"
                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                    placeholder={t('AmetricaSignUpPasswordPlaceHolder')}
                    mb="16px"
                    size="md"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <InputRightElement width="3.5rem">
                    <IconButton
                        h="1.75rem"
                        size="sm"
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} // Toggle icon based on showPassword state
                        onClick={togglePasswordVisibility}
                    />
                </InputRightElement>
            </InputGroup>
            <Button
                bg="#1D9BF0"
                borderRadius="5rem"
                w="100%"
                h="45px"
                maxW="100%"
                fontSize="16px"
                color="white"
                fontWeight="bold"
                mb="24px"
                onClick={handleLogin}
                isLoading={loading}
                loadingText="LOADING..."
                disabled={loading}
            >
                {t('AmetricaSignUpCreateAccount')}
            </Button>
            {isOpen && (
                <SignInModal isOpen={isOpen} onClose={() => setIsOpen(false)} headerText={t('AmetricaSignUpLoginText')} />
            )}
        </FormControl>
    );
};

export default AmetricaSignUp;

'use client'

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'
import CommunityImg from 'assets/img/community.jpg'
import { useTranslation } from 'react-i18next';

export default function AboutMissionVision() {
  const { t } = useTranslation()
  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              CommunityImg
          }
            objectFit={'cover'}
          />
        </Flex>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'purple.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('purple.50', 'purple.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            {t('AboutMissionVisionTitle')}
          </Text>
          <Text color={'gray.300'} fontSize={'lg'}>
          {t('AboutMissionVisionDescription')}
          </Text>
          <Heading> {t('AboutMissionVisionMission')}</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
          {t('AboutMissionVisionMissionText')}
          </Text>
          <Heading>{t('AboutMissionVisionVision')}</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
          {t('AboutMissionVisionVisionText')}
          </Text>
        </Stack>
      </SimpleGrid>
    </Container>
  )
}
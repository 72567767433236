import {Box, Flex, Icon, Image, Select, Text, useColorMode, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import Card from "../../Card/Card";
import AmetricaFXProModal from "../../Modals/AmetricaFXProModal";
import VolatilityLineChart from "./VolatilityLineChart";
import InfoPopover from "../../PairAnalyticsComponents/Indicators/InfoPopover";
import {generateLineChartOptions} from "../../../variables/charts";
import { useTranslation } from 'react-i18next';

function VolatilityPerformance({data}) {
    const textColor = useColorModeValue("gray.700", "white");
    const theme = useColorModeValue("light", "dark");
    const [selectedKey, setSelectedKey] = useState('1d');
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dataList, setdataList] = useState(data);
    const [userPlan, setUserPlan] = useState(null);
    const { colorMode } = useColorMode();
    const linechartRef = React.createRef();
    const lineChartOptions = generateLineChartOptions(textColor, theme);
    const { t } = useTranslation()
    const intervalsDict = {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };
    useEffect(() => {
        setdataList(data)
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        setLoading(false)
    }, [data]);

    const handleSelectChange = (event) => {
        if (userPlan !== 'Premium' && event.target.value !== '1d') {
            onOpen()
        } else {
            setSelectedKey(event.target.value);
            // Update displayed data based on the selected time interval
        }
    };
    const isOptionDisabled = (key) => {
        // For standard users, disable all options except '1D'
        return userPlan !== 'Premium' && key !== '1d';
    };
    const handleIntervalChange = (event) => {
        const selectedValue = event.target.value;

        if (userPlan !== 'Premium' && selectedValue !== '1d') {
            setSelectedKey('1d');
            onOpen(); // Show modal or notification
        } else {
            setSelectedKey(selectedValue); // Allow changing selection
        }
    };
    const selectedData = data[selectedKey] || [];

    return (
        <Card
            bg={
                colorMode === "dark"
                    ? "gray.800"
                    : "white"
            }
            p='0px'
            h={"550px"}
            maxW={{ sm: "420px", md: "100%" }}>
            <Box position="absolute" top="4" right="0"  mb="15px" mr={1} maxH={'20px'} color={'gray.400'}>
                <Flex alignItems="center">
                <Select defaultValue="1d"  onChange={handleIntervalChange}>
                                {Object.entries(intervalsDict).map(([key, value]) => (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {isOptionDisabled(key) ? `${value} ✨` : value}
                                    </option>
                                ))}
                            </Select>
                    <InfoPopover
                        header={t('VolatilityPerformanceInfoHeader')}
                        context={t('VolatilityPerformanceInfoText')}
                    />
                </Flex>
                <AmetricaFXProModal isOpen={isOpen} onClose={onClose} />
            </Box>
            <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
                <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                {t('Volatility')}
                </Text>
                <Text color={textColor} fontSize='lg' fontWeight='bold'>
                    {t('VolatilityPerformanceHeader')}
                </Text>
            </Flex>
            <Box minH='300px' id={linechartRef}>
                <VolatilityLineChart
                    chartData={selectedData}
                    chartOptions={lineChartOptions}
                    ref={linechartRef}
                />
            </Box>

        </Card>
    );
}

export default VolatilityPerformance;

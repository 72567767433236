import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Text,
    Image,
    VStack,
    SimpleGrid,
    Link,
    Skeleton,
    Card,
    CardBody,
    CardFooter,
    Select,
    Flex, useColorModeValue, Tooltip
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CurrencyView from "../CurrencyView/CurrencyView";
import {useHistory} from "react-router-dom";

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-fundamental-api';

const NewsPage = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const textColor = useColorModeValue("navy.900", "white");
    const history = useHistory();

    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem('visited');
        if (!hasVisitedBefore) {
            localStorage.setItem('visited', true);
        }
        const updateData = async () => {
            try {
                setLoading(true)
                const userFromStorage = localStorage.getItem('_Attributes') || '';
                const res = await axios.post(cloudFunctionURL, {
                    token: userFromStorage,
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setNews(res.data.news.news)
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error:', error.response ? error.response.data.error : error.message);
            }
        };
        updateData();

    }, []);
    const language = i18n.language;
    console.log(news)
    return (
        <Box>
            <Flex direction='column' w='100%'>
                <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
                    <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                        {t("Events")}
                    </Text>
                    <Text color={textColor} fontSize='lg' fontWeight='bold'>
                        {t("ForexLatestNews")}
                    </Text>
                </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                {loading ? (
                    Array(4).fill().map((_, i) => (
                        <Skeleton key={i} height="300px" />
                    ))
                ) : (
                    news.map((article, index) => (
                        <Card key={index} p={3} shadow="md" borderWidth="2px" borderRadius="xl" borderColor={"white"}>
                            <Image src={article.img_url} alt={article.header[language]} borderRadius="md" />
                            <Tooltip label={t("ShowMoreAboutCurrency")} openDelay={500} bg={useColorModeValue("navy.900", "gray.700")} color={"white"} borderRadius={"8px"}>
                                <Box
                                    p={1}
                                    borderRadius={"8px"}
                                    onClick={() => history.push(`/fx/currency-overview?currency=EUR`)}
                                    cursor="pointer"
                                    _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
                                >
                                    <CurrencyView currency = {article.currency} textColor={textColor}/>
                                </Box>
                            </Tooltip>
                            <CardBody>
                                <Heading size="md" mt={2}>{article.header[language]}</Heading>
                                <Text mt={2} size={"md"}>{article.context[language]}</Text>
                            </CardBody>
                            <CardFooter>
                                <VStack align="start" mt={4}>
                                    {article.related_articles.map((related, idx) => (
                                        <Link key={idx} href={related.url} color="teal.500">
                                            {related.title}
                                        </Link>
                                    ))}
                                </VStack>
                            </CardFooter>
                        </Card>
                    ))
                )}
            </SimpleGrid>
        </Box>
    );
};

export default NewsPage;

import React, {useEffect, useState} from "react";
// Chakra imports
import {
    Box,
    Flex,
    Text,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";

import Options from "../../components/Premium/Options";
import decodeJWT from "../../variables/functions";
import {useTranslation} from "react-i18next";

function PremiumUpgrade() {
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const [profile, setProfile] = useState(null);
    const bgColor = useColorModeValue("transparent", "black");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [userPlan, setUserPlan] = useState(null);
    const { t } = useTranslation()

    useEffect(() => {
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan);
        const userFromStorage = localStorage.getItem('_Attributes');
        if (userFromStorage) {
          const decodedToken = decodeJWT(userFromStorage);
          setProfile(decodedToken);}
    }, []);

    return (
        <Flex position='relative'>
            <Flex
                minH={{ md: "720px" }}
                h='100%'
                maxH={'100%'}
                w='100%'
                maxW='100%'
                bgSize='cover'
                bg={bgColor}
                mx='auto'
                pt={{ md: "0px" }}>
                <Flex
                    w='100%'
                    h='100%'
                    alignItems='center'
                    p={5}
                    mt={20}
                    flexDirection="column"
                    >
                    <Flex as="span" position="relative" >
                        <Text fontSize={{ sm: "3xl", md: "5xl", lg: "50px" }} fontWeight="bold" color={textColor}>
                            AmetricaFX
                        </Text>
                        <Text
                            as="span"
                            fontSize={{ sm: "3xl", md: "5xl", lg: "50px" }}
                            fontWeight="bold"
                            bgGradient="linear-gradient(216.66deg, rgb(0, 128, 128) 2.89%, rgb(0, 102, 102) 97.26%);"
                            bgClip="text"
                            ml={2}
                        >
                            Premium✨
                        </Text>
                    </Flex>
                    <Box>
                        <Text textAlign={"center"} fontSize={{ sm: "xl", md: "3xl", lg: "25px" }} fontWeight="bold" mb="4" color="gray.400">
                            {t('PremiumSubHeader1')}
                        </Text>
                        <Text textAlign={"center"} mb="8" fontSize={{ sm: "lg", md: "3xl", lg: "18px" }} color="gray.400">
                            {t('PremiumSubHeader2')}
                        </Text>
                    </Box>
                    <Options userPlan={userPlan} profile={profile}/>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default PremiumUpgrade;

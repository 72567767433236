import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps, useColorModeValue,
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";

export default function AIERAText() {
  const { t } = useTranslation()
  return (
    <Container maxW={'4xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 5 }}
          py={{ base: 10, md: 10 }}
      mt={20}
      >
        <Heading
          fontWeight={700}
          fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
          color={"white"}
          lineHeight={'110%'}>
          {t('LandingAIERAHeader1')} {' '}
        </Heading>
        <Heading
            fontWeight={700}
            fontSize={{ base: '2xl', sm: '4xl', md: '4xl' }}
            color={"white"}
            lineHeight={'110%'}>
          {t('LandingAIERAHeader2')} {' '}
        </Heading>
      </Stack>
    </Container>
  );
}
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, useColorMode, Button, Box,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import AMETRICA_LOGO from "assets/img/ICON-LIGHT.png";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {MdInsights} from "react-icons/md";
import Hero3 from "../../../assets/img/Hero3.png";
import {useTranslation} from "react-i18next";


export default function StartNow() {
    const handleClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/fx/market-dynamics`;
        window.location.href = navPath; // Redirects to the specified path
    };
    const { t } = useTranslation()
    return (
        <Container maxW={'8xl'} mt={5} mb={5} py={7} px={10}
                   bgImage={`url(${Hero3})`}
                   bgSize="cover"
                   bgPosition="center"
                   borderRadius={"16px"} boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'} >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={40}>
                <Stack spacing={4} py={20}>
                    <Heading fontSize={'3xl'} color={"white"}>{t('StartNowBannerHeader')}</Heading>
                    <Text  fontSize={'lg'} color={"white"}>
                        {t('StartNowBannerDesc')}
                    </Text>
                    <Button width={'100%'}
                            borderRadius={"15px"}
                            rightIcon={<MdInsights />}
                            bgColor='white' color={"black"}
                            onClick={handleClick}
                            variant='solid'
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'sm',
                            }}
                    >
                        {t('StartNowBannerButton')}
                    </Button>
                </Stack>
                <Flex position="relative">
                    <Image
                        alt={'feature image'}
                        src={AMETRICA_LOGO}
                        objectFit={'cover'}
                        boxSize={"300px"}
                        rounded={"xl"}
                        boxShadow={'lg'}
                        overflow={'hidden'}
                        transition={'all 0.3s ease'}
                        _hover={{ boxShadow: 'xl', transform: 'scale(1.01)' }}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    );
}
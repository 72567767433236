import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import {Flex, Text, useColorMode, useColorModeValue} from "@chakra-ui/react";
import CardBody from "../Card/CardBody";
import HeatMapWidget from "../TradingView/HeatMapWidget";
import React from "react";
import { useTranslation } from 'react-i18next';

const PriceChangeMatrixLayout = ({}) => {
    const textColor = useColorModeValue("navy.900", "white");
    const colorTheme = useColorModeValue("light", "dark");
    const { colorMode } = useColorMode();
    const { t } = useTranslation()
    return (
        <Card
            my="12px"
            overflowX={{ sm: "scroll", xl: "hidden" }}
            pb="0px"
            bg={
                colorMode === "dark"
                    ? "gray.800"
                    : "white"
            }
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        {t('PriceChangeMatrixHeader')}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <HeatMapWidget colorTheme={colorTheme}/>
            </CardBody>
        </Card>
    );

}
export default PriceChangeMatrixLayout;

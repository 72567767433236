import {
  Box,
  Container, Divider, Flex, Image, Stack,
} from '@chakra-ui/react';
import SplitWithImage from './Features/Feature1';
import InsightsFeature from './Features/InsightsFeature';
import Hero from './Hero/Hero';
import UseDataText from './Features/UseDataText';
import CustomCarousel from "./Hero/CustomCarousel";
import MarketDynamicsFeature from "./Features/MarketDynamicsFeature";
import CurrencyOverviewFeature from "./Features/CurrencyOverviewFeature";
import AIERA from "assets/img/AIERA.png";
import PairAnalyticsFeature from "./Features/PairAnalyticsFeature";
import AmetricaGPTFeature from "./Features/AmetricaGPTFeature";
import AIERAText from "./Features/AIERAText";
import FAQ from "./Features/FAQ";
import PremiumUpgrade from "../../views/Pages/PremiumUpgrade";
import StartNow from "./Features/StartNow";


export default function Home() {
  return (
    <Container maxW={'full'}>
      <Flex
        flexDirection={'row'}
        align={'flex-start'}
        justify={'center'}
      >
        <Container maxW={'7xl'}>
          <Hero/>
          <CustomCarousel/>
          <Container  maxW={'8xl'}>
            <UseDataText/>
            <MarketDynamicsFeature/>
            <Stack direction='row' h='70px' alignItems='center' justifyContent='center' p={4}>
              <Divider orientation='vertical' borderColor="blue.50" borderRadius={"15px"}  mx={4}  borderWidth="5px" />
            </Stack>
            <CurrencyOverviewFeature/>
            <Stack direction='row' h='70px' alignItems='center' justifyContent='center' p={4}>
              <Divider orientation='vertical' borderColor="blue.50" borderRadius={"15px"}  mx={4}  borderWidth="5px" />
            </Stack>
            <PairAnalyticsFeature/>
            <Stack direction='row' h='70px' alignItems='center' justifyContent='center' p={4}>
              <Divider orientation='vertical' borderColor="blue.50" borderRadius={"15px"}  mx={4}  borderWidth="5px" />
            </Stack>
            <AmetricaGPTFeature/>
            <Stack direction='row' h='70px' alignItems='center' justifyContent='center' p={4}>
              <Divider orientation='vertical' borderColor="blue.50" borderRadius={"15px"}  mx={4}  borderWidth="5px" />
            </Stack>
            <SplitWithImage/>
          </Container>
          <Box
              backgroundImage={AIERA} // Ensure this is the correct path to your image
              backgroundSize="cover"
              backgroundPosition="center"
              borderRadius={"15px"}
              mt={20}
              p={4} // Add padding if needed
          >
            <AIERAText />
            <InsightsFeature />
          </Box>
          <PremiumUpgrade/>
          <FAQ/>
          <StartNow/>
        </Container>
      </Flex>
    </Container>
  );
}
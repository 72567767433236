import React from 'react';
import { Image, keyframes, useColorMode, usePrefersReducedMotion } from '@chakra-ui/react';
import logoDark from './logo-dark.png';
import logoLight from './SidebarHelpImage.png';

// Brightness keyframes animation
const brightness = keyframes`
  0% { filter: brightness(1); }
  50% { filter: brightness(1.5); }
  100% { filter: brightness(1); }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const AmetricaIconLogo = props => {
    const { colorMode } = useColorMode();
    const imageSrc = colorMode === 'light' ? logoLight : logoDark;
    const prefersReducedMotion = usePrefersReducedMotion();

    // Combine spin and brightness animations
    const animation = prefersReducedMotion
        ? undefined
        : `${brightness} 2s linear infinite`;

    return(
            <Image src={imageSrc} animation={animation} {...props} />
    );
};

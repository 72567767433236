import React, { useEffect, useState } from 'react';
import {Box, Heading, Text, Button, VStack, useColorModeValue, Flex, Grid, Image} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GettingStarted from "../../assets/img/gettingstarted.png";

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-articles-api';

const LearnFXPage = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const textColor = useColorModeValue("gray.700", "white");

    useEffect(() => {
        const getArticles = async () => {
            try {
                const res = await axios.post(cloudFunctionURL, {
                    article: ''
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setArticles(res.data.article.articles);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching articles:', error.response ? error.response.data.error : error.message);
            }
        };
        getArticles();
    }, []);

    const handleReadMore = (title) => {
        history.push(`/fx/article/${encodeURIComponent(title)}`);
    };

    if (loading) {
        return <Text>Loading...</Text>;
    }

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px", lg: "100px" }}>
            <Text textAlign="center" fontSize="46px" color={useColorModeValue('navy.900', 'white')} fontWeight="bold" pb=".5rem">
                Learn Forex
            </Text>
            <Text textAlign="center" fontSize="18px" color={useColorModeValue('navy.900', 'white')}  fontWeight="bold" pb=".5rem">
                Simple. Smart. Ametrica. Your winning journey starts here
            </Text>
            <Card p='16px' my='24px'>
                <CardHeader p='12px 5px' mb='12px'>
                </CardHeader>
                <CardBody px='5px'>
                    <Grid
                        templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(2, 1fr)" }}
                        templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                        gap='24px'>
                        {articles.map((article, index) => (
                            <Card>
                                <Flex key={index} direction='column'>
                                    <Box mb='20px' position='relative' borderRadius='15px'>
                                        <Box
                                            w='100%'
                                            h='100%'
                                            position='absolute'
                                            top='0'
                                            borderRadius='15px'
                                            bg='linear-gradient(360deg, rgba(49, 56, 96, 0.16) 100%, rgba(21, 25, 40, 0.88) 100%)'></Box>
                                    </Box>
                                    <Flex direction='column'>
                                        <Text fontSize='md' color='gray.400' fontWeight='600' mb='10px'>
                                            Beginner #{index}
                                        </Text>
                                        <Text
                                            fontSize='xl'
                                            color={textColor}
                                            fontWeight='bold'
                                            mb='10px'>
                                            {article.title}
                                        </Text>
                                        <Text fontSize='sm' color='gray.400' fontWeight='400' mb='20px'>
                                            {article.summary}...
                                        </Text>
                                        <Flex justifyContent='space-between'>
                                            <Button mt={4} colorScheme="teal" onClick={() => handleReadMore(article.title_index)}>Read</Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>
                        ))}
                    </Grid>
                </CardBody>
            </Card>

        </Flex>
    );
};

export default LearnFXPage;

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue, useColorMode, Button,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import FINVEO from "../../../assets/img/ImageFinveoBanner.png";
import {useTranslation} from "react-i18next";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function InsightsFeature() {
  const { colorMode } = useColorMode();
  const { t } = useTranslation()
  return (
      <Container maxW={'5xl'} mt={5} mb={5} py={7} px={10} bg={useColorModeValue('gray.900', 'gray.900')} borderRadius={"16px"} boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'} >
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10} alignItems={"center"} justifyContent={"center"}>
        <Stack spacing={4} p={5} alignItems={"center"} justifyContent={"center"}>
          <Heading fontSize={'3xl'} color={"white"}>{t('LandingFeature5Header')}</Heading>
          <Text  fontSize={'lg'} textAlign={"center"} color={"gray.300"}>
              {t('LandingFeature5Desc')}
          </Text>
        </Stack>
          <Flex alignItems={"center"} justifyContent={"center"}>
              <Image
                  rounded={'xl'}
                  alt={'feature image'}
                  src={
                      FINVEO
                  }
                  objectFit={'cover'}
              />
          </Flex>
      </SimpleGrid>

    </Container>
  );
}
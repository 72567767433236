// InvestingCalendar.js
import React from 'react';
import Card from "../Card/Card";
import {Box, Flex, Text, useColorMode, useColorModeValue} from "@chakra-ui/react";
import CardBody from "../Card/CardBody";
import {useTranslation} from "react-i18next";
import CurrencyBanner from "./CurrencyBanner";
import GPTBanner from "./GPTBanner";

const InvestingCalendar = () => {
    const iframeHTML = `
    <iframe src="https://sslecal2.investing.com?ecoDayBackground=%231f2733&defaultFont=%231b244a&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&importance=2,3&features=datepicker,timezone&countries=25,32,6,37,72,22,17,39,14,10,35,43,56,36,110,11,26,12,63,4,5&calType=week&timeZone=63&lang=1" width="650" height="467" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe>
    <div class="poweredBy" style="font-family: Arial, Helvetica, sans-serif;">
      <span style="font-size: 11px;color: #333333;text-decoration: none;">
        Economic Calendar provided by 
        <a href="https://www.investing.com/" rel="nofollow" target="_blank" style="font-size: 11px;color: #06529D; font-weight: bold;" class="underline_link">Investing.com</a>.
      </span>
    </div>
  `;
    const textColor = useColorModeValue("navy.900", "white");
    const { colorMode } = useColorMode();
    const { t } = useTranslation()
    return (
        <Card   overflowX={{ sm: "scroll", xl: "hidden" }} p='0px' maxW={{ sm: "420px", md: "100%" }} bg={
            colorMode === "dark"
                ? "gray.800"
                : "white"
        }>
            <Flex direction='column' w='100%'>
                <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
                    <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
                        {t("Events")}
                    </Text>
                    <Text color={textColor} fontSize='lg' fontWeight='bold'>
                        {t("ForexCalendarEconomicCalendar")}
                    </Text>
                </Flex>
            </Flex>
            <CardBody>
                <Flex display={{ sm: "column", xl: "flex" }}  height="100%">
                    <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />
                    <GPTBanner/>
                </Flex>
            </CardBody>
        </Card>

    );
};

export default InvestingCalendar;

import {useColorModeValue} from "@chakra-ui/react";

export const barChartData = [
  {
    name: "Strength",
    data: [61, 49, 18, -18, -23, -47],
  },
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    categories: ["USD", "CHF", "EUR", "JPY", "GBP", "CAD", "AUD", "NZD"],
    labels: {
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
    show: true,
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "#A0AEC0",
    labels: {
      show: true,
      style: {
        colors: "#A0AEC0",
        fontSize: "14px",
      },
    },
  },
  fill: {
    colors: "#003776",
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 15,
      columnWidth: "15px",
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

export const lineChartData = [
  {
    name: "Mobile apps",
    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
  },
  {
    name: "Websites",
    data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
  },
];

export const forexChartData = [
  {
    name: "EURUSD",
    data: [1.2, 0.9, 1.5, 1.1, 1.6, 0.8, 1.4, 1.0, 1.3],
    color: "#003776", // Assign a unique color
  },
  {
    name: "GBPUSD",
    data: [1.1, 1.0, 1.4, 1.2, 1.5, 0.9, 1.3, 1.1, 1.4],
    color: "#4073AF", // Assign a unique color
  },
  {
    name: "USDJPY",
    data: [0.7, 0.8, 0.6, 0.9, 0.7, 0.5, 0.8, 0.6, 0.7],
    color: "#4073AF", // Assign a unique color
  }
  // ... Add more currency pairs as needed
];

export const currencyData = [
  {
    name: "AUD",
    color: "#15f6ce", // Assign a unique color
  },
  {
    name: "CAD",
    color: "#ff004d", // Assign a unique color
  },
  {
    name: "CHF",
    color: "#0a011f", // Assign a unique color
  },
  {
    name: "EUR",
    color: "#ffd606", // Assign a unique color
  },
  {
    name: "GBP",
    color: "#1b3fff", // Assign a unique color
  },
  {
    name: "JPY",
    color: "#ff4c03", // Assign a unique color
  },
  {
    name: "NAZD",
    color: "#c006ff", // Assign a unique color
  },
  {
    name: "USD",
    color: "#167a00", // Assign a unique color
  },
  // ... Add more currency pairs as needed
];

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false, // Enable toolbar
    },
  },
  tooltip: {
    theme: "light",
    x: {
      format: 'dd MMM yyyy'
    }
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "datetime",
    axisTicks: {
      show: true // Show axis ticks for clarity
    },
    axisBorder: {
      show: true,
    },
    labels: {
      style: {
        colors: "navy.300",
        fontSize: "14px", // Slightly larger font for readability
      },
    },
  },
  yaxis: {
    decimalsInFloat: 1,
    labels: {
      style: {
        colors: "navy.300",
        fontSize: "14px",
      },
    },
  },
  legend: {
    show: true, // Show the legend
    position: 'top', // Position at the top for easy identification
    horizontalAlign: 'right', // Align to right
    onItemClick: {
      toggleDataSeries: true
    },
    selected: {
      'EUR': true,
      'USD': true,
      'AUD': false
    }
  },
  grid: {
    strokeDashArray: 5, // More spaced dashed grid lines
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.3, // Lighter shade intensity for less distraction
      inverseColors: true,
      opacityFrom: 0.7, // Higher opacity for visibility
      opacityTo: 0.9,
      stops: [0, 100]
    },
  },
  markers: {
    size: 4, // Add markers
  },
  stroke: {
    curve: 'straight', // Smooth lines can be easier to follow
    width: 4 // Thinner lines might reduce visual clutter
  },
  colors: currencyData.map(series => series.color), // Ensure colors are distinct
};


export const generateLineChartOptions = (textColor, theme) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: theme,
      x: {
        format: 'dd MMM yyyy HH:mm'
      }
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
      },
      labels: {
        style: {
          colors: textColor,
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      decimalsInFloat: 2,
      labels: {
        style: {
          colors: textColor,
          fontSize: "14px",
        },
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      labels:{
        colors: textColor
      },
      onItemClick: {
        toggleDataSeries: true
      }
    },
    grid: {
      strokeDashArray: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.3,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      },
    },
    markers: {
      size: 1,
    },
    stroke: {
      curve: 'straight',
      width: 4
    },
    colors: currencyData.map(series => series.color),
  };
};

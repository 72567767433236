import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps, useColorModeValue,
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";

export default function UseDataText() {
  const { t } = useTranslation()
  return (
    <Container maxW={'4xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 5 }}
          py={{ base: 10, md: 10 }}
      mt={20}
      >
        <Heading
          fontWeight={700}
          fontSize={{ base: '2xl', sm: '4xl', md: '4xl' }}
          lineHeight={'110%'}>
          {t('LandingHowToHeader1')} {' '}
          <Text as={'span'} color={'navy.500'}>
            Ametrica?
          </Text>
        </Heading>
        <Text color={'gray.500'} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }}>
          {t('LandingHowToHeaderDesc')}
        </Text>
      </Stack>
    </Container>
  );
}
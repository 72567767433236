import React from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import Select from 'react-select';
import data from './currencylist.json';
import CurrencyView from "../CurrencyView/CurrencyView"; // Make sure to provide the correct path

function CurrencySelectBar({ onSelectChange,value }) {
    const inputBackgroundColor = useColorModeValue('white', 'black');
    const inputTextColor = useColorModeValue('black', 'white');
    const hoverBackgroundColor = useColorModeValue('#f0f0f0', '#4A5568');
    const hoverTextColor = useColorModeValue('black', 'white');
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            background: state.isFocused
                ? hoverBackgroundColor
                : state.isSelected
                    ? inputBackgroundColor
                    : null,
            color: state.isFocused ? hoverTextColor : inputTextColor,
        }),
        control: (provided) => ({
            ...provided,
            background: inputBackgroundColor,
            color: inputTextColor,
            minWidth: 272,
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: inputBackgroundColor,
        })
    };

    const options = Object.entries(data).map(([symbol, currency]) => ({
        value: symbol,
        label: `${currency.name}`,
        color: useColorModeValue("navy.800", "white")
    }));

    const formatOptionLabel = ({ label, color }) => (
        <CurrencyView currency={label} textColor={color}/>
    );

    const findOption = (value) => {
        return options.find(option => option.value === value);
    }
    const selectedOption = findOption(value);

    const defaultOption = options.find(option => option.value === 'EUR'); // Find the default option
    return (
        <Box as="section"
             p={1}
             ml='0.75rem'
        >
            <Flex>
                <Select
                    options={options}
                    placeholder="Search or select a currency..."
                    getOptionLabel={(option) => option.label}
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                    value={selectedOption}
                    onChange={(selectedOption) => onSelectChange(selectedOption.value)}
                />
            </Flex>
        </Box>
    );
}


export default CurrencySelectBar;
import React, { useState, useEffect, useRef } from 'react';
import ChatHistory from './ChatHistory';
import {
  Flex,
  Input,
  Button,
  Box,
  Text,
  Grid,
  SimpleGrid,
  VStack,
  HStack,
  Divider,
  IconButton,
  Spinner,
  useDisclosure,
  useColorModeValue, Container
} from "@chakra-ui/react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RemoteRunnable } from "@langchain/core/runnables/remote";
import SignInModal from "../../components/Modals/SignInModal";

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messageListRef = useRef(null);
  const [remoteChain, setRemoteChain] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const exampleInputs = [
    "What is the current trend in EUR/USD?",
    "Can you provide a forecast for GBP/JPY for the next hour?",
    "Can you give me some information about CAD?",
    "Give me an overview of the recent price movements for AUD/CAD"
    // Add more example inputs as needed
  ];

  const remoteChainInstance = new RemoteRunnable({
    url: "https://ametricagpt-mhkvrwuj2q-ew.a.run.app/openai-functions-agent/",
  });
  const chatHistory = [];
  useEffect(() => {
    const localUserPlan  = localStorage.getItem('_UserTraits');
    setUserPlan(localUserPlan)
    if (localUserPlan === "") {
        onOpen();
    }
    setRemoteChain(remoteChainInstance);
  }, []);

  const sendMessage = async () => {
    if (userInput.trim() === '') return;

    const userMessage = { text: userInput, isUser: true, timestamp: new Date().toISOString() };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setUserInput('');
    setIsLoading(true);

    try {
      // Prepare chat history in the tuple format required by the API
      const chatHistory = messages.concat(userMessage).map(msg => [
        msg.isUser ? "user" : "bot",
        msg.text
      ]);

      const logStream = await remoteChain.streamEvents({
        chat_history: chatHistory,
        input: userInput
      }, {
        version: "v1"
      });

      let aiResponse = '';
      for await (const event of logStream) {
        if (event.event === 'on_chat_model_stream') {
          aiResponse += event.data.chunk.content;
        }
      }

      setMessages(prevMessages => [...prevMessages, { text: aiResponse, isUser: false }]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }



  const handleSend = () => {
    if (userInput.trim() === '') return;
    setUserInput(userInput.trim());
    sendMessage()
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleExampleInput = (input) => {
    setUserInput(input);
    sendMessage();
  };

  return (
      <Container maxW={'5xl'}>
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }} height="100vh">
      <SignInModal isOpen={isOpen} onClose={onClose} headerText={"Login For Free to Talk with GPT"}/>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ChatHistory messages={messages} messageListRef={messageListRef} isLoading={isLoading}/>
      <Flex justifyContent="center" alignItems="center" bg={useColorModeValue('gray.200', 'gray.900')} px={4} py={2} borderRadius="20rem" position="fixed" bottom="10" left={{ sm: "10%", md: "5%", xl:"30%"}} w={{ sm: "80%", md: "80%", xl:"50%"}} minH="80px">
        <Input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter your message..."
          borderRadius="md"
          bgColor={useColorModeValue('gray.200', 'gray.900')}
          p={3}
          flex={1}
        />
        <Button ml={3} onClick={handleSend} bgGradient="linear-gradient(216.66deg, rgb(29, 155, 240) 2.89%, rgb(0, 131, 235) 97.26%);" color="white">Send</Button>
      </Flex>
      {messages && messages.length === 0 && (
  <Box p={4}>
    <Flex as="span" position="relative">
      <Text
          fontSize={{ sm: "3xl", md: "5xl", lg: "50px" }} fontWeight="bold" color={useColorModeValue('gray.600', 'gray.600')}
      >
        Hello,
      </Text>
    </Flex>
    <Flex as="span" position="relative">
      <Text
        as="span"
        fontSize={{ sm: "3xl", md: "5xl", lg: "50px" }}
        fontWeight="bold"
        bgGradient="linear-gradient(216.66deg, rgb(29, 155, 240) 2.89%, rgb(0, 131, 235) 97.26%);"
        bgClip="text"
      >
        Welcome to AmetricaGPT✨
      </Text>
    </Flex>
    <Flex as="span" position="relative">
      <Text fontSize={{ sm: "3xl", md: "5xl", lg: "50px" }} fontWeight="bold" color={useColorModeValue('gray.600', 'gray.600')}>
        How can I help you today?
      </Text>
    </Flex>
    <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='3px' mb='50px' mt='50px' maxW={{ sm: "100%", md: "80%", xl:"80%"}}>
      {exampleInputs.map((input, index) => (
        <Box
          key={index}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow="md"
          borderRadius="md"
          p={4}
          textAlign="center"
          position="relative"
          w={{ sm: "100%", md: "80%", xl:"80%"}}
          h={{ sm: "80px", md: "150px", xl:"150px"}}
        >
          <Text>{input}</Text>
          <IconButton
            aria-label="Select input"
            icon={<AiOutlineCheckCircle />}
            size="sm"
            colorScheme="twitter"
            position="absolute"
            bottom={2}
            right={2}
            onClick={() => handleExampleInput(input)}
          />
        </Box>
      ))}
    </SimpleGrid>
  </Box>
)}

    </Flex>
      </Container>
  );
};

export default ChatApp;

import { Box, Text, Icon, Flex, useColorModeValue } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {useTranslation} from "react-i18next"; // Import icons from Chakra UI

// Define a component that takes prediction details as props
const PrevPredictionCard = ({ startDate,endDate, minPrediction, maxPrediction, isCorrect, closePrice }) => {
    const bgColor = useColorModeValue('navy.900', 'gray.900'); // Adjust the color based on the color mode
    const textColor = useColorModeValue('navy.900', 'white'); // Adjust the text color based on the color mode
    const { t } = useTranslation()
    return (
        <Box maxW="xs" borderWidth="1px" borderRadius="lg" overflow="hidden" >
            <Box p="2" bg={bgColor} justifyContent="center" alignItems="center" >
                <Text fontWeight="bold" textTransform="uppercase" fontSize="md" letterSpacing="wide" textAlign="center" color={"white"}>
                    {startDate}-{endDate}
                </Text>
            </Box>
            <Box p="2" align="center">
                <Text mb="2" fontWeight="semibold" fontSize={"sm"} lineHeight="tight" isTruncated>
                    {t('Prediction')}
                </Text>
                <Text mb="2" fontWeight="semibold" fontSize={"sm"} lineHeight="tight" isTruncated color={textColor}>
                   {minPrediction} - {maxPrediction}
                </Text>
                <Flex justify="center" align="center">
                    {isCorrect ? (
                        <Icon as={CheckIcon} w={8} h={8} color="green.400" />
                    ) : (
                        <Icon as={CloseIcon} w={8} h={8} color="red.500" />
                    )}
                </Flex>
                <Text mt="2" color="gray.500">
                    {t('ClosePrice')}: {closePrice}
                </Text>
            </Box>
        </Box>
    );
};

export default PrevPredictionCard;

import { Box, Heading, Text, VStack, Link, Flex } from '@chakra-ui/react';
import AboutHero from './AboutHero';
import AboutMissionVision from './AboutMissionVision'
import AboutCoreValues from './AboutCoreValues';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation()
  return (
    <Box p={8}>
        <AboutHero/>
        <AboutCoreValues/>
        <AboutMissionVision/>
      <VStack spacing={8} mt={'100'}align="start">
        <Heading as="h2" size="xl">
        {t('AboutContactUs')}
        </Heading>
        <VStack spacing={2} align="start">
          <Text fontSize="lg">
          📬: <Link href="mailto:info@ametrica.io">info@ametrica.io</Link>
          </Text>
          <Text fontSize="md">
          🏡: FENERBAHÇE MAH. İĞRİP SOK. NO:13 İÇ KAPI NO:1 KADIKÖY / İSTANBUL / TÜRKİYE
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default About;

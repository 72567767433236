import React from 'react';
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter, // Import PopoverFooter
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { BiInfoCircle } from 'react-icons/bi';

const InfoPopover = ({ header, context }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          size="md"
          aria-label="Info"
          icon={<BiInfoCircle />}
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontSize="md" fontWeight="bold">
          {header}
        </PopoverHeader>
        <PopoverBody fontSize="sm">{context}</PopoverBody>
        <PopoverFooter fontSize="xx-small" color="#0066cc">
          This info isn't a recommendation for what you should personally do, so please don't take the data as investment advice.
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopover;

// Chakra imports
import {
    Grid,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import StrengthRanking from "../MarketDynamicsComponents/CurrencyStrength/StrengthRanking";
import CurrencyStrengthPerformance from "./CurrencyStrength/CurrencyStrengthPerformance";


const CurrencyPairStrength = ({data}) => {
    const { colorMode } = useColorMode();
    const [strengthPerformanceData, setStrengthPerformanceData] = useState({});
    const [strengthRankingData, setStrengthRankingData] = useState({});
    const [loading, setLoading] = useState(true);
    const textColor = useColorModeValue("navy.900", "white");
    useEffect(() => {
        const strength_data = data.data.currency.pair_strength
        setStrengthPerformanceData(strength_data["StrengthPerformance"])
        setStrengthRankingData(strength_data["StrengthRanking"])
        setLoading(false);

    }, [data]);
    return (
        <Grid
            templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap='20px'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <CurrencyStrengthPerformance data={strengthPerformanceData}/>
            )}
            {loading ? (
                <div>Loading...</div>
            ) : (
                <StrengthRanking data={strengthRankingData}/>
            )}
        </Grid>
    );
}
export default CurrencyPairStrength;




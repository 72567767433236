// Chakra imports
import {
    Grid,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import React, {  useState, useEffect } from 'react';
import PrevPredictionCard from "./PrevPredictionCard";


function PricePredictionLayout({ selectedPair,data }) {


    const [prevPredData, setPrevPredData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updateData = async () => {
            try {
                setLoading(true)
                const prev_pred_data = data.data.prediction.PrevPredictions
                console.log(prev_pred_data)
                setPrevPredData(prev_pred_data)
                console.log(prev_pred_data);
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
                setLoading(false)
            }
        };
        updateData();

    }, [selectedPair]);


    return (
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
        { !loading && prevPredData.map((prevdata, index) => (
          <PrevPredictionCard
            key={index}
            startDate={prevdata.start_date}
            endDate={prevdata.end_date}
            minPrediction={prevdata.lower_band}
            maxPrediction={prevdata.upper_band}
            isCorrect={prevdata.is_accurate}
            closePrice={prevdata.reference_price}
          />
        ))}
      </Grid>
    );
}

export default PricePredictionLayout;
import {Box, Flex, Icon, Image, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";
import PairView from "../../PairView/PairView";


function CurrencyVolatilityRow(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const { name, logo, value } = props;
    return (
        <Flex my="1rem" justifyContent="space-between">
            <Flex alignItems="center">
                <Box
                    me="12px"
                    borderRadius="50%"
                    color={
                        value > 0
                            ? "blue.400"
                            : value < 0
                                ? "red.400"
                                : "gray.400"
                    }
                    border="1px solid"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    w="35px"
                    h="35px"
                >
                    <Icon as={logo} />
                </Box>
                <Flex direction="column">
                    <Flex>
                        <PairView pair={name} textColor={textColor}/>
                    </Flex>
                </Flex>
            </Flex>
            <Box
                color={
                    value > 0
                        ? "blue.400"
                        : value < 0
                            ? "red.400"
                            : { textColor }
                }
            >
                <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} fontWeight="bold">
                    {value}%
                </Text>
            </Box>
        </Flex>
    );
}

export default CurrencyVolatilityRow;

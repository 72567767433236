import {
    Box,
    Divider,
    Flex,
    Heading,
    Select,
    Spinner,
    Stack,
    Text, useColorModeValue
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import InfoPopover from "../PairAnalyticsComponents/Indicators/InfoPopover";
import ErrorMessageBox from "../MarketDynamicsComponents/ErrorMessageBox";
import TopListTable from "./TopListTable";
import Card from "../Card/Card";
import {useTranslation} from "react-i18next";


export default function CurrencyTopSellList({data}) {
    const [selectedKey, setSelectedKey] = useState('1d');
    const [shortData, setShortData] = useState({});
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const { t } = useTranslation()
    const intervalsDict = {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };
    useEffect(() => {
        const updateData = async () => {
            try {
                setLoading(true)
                const short_data = data.data.currency.ta_longshort
                setShortData(short_data["ShortList"])
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
                setLoading(false)
                setHasError(true);
            }
        };
        updateData();

    }, [selectedKey]);


    const handleIntervalChange = (event) => {
        setSelectedKey(event.target.value);
    };


    const selectedData = shortData[selectedKey] || [];
    return (
        <Card
            maxH="700px"
            bg={useColorModeValue('white', 'gray.800')}
            p={6}
            alignItems={"center"}
            mt={5}
            justifyContent={"center"}
            display="column"
            overflowY="auto"  // Enable vertical scrolling
            sx={{
                '&::-webkit-scrollbar': {
                    width: '10px', // Thin scrollbar
                    borderRadius: '16px', // Radius
                    backgroundColor: `rgba(0, 0, 0, 0.05)`, // Scrollbar track color
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#003776', // Scrollbar color (blue)
                    borderRadius: '16px', // Radius
                }
            }}
        >
            <Box  p={1} w={'100%'} alignItems={"flex-start"}>
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <Stack spacing={2} align="flex-start" mb={1}>
                            <Heading fontSize="md" fontWeight={700} fontFamily="body" color={useColorModeValue('navy.900', 'white')}>
                                {t('TopSellListHeader')}
                            </Heading>
                            <Text fontSize="md" textAlign="start" fontWeight={450} fontFamily="body" color={useColorModeValue('navy.900', 'white')}>
                                {t('TopSellListHeaderDescription')}
                            </Text>
                        </Stack>
                    </Flex>
                    <Flex alignItems="center">
                        <Select defaultValue="1d" w={40} onChange={handleIntervalChange}>
                            {Object.entries(intervalsDict).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </Select>
                        <InfoPopover
                            header={t('TopSellListInfoHeader')}
                            context={t('TopSellListInfoText')}
                        />
                    </Flex>
                </Flex>
                <Divider orientation='horizontal' mb={5}/>
            </Box>
            {loading ? (
                <Spinner mt={'10rem'} size="xl" color={"blue"} thickness="2px" />
            ) : hasError ? (
                <ErrorMessageBox/>
            ) : (
                <TopListTable data={selectedData} interval={intervalsDict[selectedKey]}/>
            )}
        </Card>
    );
}
// Chakra imports
import {
    Box, Flex,
    Grid, Image, Select, Table, Tbody, Text, Th, Thead, Tr,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import React, {  useState, useEffect, useRef } from 'react';
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import TradingViewBoardWidget from "./TradingViewBoardWidget";
import CurrentPredictionCard from "./PricePrediction/CurrentPredictionCard";


function PairAnalyticsChart({ selectedPair, data}) {
    const textColor = useColorModeValue("gray.700", "white");
    const theme = useColorModeValue("light", "dark");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [currenctPredData, setCurrenctPredData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updateData = async () => {
            try {
                setLoading(true)
                const currenct_pred_data = data.data.prediction.CurrentPrediction
                setCurrenctPredData(currenct_pred_data)
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
                setLoading(false)
            }
        };
        updateData();

    }, [selectedPair]);
    // Step 1: Create a reference
    return (
        <Box>
            <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ lg: "repeat(2, auto)" }}
                gap='20px'>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                    <CardBody>
                        <Grid
                            templateColumns={{ sm: "1fr", lg: "4fr 1fr" }}
                            templateRows={{ lg: "repeat(2, auto)" }}
                            gap='20px'>
                            <TradingViewBoardWidget selectedPair={selectedPair} theme={theme}/>
                            <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
                            {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                <CurrentPredictionCard
                                                        pair={selectedPair}
                                                        timeStart={currenctPredData.BandStart}
                                                        timeEnd={currenctPredData.BandEnd}
                                                        minPrediction={currenctPredData.LowerBand}
                                                        maxPrediction={currenctPredData.UpperBand}
                                                        confidence={currenctPredData.AccuracyProb}
                                                    />
            
                                )}
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
            </Grid>
        </Box>
    );
}

export default PairAnalyticsChart;

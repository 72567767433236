// Chakra imports
import {
    Box, Divider, Flex,
    Grid, Image, Select, Table, Tbody, Text, Th, Thead, Tr, useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import React, {  useState, useEffect, useRef } from 'react';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import PricePredictionLayout from "./PricePrediction/PricePredictionLayout";
import PairAnalyticsChart from "./PairAnalyticsChart";
import axios from "axios";
import {useTranslation} from "react-i18next";

function PairAnalyticsPricePrediction({ selectedPair }) {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const { colorMode } = useColorMode();
    const [predData, setPredData] = useState({});
    const [loading, setLoading] = useState(true);
    const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-priceprediction-api';
    const { t } = useTranslation()
    useEffect(() => {
      const updateData = async () => {
        try {
          setLoading(true)
          const userFromStorage = localStorage.getItem('_Attributes') || '';
          const res = await axios.post(cloudFunctionURL, {
            token: userFromStorage,
            pair: selectedPair
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          setPredData(res)
          setTimeout(() => {
            setLoading(false)
          }, 400);
  
        } catch (error) {
          console.error('Error logging in', error.response ? error.response.data.error : error.message);
        }
      };
      updateData();
  
    }, [selectedPair]);
    return (
        <Box>
            <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ lg: "repeat(2, auto)" }}
                gap='20px'>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px"     bg={
                    colorMode === "dark"
                        ? "gray.800"
                        : "white" }>
                    <CardHeader p="6px 0px 22px 0px">
                        <Flex direction="flex">
                            <Text fontSize="lg" color={textColor} fontWeight="bold" >
                                {t('PriceRangePredictions')}
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        
                        <Box>
                            <Text fontSize="md" color={"gray.500"} fontWeight="bold" mb={4}>
                                {t('CurrentPrediction')}
                            </Text>
                            {loading ? (
                                    <div>Loading...</div>
                                ) : (
                            <PairAnalyticsChart selectedPair={selectedPair} data={predData}/>
                            )}
                        </Box>
                        <Box>
                            <Text fontSize="md" color={"gray.500"} fontWeight="bold" mb={4}>
                                {t('PreviousPrediction')}
                            </Text>
                            {loading ? (
                                    <div>Loading...</div>
                                ) : (
                            <PricePredictionLayout selectedPair={selectedPair} data={predData}/>
                        )}
                        </Box>
                    </CardBody>
                </Card>

            </Grid>
        </Box>
    );
}

export default PairAnalyticsPricePrediction;

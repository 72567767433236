import {Box, Text, Icon, Flex, useColorModeValue, Grid, Divider} from '@chakra-ui/react';
import IconBox from "../../Icons/IconBox";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import React from "react";
import PairView from "../../PairView/PairView"; // Import icons from Chakra UI

// Define a component that takes prediction details as props
const CurrentPredictionCard = ({pair, timeStart, timeEnd, minPrediction, maxPrediction, confidance}) => {
    const bgColor = useColorModeValue('navy.900', 'green.500');
    const textColor = useColorModeValue('white', 'white');
    const iconBoxInside = useColorModeValue("white", "white");
    return (
        <Box maxH="450px" maxW="sm" borderWidth="2px" borderRadius="lg" overflow="hidden" boxShadow="0 12px 12px 0 rgb(0 0 0 / 16%)" >
            <Box p="2" bg={"navy.900"} justifyContent="center" alignItems="center" >
                <PairView pair = {pair} textColor={textColor} />
                <Text fontWeight="bold" fontSize="sm" letterSpacing="wide" textAlign="start" color={textColor}>
                    {timeStart}-{timeEnd} Prediction
                </Text>
            </Box>
            <Box mt="7" p="6" align="center">
                <Text bg="green.400" fontWeight="semibold" fontSize={"lg"} lineHeight="tight" isTruncated color={"white"}>
                    {maxPrediction}
                </Text>
                <Text mb="2" color = "green.400" fontWeight="semibold" fontSize={"sm"} lineHeight="tight" isTruncated>
                    Maximum Prediction
                </Text>
                <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    mt={'4'}
                    bg={'green.400'}>
                    <FaArrowUp h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
                <Divider mt={5} mb={5} orientation='horizontal' />
                <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    mt={'8'}
                    bg={'red.500'}>
                    <FaArrowDown h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
                <Text mb="2" color = "red.300" fontWeight="semibold" fontSize={"sm"} lineHeight="tight" isTruncated>
                    Minimum Prediction
                </Text>
                <Text bg="red.500" fontWeight="semibold" fontSize={"lg"} lineHeight="tight" isTruncated color={"white"}>
                    {minPrediction}
                </Text>
            </Box>
        </Box>
    );
};

export default CurrentPredictionCard;
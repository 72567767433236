// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid, Icon,
    Progress, Select,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";


import React, {useEffect, useState} from "react";
import StrengthPerformance from "./CurrencyStrength/StrengthPerformance";
import StrengthRanking from "./CurrencyStrength/StrengthRanking";
const StrengthOverviewLayout = ({data}) => {
    const { colorMode } = useColorMode();
    const [strengthPerformanceData, setStrengthPerformanceData] = useState({});
    const [strengthRankingData, setStrengthRankingData] = useState({});
    const [loading, setLoading] = useState(true);
    const textColor = useColorModeValue("navy.900", "white");
    useEffect(() => {
        const momentum_data = data.data.mi.currency_strength
        setStrengthPerformanceData(momentum_data["CurrencyPerformance"])
        setStrengthRankingData(momentum_data["CurrencyRanking"])
        setLoading(false);

    }, [data]);
    return (
        <Grid
            templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap='20px'>
            {loading ? (
                <div>Loading...</div>
            ) : (
            <StrengthPerformance data={strengthPerformanceData}/>
            )}
            {loading ? (
                <div>Loading...</div>
            ) : (
            <StrengthRanking data={strengthRankingData}/>
            )}
        </Grid>
    );
}
export default StrengthOverviewLayout;




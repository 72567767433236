import {
    Box,
    Flex,
    Heading,
    HStack,
    Spinner,
    Tab,
    TabList, TabPanel, TabPanels,
    Tabs,
    Tag,
    useColorModeValue,
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import IndicatorsTable from "./IndicatorsTable";
import PairView from '../../PairView/PairView';
import axios from 'axios';

export default function Indicators({selectedPair}) {
    const [selectedKey, setSelectedKey] = useState('1d');
    const textColor = useColorModeValue("navy.900", "white");
    const [taData, setTAData] = useState({});
    const [loading, setLoading] = useState(true);
    const intervalsDict = [
        {"5min": "5 minutes"},
        {"15min": "15 minutes"},
        {"1h": "1 hour"},
        {"4h": "4 hours"},
        {"1d": "1 day"}
    ];

    const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-ta-api';

    useEffect(() => {
        const updateData = async () => {
            try {
                setLoading(true)
                const res = await axios.post(cloudFunctionURL, {
                    selectedInterval: selectedKey,
                    asset: selectedPair
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setTAData(res)
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
            }
        };
        updateData();
    }, [selectedPair, selectedKey]);

    const handleIntervalChange = (index) => {
        const selectedIntervalKey = Object.keys(intervalsDict[index])[0]; // Gets the key of the selected interval
        setSelectedKey(selectedIntervalKey);
    };
    return (
        <Box
            maxH="700px"
            bg={useColorModeValue("white", "gray.800")}
            p={6}
            alignItems={"center"}
            justifyContent={"center"}
            display="column"
            overflowY="auto"  // Enable vertical scrolling
            sx={{
                '&::-webkit-scrollbar': {
                    width: '10px', // Thin scrollbar
                    borderRadius: '16px', // Radius
                    backgroundColor: `rgba(0, 0, 0, 0.05)`, // Scrollbar track color
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#003776', // Scrollbar color (blue)
                    borderRadius: '16px', // Radius
                }
            }}
        >
                <Tabs isFitted variant='enclosed' onChange={handleIntervalChange}
                      index={intervalsDict.findIndex(interval => Object.keys(interval)[0] === selectedKey)}>
                    <TabList>
                        {intervalsDict.map(interval => (
                          <Tab key={Object.keys(interval)[0]} _selected={{ color: 'white', bg: '#001b3b'}}>
                              {Object.values(interval)[0]}
                          </Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {intervalsDict.map((interval, index) => (
                          <TabPanel key={index}>
                              <HStack spacing={4}>
                                  <Heading fontSize="xs" fontWeight={600} fontFamily="body" color="gray.400">
                                      <Flex>
                                          <PairView pair={selectedPair} textColor={textColor}/>
                                      </Flex>
                                  </Heading>
                                  <Tag size="sm" key="hourly" variant='solid' colorScheme='twitter'>
                                      {Object.values(interval)[0]}
                                  </Tag>
                              </HStack>
                          </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            {loading ? (
              <Spinner mt={'10rem'} size="xl" color={"blue"} thickness="2px"  />
            ) : (
              <>
            <IndicatorsTable data={taData} asset={selectedPair}/>
              </>
            )}
        </Box>
    );
}
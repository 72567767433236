// Chakra imports
import {
  Alert, AlertIcon,
  Box, Container, Divider,
  Flex,
  useColorModeValue, useDisclosure
} from "@chakra-ui/react";
// Custom components
import HotCurrencyCardLayout from "../../components/MarketDynamicsComponents/HotCurrencyCardLayout";
import MomentumOverviewLayout from "../../components/MarketDynamicsComponents/MomentumOverviewLayout";
import VolatilityOverviewLayout from "../../components/MarketDynamicsComponents/VolatilityOverviewLayout";
import StrengthOverviewLayout from "../../components/MarketDynamicsComponents/StrengthOverviewLayout";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import SpinnerLoading from "../../components/Spinner/SpinnerLoading";
import TopSellList from "../../components/MarketDynamicsComponents/SellListLayout";
import TopBuyList from "../../components/MarketDynamicsComponents/BuyListLayout";
import PriceChangeMatrixLayout from "../../components/MarketDynamicsComponents/PriceChangeMatrixLayout";
import Footer from "../../components/Footer/Footer";
import InformationAccordion from "../../components/MarketDynamicsComponents/InformationAccordion";
import WelcomeModal from "../../components/Modals/WelcomeModal";
import { useTranslation } from 'react-i18next';
import AmetricaFooter from "../../components/Footer/AmetricaFooter";
import CurrencyBanner from "../../components/MarketDynamicsComponents/CurrencyBanner";
import NewsPage from "../../components/MarketDynamicsComponents/News";
import InvestingCalendar from "../../components/MarketDynamicsComponents/InvestingCalendar";

export default function MarketDynamics() {
  const [miData, setMiData] = useState({});
  const [loading, setLoading] = useState(true);
  const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-mi-api';
  const { t } = useTranslation()
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('visited');
    if (!hasVisitedBefore) {
      localStorage.setItem('visited', true);
    }
    const updateData = async () => {
      try {
        setLoading(true)
        const userFromStorage = localStorage.getItem('_Attributes') || '';
        const res = await axios.post(cloudFunctionURL, {
          token: userFromStorage,
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setMiData(res)
        setTimeout(() => {
          setLoading(false)
        }, 400);

      } catch (error) {
        console.error('Error logging in', error.response ? error.response.data.error : error.message);
      }
    };
    updateData();

  }, []);
  return (
      <Container maxW={'7xl'}>
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }} overflowX={"hidden"}>
      {loading ? (
          <SpinnerLoading />
      ) : (
          <Box>
            <InformationAccordion/>
            <Divider mt="2" mb="10" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.1" />
            <NewsPage/>
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <InvestingCalendar/>
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <HotCurrencyCardLayout data={miData} />
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <MomentumOverviewLayout data={miData} />
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <StrengthOverviewLayout data={miData} />
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <CurrencyBanner/>
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <VolatilityOverviewLayout data={miData} />
            <Divider mt="2" mb="2"borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <TopBuyList data={miData} />
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <TopSellList data={miData} />
            <Divider mt="10" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <PriceChangeMatrixLayout />
            <Divider mt="2" mb="2" borderColor={useColorModeValue("gray.200", "gray.700")} borderWidth="0.2" />
            <Alert status='info' variant='left-accent' mt={10}>
              <AlertIcon />
              {t('DataWarningMessage')}
            </Alert>
            <Box >
              <AmetricaFooter />
            </Box>
          </Box>
        )}
    </Flex>
      </Container>
  );
}

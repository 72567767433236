'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    useColorModeValue,
    Text,
    Container, Heading,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import React from "react";
import {useTranslation} from "react-i18next";

export default function FAQ() {
    const { t } = useTranslation()
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'black')}>
            <Container maxW={'8xl'}>
                <Heading fontSize={'4xl'} mb={10} textAlign={"left"}>{t('FAQHeader')}</Heading>
                <Accordion allowMultiple width="100%" maxW="8xl" rounded="lg">
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ1Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ1A')}
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ2Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ2A')}
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ3Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ3A')}
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ4Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ4A')}
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ5Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ5A')}

                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}>
                            <Text fontSize="md">{t('FAQ6Q')}</Text>
                            <ChevronDownIcon fontSize="24px" />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Text color="gray.600">
                                {t('FAQ6A')}
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Container>
        </Flex>
    )
}
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, useColorMode, Button, Box,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import MarketDynamicsFeatureImage from "assets/img/MarketDynamicsFeature.png";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {MdInsights} from "react-icons/md";
import {useTranslation} from "react-i18next";

interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function MarketDynamicsFeature() {
    const handleClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/fx/market-dynamics`;
        window.location.href = navPath; // Redirects to the specified path
    };
    const { t } = useTranslation()
    const { colorMode } = useColorMode();
    return (
        <Container maxW={'8xl'} mt={5} mb={5} py={7} px={10} bg={useColorModeValue('white', 'gray.900')} borderRadius={"16px"} boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'} >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4} py={20}>
                    <Heading fontSize={'3xl'}>{t('LandingFeature1Header')}{' '}</Heading>
                    <Text  fontSize={'lg'}>
                        {t('LandingFeature1Desc')}
                    </Text>
                    <Button width={'100%'}
                            borderRadius={"15px"}
                            rightIcon={<MdInsights />}
                            bgColor='teal' color={"white"}
                            onClick={handleClick}
                            variant='solid'
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'sm',
                            }}
                    >
                        {t('LandingFeature1Button')}
                    </Button>
                </Stack>
                <Flex position="relative">
                    <Image
                        alt={'feature image'}
                        src={MarketDynamicsFeatureImage}
                        objectFit={'cover'}
                        rounded={"xl"}
                        boxShadow={'lg'}
                        overflow={'hidden'}
                        transition={'all 0.3s ease'}
                        _hover={{ boxShadow: 'xl', transform: 'scale(1.50)' }}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    );
}
import {
    Container,
    SimpleGrid,
    Flex,
    Heading,
    Text,
    Stack,
    useColorModeValue,
    useColorMode,
    AspectRatio,
    useMediaQuery
} from '@chakra-ui/react';
import GPTVideo from "assets/img/gptvideo.mp4";
import React, { ReactElement } from 'react';

interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function InsightsFeature() {
    const { colorMode } = useColorMode();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <Container
            maxW={'5xl'}
            mt={2}
            py={12}
            bg={useColorModeValue('white', 'gray.900')}
            borderRadius={"16px"}
            boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'}>
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                <Flex alignItems="center" justifyContent="center">
                    {isMobile ? (
                        <video
                            autoPlay
                            loop
                            muted
                            style={{
                                borderRadius: '16px',
                                boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.1)',
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover'
                            }}
                            playsInline
                        >
                            <source src={GPTVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <AspectRatio ratio={16 / 9} w="100%">
                            <video
                                autoPlay
                                loop
                                muted
                                style={{
                                    borderRadius: '16px',
                                    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <source src={GPTVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AspectRatio>
                    )}
                </Flex>
            </SimpleGrid>
        </Container>
    );
}

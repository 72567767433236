import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Progress,
    Icon,
    Button,
    useColorModeValue, Badge, Box, Image, Tooltip,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import PairView from "../PairView/PairView";
import {useHistory} from "react-router-dom";
import {FaDiscord, FaTachometerAlt} from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function DashboardTableRow(props) {
  const { name, status, interval, progression, isLast } = props;
  const textColor = useColorModeValue("gray.400", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const history = useHistory();
  const { t } = useTranslation()

    return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px" borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Box display="flex" alignItems="center" ml={'10'}>
                <Flex direction="column">
                    <Flex>
                        <PairView pair={name} textColor={textColor}/>
                    </Flex>
                </Flex>
            </Box>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {interval}
        </Text>
      </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge
                bg={
                status === t("StrongBuy") ? "green.500" :
                    status === t("Buy") ? "green.400" :
                        status === t("Neutral") ? "yellow.400" :
                            status === t("Sell") ? "red.300" :
                                status === t("StrongSell") ? "red.600" :
                                    "blue.400" // Replace 'defaultColor' with your default color
            }
                color={status === t("Buy") ? "white" : "white"}
                fontSize="16px"
                p="3px 10px"
                borderRadius="8px"
            >
                {status}
            </Badge>
        </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={
              status === t("StrongBuy") ? "green.500" :
                  status === t("Buy") ? "green.400" :
                      status === t("Neutral") ? "yellow.400" :
                          status === t("Sell") ? "red.300" :
                              status === t("StrongSell") ? "red.600" :
                                  "blue.400" // Replace 'defaultColor' with your default color
          }
            fontWeight="bold"
            pb=".2rem"
          >{`${progression}%`}</Text>
          <Progress
            colorScheme={"twitter"}
            size="md"
            value={progression}
            borderRadius="25px"
          />
        </Flex>
      </Td >
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Tooltip label={t('ShowMoreAboutPair')} openDelay={500} bg='navy.900' borderRadius={"8px"}>
            <Button p="0px" bg="transparent" variant="no-effects"  onClick={() => history.push(`/fx/pair-analytics?pair=${name}`)}>
              <Icon as={FaTachometerAlt} color="gray.400" cursor="pointer" />
            </Button>
          </Tooltip>
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;

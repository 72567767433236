import React from 'react';
import {
    AbsoluteCenter,
    Box,
    Divider,
    Flex,
    Text,
    VStack
} from "@chakra-ui/react";

import GoogleLoginComponent from "./GoogleLogin";
import AmetricaLogin from "./AmetricaLogin";
import {NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AuthStack = () => {
    const { t } = useTranslation()
    return (
        <VStack spacing='15px' justify='center' mb='22px' px={15}>
          <GoogleLoginComponent/>
            <Box position='relative' padding='10' width={"100%"}>
                <Divider colorScheme={"whiteAlpha"} size={"xl"}/>
                <AbsoluteCenter color={"white"} px='4'>
                {t('AuthStackOr')}
                </AbsoluteCenter>
            </Box>
            <AmetricaLogin/>
            <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={"gray.500"} fontWeight='medium'>
                {t('AuthStackDontHaveAccount')} {' '}
                    <NavLink
                        color={"blue"}
                        as='span'
                        ms='5px'
                        to='/ametrica-fx/signup'
                        fontWeight='bold'>
                        {t('AuthStackSignUp')}
                    </NavLink>
                </Text>
            </Flex>
        </VStack>
    );
};

export default AuthStack;

import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  Box,
  Flex,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation, getI18n } from "react-i18next";

const languageOptions = [
  { label: "EN", value: "en", flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg" },
  { label: "TR", value: "tr", flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg" },
];

const defaultLanguageOption = { label: "EN", value: "en", flag: "https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png" };

const LanguageDropdown = () => {
  const textColor = useColorModeValue("#44337A", "white")
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || getI18n().language);
  const flagSize = useBreakpointValue({ base: "15px", md: "20px" })

  useEffect(() => {
    changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };



  return (
    <Menu marginRight="1rem" >
      <MenuButton as={Button} colorScheme="white" p={1} rightIcon={null} >
        <Flex align="center">
          <Image src={(languageOptions.find(option => option.value === selectedLanguage) || defaultLanguageOption).flag} width={flagSize} height="15px" alt="Flag" marginRight={2} />
          <Box as="span" color="#4a5568">{(languageOptions.find(option => option.value === selectedLanguage) || defaultLanguageOption).label}</Box>
        </Flex>
      </MenuButton>
      <MenuList minW="80px">
        {languageOptions.map((option) => (
          option.value !== selectedLanguage &&
          <MenuItem key={option.value}
                    onClick={() => {
                      setSelectedLanguage(option.value);
                    }}>
            <Flex align="center">
              <Image src={option.flag} width={flagSize} height="15px" alt="Flag" marginRight={2} />
              <Box as="span" color={textColor} >{option.label}</Box>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageDropdown;

import {
    Alert, AlertIcon,
    Box, Button, Container, Divider,
    Flex, Grid, Icon, Spinner,
    Text, useColorMode,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CurrencySelectBar from "../../components/SelectBar/CurrencySelectBar";
import CurrencyView from "../../components/CurrencyView/CurrencyView";
import CurrencyPairStrength from "../../components/CurrencyOverviewComponents/CurrencyPairStrengthOverviewLayout";
import CurrencyPairVolatility from "../../components/CurrencyOverviewComponents/CurrencyPairVolatilityOverviewLayout";
import axios from "axios";
import {FaCube} from "react-icons/fa";
import CurrencyTopSellList from "../../components/CurrencyOverviewComponents/CurrencySellListLayout";
import CurrencyTopBuyList from "../../components/CurrencyOverviewComponents/CurrencyBuyListLayout";
import SignInModal from "../../components/Modals/SignInModal";
import AmetricaFooter from "../../components/Footer/AmetricaFooter";
import PairBanner from "../../components/MarketDynamicsComponents/PairBanner";
import {useTranslation} from "react-i18next";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}
function CurrencyOverview() {
    const query = useQuery();
    const [selectedCurrency, setSelectedCurreny] = useState("EUR");
    const [currencyData, setCurrencyData]= useState({});
    const iconColor = useColorModeValue("blue.500", "white");
    const [userPlan, setUserPlan] = useState(null);
    const toppicksRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();
    const textColor = useColorModeValue("gray.700", "white");
    const borderProfileColor = useColorModeValue("white", "transparent");
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const strengthref = useRef(null);
    const volatinityref = useRef(null);
    const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-currency-api';
    const { t } = useTranslation()
    const scrollToRef = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        let currencyParam = query.get('currency');
        if (!currencyParam) {
            currencyParam = "EUR";
        }
        setSelectedCurreny(currencyParam);
        const localUserPlan  = localStorage.getItem('_UserTraits');
        setUserPlan(localUserPlan)
        if (localUserPlan === "") {
            onOpen();
        }
        const updateData = async () => {
            try {
                setLoading(true)
                const userFromStorage = localStorage.getItem('_Attributes') || '';
                const res = await axios.post(cloudFunctionURL, {
                    token: userFromStorage,
                    currency: currencyParam
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setCurrencyData(res)
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
            }
        };
        updateData();

    }, [selectedCurrency]);

    const handleSelectChange = (selectedValue) => {
        setSelectedCurreny(selectedValue)
        localStorage.setItem('selectedCurrency', selectedValue);
        history.push(`/fx/currency-overview?currency=${selectedValue}`);
    };

    return (
        <Container maxW={'7xl'}>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }} overflowX="hidden">
            <Flex
                direction={{ sm: "column", md: "row" }}
                mb='24px'
                maxH='330px'
                border='1px solid'
                justifyContent={{ sm: "center", md: "space-between" }}
                borderColor={"gray.700"}
                bg={useColorModeValue("white", "black")}
                p='24px'
                borderRadius='20px'>
                <CurrencySelectBar onSelectChange={handleSelectChange} value={selectedCurrency} />
                <Flex
                    align='center'
                    mb={{ sm: "10px", md: "0px" }}
                    direction={{ sm: "column", md: "row" }}
                    w={{ sm: "100%" }}
                    justifyContent={"center"}
                    textAlign={{ sm: "center", md: "center" }}>
                    <CurrencyView currency = {selectedCurrency} textColor={textColor}/>
                    <Text fontSize='md' color={'gray.300'} fontWeight='bold' ml={1}>
                        {" "} {t('CurrencyAnalytics')}
                    </Text>
                </Flex>
                <Flex
                    direction={{ sm: "column", lg: "row" }}
                    w={{ sm: "100%", md: "50%", lg: "auto" }}>
                    <Button p='0px' bg='transparent' variant='no-effects'>
                        <Flex
                            align='center'
                            w={{ sm: "100%", lg: "135px" }}
                            bg={colorMode === "dark" ? "navy.900" : "#fff"}
                            borderRadius='8px'
                            justifyContent='center'
                            onClick={() => scrollToRef(toppicksRef)}
                            py='10px'
                            boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.06)'
                            cursor='pointer'>
                            <Icon color={textColor} as={FaCube} me='6px' />
                            <Text fontSize='xs' color={textColor} fontWeight='bold'>
                                {t('TopPicks')}
                            </Text>
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
            {loading ? (
                <Box
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                >
                    <Spinner mt={'5rem'} size="xl" color={"blue"} thickness="2px"  />
                </Box>
            ) : (
            <Box>
                <Grid
                    templateColumns={{ sm: "1fr", lg: "1fr" }}
                    templateRows={{ lg: "repeat(2, auto)" }}
                    gap='20px'>
                </Grid>
                <Box ref={strengthref}>
                    <CurrencyPairStrength data ={currencyData} />
                </Box>
                    <Box ref={volatinityref}>
                        <CurrencyPairVolatility data ={currencyData} />
                    </Box>
                <PairBanner/>
                <Box ref={toppicksRef}>
                    <CurrencyTopBuyList data ={currencyData} />
                </Box>
                <Box>
                    <CurrencyTopSellList data ={currencyData} />
                </Box>
                <SignInModal isOpen={isOpen} onClose={onClose} headerText={t('LoginFreeCurrency')}/>

                <Alert status='info' variant='left-accent' mt={10}>
                    <AlertIcon />
                    {t('DataWarningMessage')}
                </Alert>
                <Box >
                    <AmetricaFooter />
                </Box>
            </Box>
            )}

        </Flex>
        </Container>
    );
}

export default CurrencyOverview;

'use client'

import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon, Image, useColorModeValue,
} from '@chakra-ui/react'
import eur from "assets/img/currency-logo/eur-logo.svg";
import usd from "assets/img/currency-logo/usd-logo.svg";
import cad from "assets/img/currency-logo/cad-logo.svg";
import chf from "assets/img/currency-logo/chf-logo.svg";
import jpy from "assets/img/currency-logo/jpy-logo.svg";

import React from "react";
import SignUpStack from "../../components/Auth/SignUpStack";
import LogoBig from "../../assets/img/AMETRICA_LOGO-BIG.png";
import LogoBigDark from "../../assets/img/AMETRICA_LOGO-BIG-DARK.png";
import { useTranslation } from 'react-i18next';

const avatars = [
    {
        url: eur,
    },
    {
        url: usd,
    },
    {
        url: cad,
    },
    {
        url: chf,
    },
    {
        url: jpy,
    },
]

const Blur = (props: IconProps) => {
    return (
        <Icon
            width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
            zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
            height="560px"
            viewBox="0 0 528 560"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="71" cy="61" r="111" fill="#AACCFF" />
            <circle cx="244" cy="106" r="139" fill="#7788AA" />
            <circle cy="291" r="139" fill="#445588" />
            <circle cx="80.5" cy="189.5" r="101.5" fill="#334477" />
            <circle cx="196.5" cy="317.5" r="101.5" fill="#223366" />
            <circle cx="70.5" cy="458.5" r="101.5" fill="#112255" />
            <circle cx="426.5" cy="-0.5" r="101.5" fill="#001144" />
        </Icon>
    )
}

export default function SignUp() {
    const { t } = useTranslation()
    return (
        <Box position={'relative'}>
            <Container
                as={SimpleGrid}
                maxW={'7xl'}
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 10, lg: 32 }}
                py={{ base: 10, sm: 20, lg: 40 }}
                px={{ base: 10, sm: 0, lg: 40 }}>
                <Stack spacing={{ base: 10, md: 20 }} mt={3}>
                    <Heading
                        lineHeight={1.1}
                        fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
                        {t('SignUpTradeFirst')} {' '}
                        <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                        {t('SignUpTradeMid')}
                        </Text>{' '}
                        {t('SignUpTradeLast')}
                    </Heading>
                    <Stack direction={'row'} spacing={2} align={'center'}>
                        <AvatarGroup>
                            {avatars.map((avatar) => (
                                <Avatar
                                    key={avatar.name}
                                    src={avatar.url}
                                    size={useBreakpointValue({ base: 'md', md: 'lg' })}
                                    position={'relative'}
                                    zIndex={2}
                                    _before={{
                                        content: '""',
                                        width: 'full',
                                        height: 'full',
                                        rounded: 'full',
                                        transform: 'scale(1.125)',
                                        bgGradient: 'linear(to-bl, blue.100,blue.400)',
                                        position: 'absolute',
                                        zIndex: -1,
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            ))}
                        </AvatarGroup>
                        <Text fontWeight={"bold"} fontSize={{ base: '1xl', md: '2xl' }}>
                            + {t('SignUpTradeManyMore')}
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    bg={useColorModeValue('white', 'black')}
                    rounded={'xl'}
                    p={{ base: 4, sm: 6, md: 8 }}
                    spacing={{ base: 8 }}
                    maxW={{ lg: 'lg' }}>
                    <Stack spacing={2}>
                        <Heading
                            color={useColorModeValue('black', 'white')}
                            lineHeight={1.1}
                            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                            {t('SignUpJoinToday')}
                            <Text as={'span'} bgGradient="linear(to-r, blue.100,blue.400)" bgClip="text">
                                .
                            </Text>
                        </Heading>
                        <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                        {t('SignUpJoinDescription')}
                        </Text>
                    </Stack>
                    <Box as={'form'} mt={2}>
                        <SignUpStack/>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}
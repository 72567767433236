import {NavLink, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Avatar, Badge,
    Box,
    Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay,
    Flex,
    Icon,
    Menu,
    MenuButton, MenuItem, MenuList, Stack,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import IconBox from "../Icons/IconBox";
import {HSeparator} from "../Separator/Separator";
import decodeJWT from "../../variables/functions";
import {googleLogout} from "@react-oauth/google";
import {FaEllipsis} from "react-icons/fa6";
import {IoIosContact, IoIosExit} from "react-icons/io";
import {ProfileIcon} from "../Icons/Icons";
import {HamburgerIcon} from "@chakra-ui/icons";
import SignInModal from "../Modals/SignInModal";

export function SidebarResponsive(props) {
    // to check for active links and opened collapses
    let location = useLocation();
    const { logo, routes, colorMode, hamburgerColor, ...rest } = props;

    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    const mainPanel = React.useRef();
    // SIDEBAR
    const drawer = useDisclosure()
    const signinmodal = useDisclosure()
    const btnRef = React.useRef();
    const { isSignInOpen, onSignInOpen, onSignInClose } = useDisclosure();
    // Color variables
    const [profile, setProfile] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const userFromStorage = localStorage.getItem('_Attributes');
        if (userFromStorage) {
            const decodedToken = decodeJWT(userFromStorage);
            setProfile(decodedToken);
            localStorage.setItem('_UserTraits', decodedToken.userPlan);
        }
        else {
            localStorage.setItem('_UserTraits', "");
        }
    }, []);
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    let activeBg = useColorModeValue("white", "gray.700");
    let inactiveBg = useColorModeValue("black", "black");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "white");
    let borderColor = useColorModeValue("gray.400", "white");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
    let sidebarBackgroundColor = useColorModeValue("white", "black");

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.category) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <>
                        <Text
                            color={activeColor}
                            fontWeight="bold"
                            mb={{
                                xl: "6px",
                            }}
                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                        >
                            {document.documentElement.dir === "rtl"
                                ? prop.rtlName
                                : prop.name}
                        </Text>
                        {createLinks(prop.views)}
                    </>
                );
            }
            return (
                <NavLink to={prop.layout + prop.path} key={key}>
                    {activeRoute(prop.layout + prop.path) === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg={activeBg}
                            boxShadow={sidebarActiveShadow}
                            mb={{
                                xl: "6px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        bg="black"
                                        color="white"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={activeColor} my="auto" fontSize="sm">
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg="transparent"
                            mb={{
                                xl: "6px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            py="12px"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        bg={inactiveBg}
                                        color="white"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={inactiveColor} my="auto" fontSize="sm">
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };

    var links = <>{createLinks(routes)}</>;

    //  BRAND

    var brand = (
        <Box pt={"35px"} mb="8px">
            {logo}
            <HSeparator my="26px" />
        </Box>
    );

    const logOut = () => {
        googleLogout();
        setProfile(null);
        localStorage.removeItem('_Attributes');
        localStorage.removeItem('_UserTraits');
        history.push('/market-dynamics');
    };

    const colorModeChange = () => {
        toggleColorMode()
        window.location.reload()
    };
    const redirectToProfile = () => {
        const profilePath = `${process.env.PUBLIC_URL}/#/fx/profile`;
        window.location.href = profilePath; // Redirects to the specified path
    };
    class ProfilePicture extends React.Component<{}> {
        render() {
            return (
                <Flex alignItems={'center'}  w="100%"
                      maxW="220px">
                    <Menu size={"xs"}>
                        <Flex justifyContent={"center"} alignItems={"center"}>
                            <MenuButton
                                as={Button}
                                rounded={'full'}
                                variant={'link'}
                                cursor={'pointer'}
                                sx={{
                                    width: '100%',
                                    _hover: { bg: 'gray.900' },
                                    p: '2', // This is a gold-like color; adjust as needed
                                }}
                                minW={0}>
                                <Flex mr={4} justifyContent="space-between" alignItems="center">
                                    <Avatar
                                        size={'xs'}
                                        src={
                                            profile.picture
                                        }
                                    />
                                    <Box alignItems="left">
                                        <Text fontSize={"10px"} color={"gray.400"}>{profile.mail}</Text>
                                        <Badge align={"left"}>
                                            {profile.userPlan}
                                        </Badge>
                                    </Box>
                                    <Icon as={FaEllipsis} color="gray.400" cursor="pointer" ml={4} />
                                </Flex>
                            </MenuButton>

                        </Flex>
                        <MenuList minW="0" w={'100%'}>
                            <MenuItem icon={<IoIosContact />}onClick={redirectToProfile} fontSize={"sm"}>Profile Settings</MenuItem>
                            <MenuItem icon={<IoIosExit />}onClick={logOut} fontSize={"sm"} color={"red.500"}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            );
        }
    }
    const SignInButton = () => {
        return (
            <Button
                ms='0px'
                px='0px'
                color={"white"}
                onClick={signinmodal.onOpen}
                variant='no-effects'
                rightIcon={
                    document.documentElement.dir ? (
                        ""
                    ) : (
                        <ProfileIcon color={"white"} w='22px' h='22px' me='0px' />
                    )
                }
                leftIcon={
                    document.documentElement.dir ? (
                        <ProfileIcon color={"gray.200"} w='22px' h='22px' me='0px' />
                    ) : (
                        ""
                    )
                }>
                <Text display={{ sm: "flex", md: "flex" }}>Sign In</Text>
            </Button>
        );
    };
    return (
        <Flex
            display={{ sm: "flex", xl: "none" }}
            ref={mainPanel}
            alignItems="center"
        >
            <HamburgerIcon
                color={hamburgerColor}
                w="18px"
                h="18px"
                ref={btnRef}
                onClick={drawer.onOpen}
            />
            <Drawer
                isOpen={drawer.isOpen}
                onClose={drawer.onClose}
                placement={document.documentElement.dir === "rtl" ? "right" : "left"}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent
                    w="250px"
                    maxW="250px"
                    ms={{
                        sm: "16px",
                    }}
                    my={{
                        sm: "16px",
                    }}
                    borderRadius="16px"
                    bg={sidebarBackgroundColor}
                >
                    <DrawerCloseButton
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody maxW="250px" px="1rem">
                        <Box maxW="100%" h="100vh">
                            <Box>{brand}</Box>
                            <Stack direction="column" mb="40px">
                                <Box>{links}</Box>
                            </Stack>
                            <Box mt={10}>
                                {profile ? <ProfilePicture /> : <SignInButton />}
                            </Box>
                            <SignInModal isOpen={signinmodal.isOpen} onClose={signinmodal.onClose} headerText={"Welcome to Ametrica"}/>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}
import React from 'react';
import {Box, IconButton, Text} from "@chakra-ui/react";
import {BiRefresh} from "react-icons/bi";

const ErrorMessageBox = () => {
    return (
        <Box textAlign={"center"} display="flex" flexDirection="column" alignItems="center">
            <Text fontSize="xl" fontFamily="body" color="#003776">
                Error loading data, please retry.
            </Text>
            <IconButton
                onClick={() => window.location.reload()}
                mt={2}
                w={50}
                h={50}
                colorScheme='facebook'
                icon={<BiRefresh  />}
            />
        </Box>
    );
};

export default ErrorMessageBox;

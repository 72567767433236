import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useColorModeValue
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";



export default function Hero() {
  const handleSignUpClick = () => {
    // Call the openModal function passed from the parent component
    const signUpPath = `${process.env.PUBLIC_URL}/#/fx/market-dynamics`;
    window.location.href = signUpPath; // Redirects to the specified path
  };
  const { t } = useTranslation()
  return (
    <Container maxW={'5xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 3, md: 5 }}
          mt={20}
          py={{ base: 20, md: 28}}>
          <Heading
            fontWeight={700}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
            lineHeight={'100%'}>
            {t('LandingHeader1')}{' '}
          </Heading>
          <Heading
              fontWeight={700}
              fontSize={{ base: '3xl', sm: '2xl', md: '5xl' }}
              lineHeight={'100%'}>
            {t('LandingHeader2')}{' '}
          </Heading>

          <Text    fontWeight={400}
                   fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} color={'gray.500'} maxW={'4xl'}>
            {t('LandingHeaderDesc')}
          </Text>
          <Stack spacing={2} direction={'row'} mt={10}>
            <Button
                rounded={{ base: 'md', md: 'lg', lg: 'xl' }}
                px={{ base: 4, md: 6, lg: 8 }} // Responsive padding
                py={{ base: 2, md: 3, lg: 4 }} // Responsive padding
                fontSize={{ base: 'md', md: 'lg', lg: 'xl' }} // Responsive font size
                height={{ base: '40px', md: '50px', lg: '60px' }} // Responsive height
                width={{ base: '100px', md: '120px', lg: '150px' }} // Responsive width
                colorScheme={useColorModeValue('white', 'purple.800')}
                bg={useColorModeValue('black', 'white')}
                onClick={handleSignUpClick}
                _hover={{ bg: 'teal' }}>
              {t('LandingGetStarted')}
            </Button>
          </Stack>
        </Stack>
    </Container>
  );
}
import React, {useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Box
} from '@chakra-ui/react';

import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import FinveoSubscriptionDetail from "./FinveoSubscriptionDetail";
import {useHistory} from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";




function FinveoSubscriptionModal({ isOpen, onClose, profile }) {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered bg={"black"} size={"full"} maxH={"350px"}  overflowY={{ sm: "scroll", xl: "scroll" }}>
               
                <ModalOverlay     bg='blackAlpha.300'
                                  backdropFilter='blur(10px)' />
                <ModalContent borderRadius="lg">
                    <ModalHeader
                        paddingY="6"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        bg="black"
                        borderBottom="1px solid"
                        borderBottomColor="gray.200"
                    >
                        <Box>
                            <AmetricaLogo/>
                        </Box>
                        <ModalCloseButton color="gray.500" position="absolute" right="4" top="4" />
                    </ModalHeader>
                    <ModalBody
                               display="flex"
                               flexDirection="column"
                               alignItems="center"
                               backgroundColor={"black"}
                               overflowY={{ sm: "scroll", xl: "hidden" }}
                               justifyContent="center">
                        <FinveoSubscriptionDetail profile={profile}/>
                        <Box width ={"75%"} mt={"5rem"}>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default FinveoSubscriptionModal;
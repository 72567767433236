import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import  {store}  from './ReduxToolkit/store';
import { Provider } from 'react-redux';
import {GoogleOAuthProvider} from "@react-oauth/google";
import './i18n';

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <HashRouter>
        <Provider store={store}>
            <GoogleOAuthProvider clientId="442107147924-e4db3lq627puld5sf6q8o6oi6lthvgf0.apps.googleusercontent.com">
                      <Switch>
                        <Route path={`/ametrica-fx`} component={AuthLayout} />
                        <Route path={`/fx`} component={AdminLayout} />
                        <Redirect from={`/`} to="/ametrica-fx/home" />
                      </Switch>
            </GoogleOAuthProvider>
        </Provider>
    </HashRouter>
  </ChakraProvider>,
  document.getElementById("root")
);

import {
    Grid,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import { CiWavePulse1 } from "react-icons/ci";
import React, {useEffect, useState} from "react";
import CurrencyVolatilityPerformance from "./CurrencyVolatility/CurrencyVolatilityPerformance";
import CurrencyVolatilityTable from "./CurrencyVolatility/CurrencyVolatilityTable";

const CurrencyPairVolatility = ({data}) => {
    const [volatilityData, setVolatilityData] = useState({});
    const [volatilityRankingData, setVolatilityRankingData] = useState({});
    const [volatilityPerformanceData, setVolatilityPerformanceData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const volatility_data = data.data.currency.pair_volatility
        setVolatilityData(volatility_data)
        setVolatilityRankingData(volatility_data["VolatilityRanking"])
        setVolatilityPerformanceData(volatility_data["VolatilityPerformance"])
        setLoading(false);

    }, [data]);
    return (
        <Grid
            templateColumns={{ sm: "1fr", lg: "1fr 2fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap='20px'>
            <CurrencyVolatilityTable data={volatilityRankingData} icon={CiWavePulse1} />
            {loading ? (
                <div>Loading...</div>
            ) : (
                <CurrencyVolatilityPerformance data={volatilityPerformanceData}/>
            )}
        </Grid>
    );
}
export default CurrencyPairVolatility;



import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, useColorMode, Button, Box, IconProps, useBreakpointValue, AvatarGroup, Avatar,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {MdInsights} from "react-icons/md";
import AIERA from "../../assets/img/Hero3.png";



interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function PairBanner() {
    const handleClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/fx/pair-analytics`;
        window.location.href = navPath; // Redirects to the specified path
    };
    return (
        <Container maxW={'7xl'} mt={5} mb={5} py={2} px={10}
                   backgroundImage={AIERA} // Ensure this is the correct path to your image
                   backgroundSize="cover"
                   backgroundPosition="center"
                   borderRadius={"15px"} boxShadow={'0px 16px 24px rgba(0, 0, 0, 0.1)'} >
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                <Stack spacing={4} py={10} just>
                    <Heading color="white" fontSize={'3xl'}>Deep Dive into Pairs</Heading>
                    <Heading color="white" fontSize={'2xl'}>Get Price Range Predictions and AI Optimized Indicators</Heading>
                    <Button width={{ sm: '80%', md: '40%' }}
                            borderRadius={"15px"}
                            rightIcon={<MdInsights />}
                            bgColor='white' color={"navy.900"}
                            variant='solid'
                            onClick={handleClick}
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'sm',
                            }}
                    >
                        Pair Analytics
                    </Button>
                </Stack>
            </SimpleGrid>
        </Container>
    );
}
import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';

const SpinnerLoading = () => {
    return (
        <Box
            position="full-screen" // Use "fixed" for full-screen or "absolute" for within a container
            w={"100%"}
            h={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"15px"}// Make sure this color exists in your theme or use a CSS color
            zIndex="overlay" // Ensures it's above most other elements
        >
            <Spinner
                thickness="4px"
                speed="0.85s"
                emptyColor="gray.200"
                color="teal"
                size="xl"
            />
        </Box>
    );
};

export default SpinnerLoading;

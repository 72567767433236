import {Text, Box, Flex, Image, Spinner, useColorModeValue} from '@chakra-ui/react';
import avatar5 from "assets/img/avatars/avatar5.png";
import glemm from "assets/img/avatars/gptlogo.png";
import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import ReactMarkdown from 'react-markdown';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const ChatMessage = ({ message, isUser }) => {
  const [typedMessage, setTypedMessage] = useState('');
  const [loading, setLoading] = useState(true);


  const UserProfile = () => (
    <Flex align='center' mb={5}>
      <Flex direction='column'>
        <Text fontSize='sm' color={"white"} fontWeight='bold'>
          You{" "}
        </Text>
      </Flex>
    </Flex>
  );

  const GPTProfile = () => (
    <Flex align='center' mb={5}>
      <Image
        src={glemm}
        w='25px'
        h='25px'
        borderRadius='full'
        me='10px'
      />
      <Flex direction='column'>
        <Text fontSize='sm' color={useColorModeValue('gray.700', 'white')} fontWeight='bold'>
          Ametrica GPT{" "}
        </Text>
      </Flex>
    </Flex>
  );

  useEffect(() => {
    if (!isUser && message) {
      setLoading(true); // Start loading when component mounts and message is not yet displayed
      let currentIndex = 0;
      const interval = setInterval(() => {
        setTypedMessage((prev) => prev + message.charAt(currentIndex));
        currentIndex++;
        if (currentIndex === 1) {
          setLoading(false); // Stop loading when typing starts
        }
      }, 2); // Adjust the typing speed as needed
      return () => clearInterval(interval);
    }
  }, [message, isUser]);

  return (
    <Box bg={isUser ? useColorModeValue('gray.800', 'gray.800') : useColorModeValue('gray.50', 'gray.900')} p={10} borderRadius="md">
      <Box>
        {isUser ? <UserProfile/> : <GPTProfile/>}
      </Box>
      {isUser ? (
        <Text fontSize='sm' color={useColorModeValue('white', 'white')}>
          {message}
        </Text>
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{typedMessage}</ReactMarkdown>
      )}
    </Box>
  );
};

export default ChatMessage;

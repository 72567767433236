import React, { Component } from "react";
import Chart from "react-apexcharts";

class StrengthBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                name: "Strength",
                data: [],
            }],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                tooltip: {
                    theme: "dark",
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors: "#A0AEC0",
                            fontSize: "12px",
                        },
                    },
                    show: true,
                    axisBorder: {
                        show: false,
                    },
                },
                yaxis: {
                    show: true,
                    color: "#A0AEC0",
                    labels: {
                        show: true,
                        style: {
                            colors: "#A0AEC0",
                            fontSize: "14px",
                        },
                    },
                },
                fill: {
                    colors: "#0d73f5",
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    strokeDashArray: 5,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 15,
                        columnWidth: "15px",
                    },
                },
                responsive: [
                    {
                        breakpoint: 768,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    borderRadius: 0,
                                },
                            },
                        },
                    },
                ],
            },
        };
    }

    componentDidMount() {
        this.updateChartData(this.props.chartData);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chartData !== this.props.chartData) {
            this.updateChartData(this.props.chartData);
        }
    }

    updateChartData(chartData) {
        const categories = chartData.map(item => item.Asset);
        const seriesData = chartData.map(item => parseFloat((item.Strength * 100).toFixed(2)));


        this.setState(prevState => ({
            options: {
                ...prevState.options,
                xaxis: { ...prevState.options.xaxis, categories: categories },
            },
            series: [{ ...prevState.series[0], data: seriesData }],
        }));
    }

    render() {
        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width="100%"
                height="100%"
            />
        );
    }
}

export default StrengthBarChart;

import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
      800: "#1E222D",
      900: "#131722"
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#004494",
      600: "#24388a",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
    blue: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#2962ff",
      400: "#2962ff",
      500: "#004494",
      600: "#204ECC",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
    red: {
      300: "#f23645",
      400: "#d9303e",
      500: "#C12B37"
    },
    green: {
      300: "#358E38",
      400: "#338736",
      500: "#205622"
    }
  },
  config: {
    initialColorMode: "light", // Set initial color mode to dark
    useSystemColorMode: false, // Disable system color mode detection
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("gray.50", "black")(props),
        fontFamily: "Arial, sans-serif", // Changed to Arial
      },
      html: {
        fontFamily: "Arial, sans-serif", // Changed to Arial
      },
    }),
  },
};

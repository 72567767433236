import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Link,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner, Stack,
    Switch,
    Text,
    useColorModeValue, VStack
} from "@chakra-ui/react";
import {GoogleLogin} from "@react-oauth/google";
import React, {useState} from "react";
import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import {useHistory} from "react-router-dom";
import AuthStack from "../Auth/AuthStack";
import { useTranslation } from 'react-i18next';


function SingInModal({isOpen , onClose, headerText }) {
    const textColor = useColorModeValue("navy.700", "white");
    const history = useHistory();
    const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-auth'; // Replace with your Cloud Function URL
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const handleCloseModal = () => {
        onClose
        history.push('/#/fx/market-dynamics'); // Redirect to homepage
    };

    return (
        <>
            {loading ? (
                <Box
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                >
                    <Spinner mt={'5rem'} size="xl" color={"blue"} thickness="2px"  />
                </Box>
            ) : (
        <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size={"md"} maxH={"720px"} w={'300px'} borderRadius={"5rem"} background={useColorModeValue('white', 'black')}>
            <ModalOverlay     bg={'blackAlpha.300'}
                              backdropFilter='blur(40px)' />
            <ModalContent borderRadius="lg">
                <ModalHeader
                    paddingY="2"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    background={useColorModeValue('white', 'black')}
                    borderBottom="1px solid"
                    borderBottomColor="gray.200"
                >
                    <Box>
                        <AmetricaLogo/>
                    </Box>
                    <ModalCloseButton color="gray.500" position="absolute" left="4" top="4" />
                </ModalHeader>
                <ModalBody p={6}
                           display="flex"
                           flexDirection="column"
                           alignItems="center"
                           bg={useColorModeValue('white', 'black')}
                           justifyContent="center">
                    <Flex
                        zIndex='2'
                        direction='column'
                        w='445px'
                        background='transparent'
                        borderRadius='15px'
                        p='10px'
                        mx={{ base: "100px" }}
                        bg={useColorModeValue('white', 'black')}
                        boxShadow={useColorModeValue(
                            "0px 5px 24px rgba(0, 0, 0, 0.05)",
                            "unset"
                        )}>
                        <Text
                            fontSize='2xl'
                            color={textColor}
                            fontWeight='bold'
                            textAlign='center'
                            mb='1rem'>
                            {headerText}
                        </Text>
                        <AuthStack/>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
            )}
        </>
    );
}

export default SingInModal;
import {
    Container,
    Heading,
    Stack,
    Text,
    Box, useColorModeValue
} from '@chakra-ui/react';
import {useEffect} from "react";

export default function TermsofService() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container maxW={'5xl'}>
            <Stack
                align={'center'}
                spacing={{ base: 3, md: 5 }}
                mt={20}
                py={{ base: 20, md: 28 }}>
                <Heading
                    textAlign={'left'}
                    fontWeight={700}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}>
                    Terms of Service
                </Heading>
                <Box px={20} py={20}  bg={useColorModeValue('white', 'gray.900')} borderRadius={"20px"}>
                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>1. Terms</Heading>
                    <Text textAlign="left">
                        By accessing this Website, accessible from ametrica.io and fx.ametrica.io, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for compliance with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>2. Use License</Heading>
                    <Text textAlign="left">
                        Permission is granted to temporarily download one copy of the materials on Ametrica Teknoloji Anonim Şirketi's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    </Text>
                    <Text textAlign="left">
                        - Modify or copy the materials;<br/>
                        - Use the materials for any commercial purpose or for any public display;<br/>
                        - Attempt to reverse engineer any software contained on Ametrica Teknoloji Anonim Şirketi's Website;<br/>
                        - Remove any copyright or other proprietary notations from the materials; or<br/>
                        - Transfer the materials to another person or "mirror" the materials on any other server.
                    </Text>
                    <Text textAlign="left">
                        This will let Ametrica Teknoloji Anonim Şirketi terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is in printed or electronic format.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>3. Disclaimer</Heading>
                    <Text textAlign="left">
                        All the materials on Ametrica Teknoloji Anonim Şirketi's Website are provided "as is". Ametrica Teknoloji Anonim Şirketi makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Ametrica Teknoloji Anonim Şirketi does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>4. Limitations</Heading>
                    <Text textAlign="left">
                        Ametrica Teknoloji Anonim Şirketi or its suppliers will not be held accountable for any damages that will arise with the use or inability to use the materials on Ametrica Teknoloji Anonim Şirketi's Website, even if Ametrica Teknoloji Anonim Şirketi or an authorized representative of this Website has been notified, orally or in writing, of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations of liability for incidental damages; these limitations may not apply to you.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>5. Revisions and Errata</Heading>
                    <Text textAlign="left">
                        The materials appearing on Ametrica Teknoloji Anonim Şirketi's Website may include technical, typographical, or photographic errors. Ametrica Teknoloji Anonim Şirketi will not promise that any of the materials in this Website are accurate, complete, or current. Ametrica Teknoloji Anonim Şirketi may change the materials contained on its Website at any time without notice. Ametrica Teknoloji Anonim Şirketi does not make any commitment to update the materials.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>6. Links</Heading>
                    <Text textAlign="left">
                        Ametrica Teknoloji Anonim Şirketi has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Ametrica Teknoloji Anonim Şirketi of the site. The use of any linked website is at the user's own risk.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>7. Site Terms of Use Modifications</Heading>
                    <Text textAlign="left">
                        Ametrica Teknoloji Anonim Şirketi may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>8. Your Privacy</Heading>
                    <Text textAlign="left">
                        Please read our Privacy Policy.
                    </Text>

                    <Heading textAlign="left" fontSize="2xl" mt={5} mb={3}>9. Governing Law</Heading>
                    <Text textAlign="left">
                        Any claim related to Ametrica Teknoloji Anonim Şirketi's Website shall be governed by the laws of Turkey without regard to its conflict of law provisions.
                    </Text>
                </Box>
            </Stack>
        </Container>
    );
}

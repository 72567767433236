import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const CurrencyView = ({ currency, textColor}) => {
    const firstCurrency = currency.substring(0, 3);

    return (
        <Box display="flex" alignItems="center" ml={'-2'}>
            <Flex justifyContent="center" alignItems="center" position="relative" w="40px" h="40px" mr={'10px'}>
                <Box position="absolute" w="24px" h="24px" overflow="hidden" borderRadius="50%" zIndex="2" border="1px solid white">
                    <Image src={`${process.env.PUBLIC_URL}/currency-logo/${firstCurrency.toLowerCase()}-logo.svg`} boxSize="24px" objectFit="cover"/>
                </Box>
            </Flex>
            <Text fontSize='md' color={textColor} fontWeight='bold'>
                {currency}{" "}
            </Text>
        </Box>
    );
};

export default CurrencyView;

'use client'

import {
    Box,
    Container,
    SimpleGrid,
} from '@chakra-ui/react'
import React from "react";
import { useTranslation } from 'react-i18next';
import Home from "../../components/Home/Home";



export default function Landing() {
    const { t } = useTranslation()
    return (
                <Home/>
    )
}
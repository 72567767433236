// slices/cryptoSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cryptoSlice = createSlice({
  name: 'crypto',
  initialState: {
    selectedCrypto: {
      name: 'Avax',
      logo: 'https://cryptologos.cc/logos/avalanche-avax-logo.png?v=025',
    },
  },
  reducers: {
    setSelectedCrypto: (state, action) => {
      state.selectedCrypto = action.payload;
    },
  },
});

export const { setSelectedCrypto } = cryptoSlice.actions;
export default cryptoSlice.reducer;

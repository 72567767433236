// app/store.js
import { configureStore } from '@reduxjs/toolkit';
import cryptoReducer from './slices/cryptoSlice';
import assetReducer from './slices/assetSlice';

export const store = configureStore({
  reducer: {
    crypto: cryptoReducer,
    asset: assetReducer,
  },
});

'use client'

import {Box, SimpleGrid, Icon, Text, Stack, Flex, AlertIcon, Alert} from '@chakra-ui/react'
import {PiChartLineUpBold, PiChartBarBold, PiFireBold, PiSparkleFill} from "react-icons/pi";
import { useTranslation } from 'react-i18next';

const Feature = ({ title, text, icon }) => {
    return (
        <Stack>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'blue.400'}
                mb={1}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text color={'gray.600'}>{text}</Text>
        </Stack>
    )
}

export default function MarketDynamicsInformation() {
    const { t } = useTranslation()
    return (
        <Box p={4}>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={10}>
                <Feature
                    icon={<Icon as={PiFireBold} w={10} h={10} />}
                    title={t('MarketDynamicsInformationHotPairs')}
                    text={
                        t('MarketDynamicsInformationHotPairsDescription')
                    }
                />
                <Feature
                    icon={<Icon as={PiChartLineUpBold} w={10} h={10} />}
                    title={t('MarketDynamicsInformationPriceChanges')}
                    text={
                        t('MarketDynamicsInformationPriceChangesDescription')
                    }
                />
                <Feature
                    icon={<Icon as={PiChartBarBold} w={10} h={10} />}
                    title={t('MarketDynamicsInformationStrengthVolatility')}
                    text={
                        t('MarketDynamicsInformationStrengthVolatilityDescription')
                    }
                />
                <Feature
                    icon={<Icon as={PiSparkleFill} w={10} h={10} />}
                    title={t('MarketDynamicsInformationSignals')}
                    text={
                        t('MarketDynamicsInformationSignalsDescription')
                    }
                />
            </SimpleGrid>
            <Alert status='info' variant='left-accent' mt={10}>
                <AlertIcon />
                 {t('DataWarningMessage')}
            </Alert>
        </Box>
    )
}
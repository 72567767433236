'use client'

import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue, Button, Box, useToast,Checkbox, useDisclosure
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import axios from "axios";
import {TbPentagonNumber1, TbPentagonNumber2, TbPentagonNumber3} from "react-icons/tb";
import ImageFinveoBanner from "../../assets/img/ImageFinveoBanner.png";
import ConfirmationModal from './ConfirmationModal';
import {useTranslation} from "react-i18next"; // Import your modal component

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametrica-fx-user-upgrade-api';

export default function FinveoSubscriptionDetail({profile}) {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // State to track checkbox
    const [redirectTimeout, setRedirectTimeout] = useState(null); // Timeout for redirect
    const [loading, setLoading] = useState(true);
    const [devicewidth, setWidth] = useState(window.innerWidth);
    const { t } = useTranslation()
    const navigateTabIBLink = () => {
        toast({
            title:  t('FinveoSubscriptionRedirectHeader'),
            description: t('FinveoSubscriptionRedirectDesc'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        setRedirectTimeout(setTimeout(() => {
          window.open('https://www.finveo.com/en/Create-Account?brd=1&sidc=ECE089C5-F5DC-4234-86A6-A8679F8630BB', '_blank');
        }, 3000)); // Redirect after 3 seconds
      };

    const navigateIBLink = () => {
        toast({
            title: t('FinveoSubscriptionRedirectHeader'),
            description: t('FinveoSubscriptionRedirectDesc'),
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
        setTimeout(() => {
            window.location.href = 'https://www.finveo.com/en/Create-Account?brd=1&sidc=ECE089C5-F5DC-4234-86A6-A8679F8630BB';
        }, 3000); // Redirect after 3 seconds
    };

    const handleFinveoRedirectClick = () => {
        // Check screen width
        if (devicewidth > 720) {
            // If screen width is greater than 720, open in new tab
            navigateTabIBLink();
        } else {
            // Otherwise, use the function for same tab redirection
            navigateIBLink();
        }
    };

    useEffect(() => {
        // Cleanup function to clear timeout if component unmounts
        return () => clearTimeout(redirectTimeout);
      }, [redirectTimeout]);
    const [autherror, setError] = useState('');
    const toast = useToast()
    const handleProfileRedirect = () => {
        // Call the openModal function passed from the parent component
        const profilePath = `${process.env.PUBLIC_URL}/#/fx/profile`;
        window.location.href = profilePath; // Redirects to the specified path
    };
    const upgradeConfirm = async () => {
        setError('');
        try {
            setLoading(true);
            const userFromStorage = localStorage.getItem('_Attributes');
            const res = await axios.post(
                cloudFunctionURL,
                {
                    token: userFromStorage
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            localStorage.setItem('_Attributes', res.data.jwt);
            localStorage.setItem('_UserTraits', res.data.userPlan);
            toast({
                title: t('FinveoSubscriptionWelcomeText'),
                description: t('FinveoSubscriptionWelcomeDesc'),
                status: 'success',
                duration: 10000, // Toast will be displayed for 10 seconds
                isClosable: true,
                position: 'top', // Display toast at the top of the screen
            });
            handleProfileRedirect();
            window.location.reload();

        } catch (error) {
            if (error.response) {
                setError(error.response.data.message);
                toast({
                    title: t('FinveoSubscriptionAuthFailedText'),
                    description: t('FinveoSubscriptionAuthFailedDesc'),
                    status: 'error',
                    duration: 10000, // Toast will be displayed for 10 seconds
                    isClosable: true,
                    position: 'bottom', // Display toast at the top of the screen
                }); // Capture and set the error message from the API response
            } else {
                setError('An error occurred while finding your finveo account. Please try again.');
                toast({
                    title: t('FinveoSubscriptionAuthFailedText'),
                    description: t('FinveoSubscriptionAuthFailedDesc'),
                    status: 'error',
                    duration: 10000, // Toast will be displayed for 10 seconds
                    isClosable: true,
                    position: 'bottom', // Display toast at the top of the screen
                });
            }
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 400);
        }
    };


    return (
        <Container maxW={'4xl'} >
                   {autherror && (
                        <Box mb="24px">
                            <Text color="red" fontSize="sm" textAlign="center">
                                {autherror}
                            </Text>
                        </Box>
                    )}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Heading>Create your Finveo Account for Free </Heading>
                    <Text color={'gray.500'} fontSize={'md'}>
                        {t('FinveoSubscriptionIntroText')} 👇 {profile.mail}
                    </Text>
                    <Stack
                    spacing={4}
                    divider={
                        <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />
                    }>
                    <Stack direction={'row'} align={'center'}>
                        <Flex w={10} h={10} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('blue.100', 'blue.500')}>
                            <Icon as={TbPentagonNumber1} color={'blue.200'} w={7} h={7} />
                        </Flex>
                        <Button w={"80%"} bg={"linear-gradient(216.66deg, rgb(29, 155, 240) 2.89%, rgb(0, 131, 235) 97.26%);"} color="white"  borderRadius={"15px"} onClick={handleFinveoRedirectClick}>Create Finveo Account Here</Button>
                    </Stack>
                    <Text color={'gray.500'} fontSize={'md'}>
                        {t('FinveoSubscriptionIntroSubText')} 👇
                    </Text>
                    <Stack direction={'row'} align={'center'}>
                        <Flex w={10} h={10} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('blue.100', 'blue.500')}>
                        <Icon as={TbPentagonNumber2} color={'blue.200'} w={7} h={7} />
                        </Flex>
                        <Checkbox colorScheme='green' isChecked={isCheckboxChecked} onChange={(e) => setIsCheckboxChecked(e.target.checked)}>
                            {t('FinveoSubscriptionConfirmText')}
                        </Checkbox>
                    </Stack>
                    <Button w={"80%"} bg={"linear-gradient(216.66deg, rgb(29, 155, 240) 2.89%, rgb(0, 131, 235) 97.26%);"} color="white" borderRadius={"15px"} onClick={upgradeConfirm} isDisabled={!isCheckboxChecked}>
                        {t('FinveoSubscriptionConfirm')}
                    </Button>
                    </Stack>
                </Stack>
                <Box
                    width={"100%"}
                  >
                    <Image  height={{sm: "150px", md: "250px", xl: "250px"}} src={ImageFinveoBanner} borderRadius='15px' />
                </Box>
            </SimpleGrid>
        </Container>
    )
}
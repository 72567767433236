import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Text,
    useToast,
    useDisclosure, Image
} from '@chakra-ui/react';
import axios from "axios";
import SignInModal from "../../components/Modals/SignInModal";
import LogoBig from "../../assets/img/AMETRICA_LOGO-BIG.png";

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-auth';

export default function Verify() {
    const [verificationStatus, setVerificationStatus] = useState('Pending Verify'); // State to track verification status
    const location = useLocation();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure(); // Modal state

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            verifyEmail(token);
        } else {
            setVerificationStatus('Invalid verification token.');
        }
    }, [location.search]);

    const verifyEmail = async (token) => {
        try {
            const res = await axios.post(cloudFunctionURL, {
                "type": "verify",
                "token": token
            });
            if (res.data.jwt) {
                localStorage.setItem('_Attributes', res.data.jwt);
                setVerificationStatus('Email verified successfully!');
            } else {
                setVerificationStatus('Failed to verify email.');
            }
        } catch (error) {
            console.error('Error verifying email:', error);
            setVerificationStatus('Error verifying email.');
        }
    };

    const handleButtonClick = () => {
        onOpen(); // Open the modal
    };

    return (
        <Box position="relative">
            <Container maxW="7xl" py={{ base: 10, sm: 20, lg: 32 }} mb={"400px"} textAlign="center" display="flex" flexDirection="column" alignItems="center">
                {/* Your logo */}
                <Box mb={4}>
                    <img src={LogoBig} alt="Logo" style={{ maxHeight: "100px", borderRadius: "15px" }} />
                </Box>
                <Box>
                    <Text
                        mb="4"
                        fontSize={verificationStatus === 'Email verified successfully!' ? '5xl' : 'xl'}
                        color={verificationStatus === 'Email verified successfully!' ? 'blue.700' : 'red.400'}
                    >
                        {verificationStatus}
                    </Text>

                    {/* Button to login account */}
                    {verificationStatus === 'Email verified successfully!' && (
                        <Button
                            bg={"#1D9BF0"}
                            borderRadius={"5rem"}
                            borderColor={"blue.400"}
                            border={"1px solid"}
                            w='60%'
                            h='45'
                            maxW='100%'
                            fontSize='16px'
                            color={"white"}
                            fontWeight='bold'
                            mb='24px'
                            onClick={handleButtonClick}
                        >
                            Sign In
                        </Button>
                    )}
                </Box>
            </Container>

            {/* Modal */}
            {isOpen && (
                <SignInModal isOpen={isOpen} onClose={() => setIsOpen(false)} headerText="Login with your verified account" />
            )}
        </Box>
    );
}

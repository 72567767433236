import {
    Box,
    Container,
    Divider,
    Flex,
    Heading,
    HStack, Select,
    Spinner,
    Stack, Tab,
    TabList, TabPanel, TabPanels,
    Tabs,
    Tag,
    Text,
    useColorMode,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import axios from "axios";
import InfoPopover from "../PairAnalyticsComponents/Indicators/InfoPopover";
import TopListTable from "./TopBuySellList/TopListTable";
import ErrorMessageBox from "./ErrorMessageBox";
import AmetricaFXProModal from "../Modals/AmetricaFXProModal";
import Card from "../Card/Card";
import { useTranslation } from 'react-i18next';

export default function TopBuyList({data}) {
    const [selectedKey, setSelectedKey] = useState('1d');
    const [longData, setLongData] = useState({});
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userPlan, setUserPlan] = useState(null);
    const { colorMode } = useColorMode();

    const { t } = useTranslation()
    const intervalsDict = {
        "5min": t("5min"),
        "15min": t("15min"),
        "1h": t("1h"),
        "4h": t("4h"),
        "1d": t("1d"),
    };


    useEffect(() => {
        const updateData = async () => {
            try {
                setLoading(true)
                const localUserPlan  = localStorage.getItem('_UserTraits');
                setUserPlan(localUserPlan)
                const long_data = data.data.mi.ta_longshort
                setLongData(long_data["LongList"])
                setTimeout(() => {
                    setLoading(false)
                }, 400);

            } catch (error) {
                console.error('Error logging in', error.response ? error.response.data.error : error.message);
                setLoading(false)
                setHasError(true);
            }
        };
        updateData();

    }, [selectedKey]);

    const isOptionDisabled = (key) => {
        // For standard users, disable all options except '1D'
        return userPlan !== 'Premium' && key !== '1d';
    };

    const handleIntervalChange = (event) => {
        const selectedValue = event.target.value;

        if (userPlan !== 'Premium' && selectedValue !== '1d') {
            setSelectedKey('1d');
            onOpen(); // Show modal or notification
        } else {
            setSelectedKey(selectedValue); // Allow changing selection
        }
    };



    const selectedData = longData[selectedKey] || [];

    return (
        <Card bg={
            colorMode === "dark"
                ? "gray.800"
                : "white"
        }>
            <Box
                maxH="700px"
                bg={useColorModeValue("white", "gray.800")}
                p={6}
                alignItems={"center"}
                justifyContent={"center"}
                display="column"
                overflowY="auto"  // Enable vertical scrolling
                sx={{
                    '&::-webkit-scrollbar': {
                        width: '10px', // Thin scrollbar
                        borderRadius: '16px', // Radius
                        backgroundColor: `rgba(0, 0, 0, 0.05)`, // Scrollbar track color
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'black', // Scrollbar color (blue)
                        borderRadius: '16px', // Radius
                    }
                }}
            >
                <Box  p={1} w={'100%'} alignItems={"flex-start"}>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                            <Stack spacing={2} align="flex-start" mb={1}>
                                <Heading fontSize="md" fontWeight={700} fontFamily="body" color= {useColorModeValue("navy.900", "gray.200")}>
                                {t('TopBuyListHeader')}
                                </Heading>
                                <Text fontSize="md" textAlign="start" fontWeight={450} fontFamily="body" color={useColorModeValue("navy.800", "gray.400")}>
                                {t('TopBuyListHeaderDescription')}
                                </Text>
                            </Stack>
                        </Flex>
                        <Flex alignItems="center">
                        <Select defaultValue="1d"  onChange={handleIntervalChange}>
                                {Object.entries(intervalsDict).map(([key, value]) => (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {isOptionDisabled(key) ? `${value} ✨` : value}
                                    </option>
                                ))}
                            </Select>
                            <InfoPopover
                                header={t('TopBuyListInfoHeader')}
                                context={t('TopBuyListInfoText')}
                            />
                        </Flex>
                        <AmetricaFXProModal isOpen={isOpen} onClose={onClose} />
                    </Flex>
                    <Divider orientation='horizontal' mb={5} />
                </Box>
                {loading ? (
                    <Spinner mt={'10rem'} size="xl" color={"blue"} thickness="2px" />
                ) : hasError ? (
                    <ErrorMessageBox/>
                ) : (
                    <TopListTable data={selectedData} interval={intervalsDict[selectedKey]}/>
                )}
            </Box>
        </Card>
    );
}
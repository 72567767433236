import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Button,
    Flex,
    Grid,
    HStack,
    Icon,
    List, ListIcon,
    ListItem,
    SimpleGrid,
    Spacer,
    Text,
    useColorMode, useDisclosure
} from "@chakra-ui/react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import FinveoSubscriptionModal from "../Modals/FinveoSubscriptionModal";
import {MdCheckCircle} from "react-icons/md";
import {TbPentagonNumber1, TbPentagonNumber2, TbPentagonNumber3} from "react-icons/tb";
import Hero2 from 'assets/img/Hero2.png';
import {useTranslation} from "react-i18next";

function Options({userPlan, profile}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const handleFinveoUpgrade = () => {
        if (!userPlan) {
          handleSignUpClick(); // Call handleSignUpClick if userPlan is empty
          return;
        }
        onOpen();
      };
    const handleSignUpClick = () => {
        // Call the openModal function passed from the parent component
        const signUpPath = `${process.env.PUBLIC_URL}/#/ametrica-fx/signup`;
        window.location.href = signUpPath; // Redirects to the specified path
    };
    const { t } = useTranslation()
    return (
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px' mb='20px'>
            <Card
                p='16px'
                bg={"linear-gradient(216.66deg, rgb(26, 29, 33) 2.89%, rgb(16, 16, 16) 97.26%), linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);"}
                h={{ sm: "450px", xl: "450px" }}
                >
                <CardBody h='100%' w='100%'>
                    <Flex
                        direction='column'
                        color='white'
                        h='100%'
                        p='0px 10px 20px 10px'
                        w='100%'>
                        <Flex justify="space-between" align="center">
                            <Text fontSize="md" fontWeight="bold">
                                {t('PremiumStarterOptionName')}
                            </Text>
                            {userPlan === "starter" && ( // Show badge if userPlan is Starter
                                <Badge align="right" colorScheme="yellow">
                                    {t('PremiumCurrenctPackage')}
                                </Badge>
                            )}
                        </Flex>
                        <HStack justify={"flex-start"}>
                            <Text fontSize='5xl' fontWeight='bold'>
                                $0
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'>
                                / {t('PremiumAnnual')}
                            </Text>
                        </HStack>
                        <Flex justify='space-between' align='center'>
                            <Text fontSize='md'>
                                {t('PremiumStarterDesc')}
                            </Text>
                            <Badge align={"right"} colorScheme={"green"}>
                                {t('PremiumStarterBadge')}
                            </Badge>
                        </Flex>
                        <Button
                            mt="1rem"
                            w="100%"
                            bg="white"
                            height="40px"
                            color="black"
                            borderRadius="4rem"
                            onClick={handleSignUpClick}
                            isDisabled={userPlan !== ""} // Disable button if userPlan is Starter
                            >
                            {t('PremiumSubscribe')}
                        </Button>
                        <Box mt={"3rem"}>
                            <List spacing={3} size={"sm"}>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumStarterList1')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumStarterList2')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumStarterList3')}
                                </ListItem >
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumStarterList4')}
                                </ListItem>
                            </List>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
            <Card
                p='16px'
                bg={"linear-gradient(216.66deg, rgb(26, 29, 33) 2.89%, rgb(16, 16, 16) 97.26%), linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);"}
                h={{ sm: "auto", xl: "auto" }}
               >
                <CardBody h='100%' w='100%'>
                    <Flex
                        direction='column'
                        color='white'
                        h='100%'
                        p='0px 10px 20px 10px'
                        w='100%'>
                        <Flex justify="space-between" align="center">
                            <Text fontSize="md" fontWeight="bold">
                                Premium✨
                            </Text>
                            {userPlan === "Premium" && ( // Show badge if userPlan is Starter
                                <Badge align="right" colorScheme="yellow">
                                    {t('PremiumCurrenctPackage')}
                                </Badge>
                            )}
                        </Flex>
                        <HStack justify={"flex-start"}>
                            <Text fontSize='5xl' fontWeight='bold'>
                                $349.99
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'>
                                / {t('PremiumAnnual')}
                            </Text>
                        </HStack>
                        <Flex justify='space-between' align='center'>
                            <Text fontSize='md'>
                                {t('PremiumPremiumDesc')}
                            </Text>
                        </Flex>
                        <Button
                            mt="1rem"
                            w="100%"
                            bg="white"
                            minHeight="40px"
                            height="50px"
                            color="black"
                            borderRadius="4rem"
                            onClick={handleFinveoUpgrade}
                            isDisabled={userPlan === "Premium"} // Disable button if userPlan is Starter
                            >
                            {t('PremiumSubscribe')}
                        </Button>
                        <Box  mt={"3rem"}>
                            <List spacing={3} size={"sm"}>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList1')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList2')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList3')}
                                </ListItem >
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList4')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList5')}
                                </ListItem >
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumPremiumList6')}
                                </ListItem>
                            </List>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
            <Card
                p='16px'
                bgImage={`url(${Hero2})`}
                bgSize="cover"
                bgPosition="center"
                h={{ sm: "auto", xl: "auto" }}
            >
                <CardBody h='100%' w='100%'>
                    <Flex
                        direction='column'
                        color='white'
                        h='100%'
                        p='0px 10px 20px 10px'
                        w='100%'>
                        <Flex justify="space-between" align="center">
                            <Text fontSize="md" fontWeight="bold">
                                Premium Trader✨
                            </Text>
                            {userPlan === "Premium" && (
                                <Badge align="right" colorScheme="yellow">
                                    {t('PremiumCurrenctPackage')}
                                </Badge>
                            )}
                        </Flex>
                        <HStack justify={"flex-start"}>
                            <Text fontSize='5xl' fontWeight='bold'>
                                $0
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'>
                                / {t('PremiumAnnual')}
                            </Text>
                        </HStack>
                        <Flex justify='space-between' align='center'>
                            <Text fontSize='md'>
                                {t('PremiumTraderDesc')}
                            </Text>
                            <Badge align={"right"} colorScheme={"green"}>
                                {t('PremiumTraderBadge')}
                            </Badge>
                        </Flex>
                        <Button
                            mt="1rem"
                            w="100%"
                            bg="white"
                            minHeight="40px"
                            height="50px"
                            color="blue.400"
                            fontWeight={"bold"}
                            borderRadius="4rem"
                            onClick={handleFinveoUpgrade}
                            isDisabled={userPlan === "Premium"}
                        >
                            {t('PremiumSubscribe')}
                        </Button>
                        <Text fontSize='md' mt={"1rem"}>
                            {t('PremiumTraderDesc2')}
                        </Text>
                        <Box mt={"3rem"}>
                            <List spacing={3} size={"sm"}>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={MdCheckCircle} color='white.500' />
                                    {t('PremiumTraderList1')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={TbPentagonNumber1} color='white.500' />
                                    {t('PremiumTraderList2')}
                                </ListItem>
                                <ListItem fontSize={"sm"}>
                                    <ListIcon as={TbPentagonNumber2} color='white.500' />
                                    {t('PremiumTraderList3')}
                                </ListItem>
                            </List>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
            <FinveoSubscriptionModal isOpen={isOpen} onClose={onClose} profile={profile} />
        </SimpleGrid>

    );
}

export default Options;

import React from 'react';
import {
    AbsoluteCenter,
    Box,
    Button,
    Divider,
    Flex,
    Text, useColorModeValue, useDisclosure,
    VStack
} from "@chakra-ui/react";
import AmetricaSignUp from "./AmetricaSignUp";
import GoogleSignUpComponent from "./GoogleSignUp";
import SignInModal from "../Modals/SignInModal";
import { useTranslation } from 'react-i18next';

const SignUpStack = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleButtonClick = () => {
        onOpen();
    };
    const { t } = useTranslation()
    return (
        <VStack spacing='15px' justify='center' mb='22px' px={15}>
            <GoogleSignUpComponent/>
            <Box position='relative' padding='10' width={"100%"}>
                <Divider colorScheme={"whiteAlpha"} size={"xl"}/>
                <AbsoluteCenter color={"white"} px='4'>
                {t('SignUpStackOr')}
                </AbsoluteCenter>
            </Box>
            <AmetricaSignUp/>
            <Flex
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                w='100%'
                mt='0px'>
                <Text color={useColorModeValue('black', 'white')} textAlign="left" fontWeight='medium' mb={3}>
                 {t('SignUpStackAlready')}
                </Text>
                <Button
                    bg={useColorModeValue('white', 'black')}
                    borderRadius={"5rem"}
                    borderColor={"5rem"}
                    border={"1px solid"}
                    w='100%'
                    h='45'
                    maxW='100%'
                    fontSize='16px'
                    color={"#1D9BF0"}
                    fontWeight='bold'
                    onClick={handleButtonClick}
                    mb='24px'
                >
                   {t('SignUpStackSignIn')}
                </Button>
            </Flex>
            {isOpen && (
                <SignInModal isOpen={isOpen} onClose={() => setIsOpen(false)} headerText={t('AmetricaSignUpLoginText')} />
            )}
        </VStack>
    );
};

export default SignUpStack;

// Chakra Icons
import { BellIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
    Avatar, Badge,
    Box, Button,
    Flex, Image, Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Stack, Text, useColorMode,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import routes from "routes.js";
import {googleLogout} from "@react-oauth/google";
import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import decodeJWT from "../../variables/functions";
import SignInModal from "../Modals/SignInModal";
import {SidebarResponsive} from "../Sidebar/SidebarResponsive";
import { useTranslation } from 'react-i18next';
import {BsMoonStarsFill, BsSun} from "react-icons/bs";
import LanguageDropdown from "../Profile/LanguageDropdown";

export default function HeaderLinks(props) {
    const { t } = useTranslation()
    const [profile, setProfile] = useState(null); // Store retrieved profile
    const { isOpen, onOpen, onClose } = useDisclosure();
    const history = useHistory();
    const {
        variant,
        children,
        fixed,
        scrolled,
        secondary,
        ...rest
    } = props;

    const { colorMode, toggleColorMode } = useColorMode();

    // Chakra Color Mode
    let navbarIcon =
        fixed && scrolled
            ? useColorModeValue("navy.700", "gray.200")
            : useColorModeValue("navy.700", "gray.200");
    let menuBg = useColorModeValue("white", "navy.800");
    if (secondary) {
        navbarIcon = "navy.700";
    }

    const handleSignInClick = () => {
        onOpen();
    };

    const handleSignUpClick = () => {
        // Call the openModal function passed from the parent component
        const signUpPath = `${process.env.PUBLIC_URL}/#/ametrica-fx/signup`;
        window.location.href = signUpPath; // Redirects to the specified path
    };
    const redirectToProfile = () => {
        const profilePath = `${process.env.PUBLIC_URL}/#/fx/profile`;
        window.location.href = profilePath; // Redirects to the specified path
    };
    const logOut = () => {
        googleLogout();
        setProfile(null);
        localStorage.removeItem('_Attributes');
        localStorage.removeItem('_UserTraits');
        history.push('/market-dynamics');
    };
    useEffect(() => {
        const userFromStorage = localStorage.getItem('_Attributes');
        if (userFromStorage) {
            const decodedToken = decodeJWT(userFromStorage);
            setProfile(decodedToken);
            localStorage.setItem('_UserTraits', decodedToken.userPlan);
        }
        else {
            localStorage.setItem('_UserTraits', "");
        }
    }, []);
    const StartFreeButton = () => {
        return (
            <Button
                ml={{ sm: 10, md: "none", xl: "none" }}
                bgColor='blue.300'
                borderRadius={"20px"}
                color={useColorModeValue("white", "white")}
                onClick={handleSignUpClick}>
                {t('AdminNavbarLinksStartFree')}
            </Button>
        );
    };

    return (
        <Flex
            pe={{ sm: "0px", md: "16px" }}
            w={{ sm: "100%", md: "auto" }}
            justify='space-between'
            flexDirection='row'>
            <SignInModal isOpen={isOpen} onClose={onClose} headerText={"Welcome to Ametrica"} />
            <SidebarResponsive
                hamburgerColor={useColorModeValue("black", "gray.50")}
                logo={
                    <Stack direction='row' spacing='6px' align='center' justify='center'>
                        {colorMode === "dark" ? (
                            <AmetricaLogo/>
                        ) : (
                            <AmetricaLogo/>
                        )}
                    </Stack>
                }
                colorMode={colorMode}
                secondary={props.secondary}
                routes={routes}
                {...rest}
            />
            {profile ? <div/> : <StartFreeButton />}
            <Flex direction='row' justifyContent={"space-between"} ml={4}>
                <Flex align='center'>
                    <LanguageDropdown/>
                </Flex>
                <Flex align='center'>
                    <Button
                        aria-label="Toggle Color Mode"
                        onClick={toggleColorMode}
                        size={"sm"}
                        _focus={{ boxShadow: 'none' }}
                        w="fit-content">
                        {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}
// Chakra imports
import {
  Avatar,
  AvatarGroup, Badge,
  Box,
  Button, Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Link, Spacer,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, {useEffect, useState} from "react";
import {
  FaArrowUp
} from "react-icons/fa";
import axios from "axios";
import SpinnerLoading from "../../components/Spinner/SpinnerLoading";
import {useHistory} from "react-router-dom";
import {BsMoonStarsFill, BsSun} from "react-icons/bs";
import LanguageDropdown from "../../components/Profile/LanguageDropdown";

function Profile() {
  const textColor = useColorModeValue("gray.700", "white");
  const headerColor = useColorModeValue("gray.700", "#1D9BF0");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "black");
  const borderProfileColor = useColorModeValue("white", "white");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const cloudFunctionURL = "https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-user"
  const { colorMode, toggleColorMode } = useColorMode(); // Hook to access color mode state and toggle function
  const switchColor = useColorModeValue("blue", "yellow"); // Adjust switch color based on color mode

  const history = useHistory(); // Initialize useHistory hook

  const handleUpgrade = () => {
    // Redirect to '#/fx/premium' when Upgrade button is clicked
    history.push("/fx/premium");
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true)
        const userFromStorage = localStorage.getItem('_Attributes') || '';
        const res = await axios.post(cloudFunctionURL, {
          token: userFromStorage,
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setUserData(res.data.user)
        setTimeout(() => {
          setLoading(false)
        }, 400);

      } catch (error) {
        console.error('Error getting user data', error.response ? error.response.data.error : error.message);
      }
    };
    getUserData();

  }, []);
  console.log(userData)
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px", lg: "100px" }}>
      {loading ? (
          <SpinnerLoading />
      ) : (
          <Box>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb='24px'
        maxH='330px'
        justifyContent={{ sm: "center", md: "space-between" }}
        align='center'
        backdropFilter='blur(21px)'
        boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
        border='1.5px solid'
        borderColor={borderProfileColor}
        bg={bgProfile}
        p='24px'
        borderRadius='20px'>
        <Flex
          align='center'
          mb={{ sm: "10px", md: "0px" }}
          direction={{ sm: "column", md: "row" }}
          w={{ sm: "100%" }}
          textAlign={{ sm: "center", md: "start" }}>
          <Avatar
              me={{ md: "22px" }}
              src={userData.picture} // Use user's profile picture
              w="80px"
              h="80px"
              bgColor={"black"}
              borderRadius="15px"
          />
          <Flex
              direction="column"
              maxWidth="100%"
              my={{ sm: "14px" }}
          >
            <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight="bold"
                ms={{ sm: "8px", md: "0px" }}
            >
              {userData.name} {/* Display user's name */}
            </Text>
            <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight="semibold"
            >
              {userData.email} {/* Display user's email */}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap='22px'>
        <Card p='16px'>
          <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={headerColor} fontWeight='bold'>
              Profile
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Flex direction='column' w='100%'>
              {/* Display User ID */}
              <Flex justifyContent='space-between' mb='21px' align='center'>
                <Text fontSize='sm' color={textColor} fontWeight='bold'>
                  User ID
                </Text>
                <Text fontSize='xs' color='gray.400' fontWeight='400'>
                  {userData.userId}
                </Text>
              </Flex>
              {/* Display Email with Verification Badge */}
              <Flex justifyContent='space-between' mb='21px' align='center'>
                <Text fontSize='sm' color={textColor} fontWeight='bold'>
                  Email
                </Text>
                <Flex align='center'>
                  <Text fontSize='xs' color='gray.400' fontWeight='400'>
                    {userData.email}
                  </Text>
                  {userData.verified ? (
                      <Box
                          ml='2'
                          px='2'
                          py='1'
                          bg='blue.200'
                          color='blue.800'
                          borderRadius='md'
                          fontSize='xs'
                          fontWeight='semibold'
                      >
                        Verified
                      </Box>
                  ) : (
                      <Badge
                          variant='solid'
                          colorScheme='red'
                          borderRadius='md'
                          px='2'
                          py='1'
                          fontSize='xs'
                          fontWeight='semibold'
                      >
                        Not Verified
                      </Badge>
                  )}
                </Flex>
              </Flex>
              {/* Display Created At */}
              <Flex justifyContent='space-between' mb='21px' align='center'>
                <Text fontSize='sm' color={textColor} fontWeight='bold'>
                  Profile Creation Day
                </Text>
                <Text fontSize='xs' color='gray.400' fontWeight='400'>
                  {userData.createdAt}
                </Text>
              </Flex>
              {/* Display User Plan */}
              <Flex justifyContent='space-between' mb='21px' align='center'>
                <Text fontSize='sm' color={textColor} fontWeight='bold'>
                  Membership Plan
                </Text>
                <Flex align='center'>
                  <Badge
                      variant='solid'
                      colorScheme={
                        userData.userPlan === 'starter'
                            ? 'gray'
                            : userData.userPlan === 'premium'
                                ? 'purple'
                                : userData.userPlan === 'premiumtrader'
                                    ? 'twitter'
                                    : 'blackAlpha'
                      }
                      borderRadius='md'
                      px='2'
                      py='1'
                      fontSize='xs'
                      fontWeight='semibold'
                  >
                    {userData.userPlan}
                  </Badge>
                  {userData.userPlan === 'starter' && (
                      <Button
                          variant='outline'
                          size='xs'
                          bg={"#1D9BF0"}
                          color={"white"}
                          leftIcon={<FaArrowUp />}
                          onClick={handleUpgrade}
                          ml='2'
                      >
                        Upgrade
                      </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card p='16px' maxW={"420px"}>
          <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={headerColor} fontWeight='bold'>
              Platform Settings
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Flex direction='column'>
              <Flex align='center' mb='20px'>
              <Text
                    noOfLines={1}
                    fontSize='md'
                    mr={5}
                    color='gray.400'
                    fontWeight='400'
                >
                  Color Mode
                </Text>
                <Button
                    aria-label="Toggle Color Mode"
                    onClick={toggleColorMode}
                    size={"sm"}
                    _focus={{ boxShadow: 'none' }}
                    w="fit-content">
                  {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
                </Button>
          
              </Flex>
              <Flex align='center' mb='20px'>
              <Text
                    noOfLines={1}
                    fontSize='md'
                    mr={5}
                    color='gray.400'
                    fontWeight='400'
                >
                  Language
                </Text>
                <LanguageDropdown/>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      <Divider colorScheme={"whiteAlpha"} size={"xl"}/>
          </Box>
        )}
    </Flex>
  );
}

export default Profile;

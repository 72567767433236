// Chakra imports
import {
    Box, Flex,
    Grid, Image, Select, Table, Tbody, Text, Th, Thead, Tr, useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import React, { useRef } from 'react';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Indicators from "./Indicators/Indicators";
import {indicatorValueData, tablesProjectData} from "../../variables/general";
import TablesProjectRow from "../Tables/TablesProjectRow";
import IndicatorsTableRow from "../Tables/IndicatorsTableRow";


function PairAnalyticsOscilator({ selectedPair }) {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const { colorMode } = useColorMode();
    // Step 1: Create a reference
    return (
        <Box>
                <Card
                    my="22px"
                    overflowX={{ sm: "scroll", xl: "hidden" }}
                    pb="0px"
                    bg={
                        colorMode === "dark"
                            ? "gray.800"
                            : "white" }
                >
                    <CardHeader p="6px 0px 22px 0px">
                        <Flex direction="flex">
                            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                                {selectedPair} Technical Analysis
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Indicators selectedPair={selectedPair} />
                    </CardBody>
                </Card>
        </Box>
    );
}

export default PairAnalyticsOscilator;

import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    IconButton,
    Text, useColorModeValue
} from "@chakra-ui/react";
import MarketDynamicsInformation from "./MarketDynamicsInformation";
import { useTranslation } from 'react-i18next';
const InformationAccordion = () => {
    const { t } = useTranslation()
    return (
        <Accordion allowToggle>
            <AccordionItem>
                <h2>
                    <AccordionButton color={useColorModeValue("gray.600", "white")} _expanded={{ bg: useColorModeValue("gray.400", "gray.700"), color: useColorModeValue("gray.100", "white") }}>
                        <Box as='span' flex='1' textAlign='left'>
                            📜{t('InformationAccordionQuestion')}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel bg={useColorModeValue("white", "black")}  color={useColorModeValue("gray.700", "white")}>
                    {t('InformationAccordionDescription')}
                    <Box bg={useColorModeValue("white", "black")} w={"100%"} h={"50%"}>
                        <MarketDynamicsInformation/>
                    </Box>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default InformationAccordion;




// import
import React from 'react';
import MarketDynamics from "views/MarketDynamics/MarketDynamics.js";
import SignIn from "views/Pages/SignIn.js";
import { HiCurrencyDollar } from "react-icons/hi2";
import {MdInsights, MdOutlineWorkspacePremium} from "react-icons/md";
import { GiSpellBook } from "react-icons/gi";
import {FaDiscord, FaTachometerAlt} from "react-icons/fa";
import {BiSupport, BiWorld} from "react-icons/bi";
import { AiTwotoneGold } from "react-icons/ai";
import PairAnalytics from "./views/MarketDynamics/PairAnalytics";
import CurrencyOverview from "./views/MarketDynamics/CurrencyOverview";
import Pricing from "./views/MarketDynamics/Pricing";
import PremiumUpgrade from "./views/Pages/PremiumUpgrade";
import LearnFX from "./views/Dashboard/LearnFX";
import Community from "./views/Dashboard/Community";
import {RiHomeLine} from "react-icons/ri";
import About from "./views/Dashboard/About";
import { HiSparkles } from "react-icons/hi";
import ChatApp from 'components/AmetricaGPT/ChatApp';
import LearnFXPage from "./components/Articles/GettingStartedwithForex";

var dashRoutes = [
  {
    path: "/market-dynamics",
    name: "Market Dynamics",
    rtlName: "لوحة القيادة",
    icon: <MdInsights color='inherit' />,
    component: MarketDynamics,
    layout: "/fx",
  },
  {
    path: "/currency-overview",
    name: "Currency Overview",
    rtlName: "لوحة القيادة",
    icon: <HiCurrencyDollar color='inherit' />,
    component: CurrencyOverview,
    layout: "/fx",
  },
  {
    path: "/pair-analytics",
    name: "Pair Analytics",
    rtlName: "لوحة القيادة",
    icon: <FaTachometerAlt  color='inherit' />,
    component: PairAnalytics,
    layout: "/fx",
  },
  {
    path: "/ametrica-gpt",
    name: "AmetricaGPT",
    rtlName: "لوحة القيادة",
    icon: <HiSparkles  color='inherit' />,
    component: ChatApp,
    layout: "/fx",
  },
  {
    name: "Learn More",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/learn-fx",
        name: "Learn FX",
        rtlName: "لوحة القيادة",
        icon: <GiSpellBook color='inherit' />,
        secondaryNavbar: true,
        component: LearnFXPage,
        layout: "/fx",
      },
      {
        path: "/premium",
        name: "Premium",
        rtlName: "لوحة القيادة",
        icon: <HiSparkles  color='inherit' />,
        component: PremiumUpgrade,
        layout: "/fx",
      },
      {
        path: "/about",
        name: "About",
        rtlName: "لوحة القيادة",
        icon: <RiHomeLine  color='inherit' />,
        component: About,
        layout: "/fx",
      }
    ],
  },
];
export default dashRoutes;

import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    Tag,
    useColorModeValue, VisuallyHidden, Button,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import {AmetricaLogo} from "../Sidebar/AmetricaLogo";
import { FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'
import {useTranslation} from "react-i18next";
const Logo = (props: any) => {
    return (
        <AmetricaLogo/>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}
const SocialButton = ({ children, label, href }) => {
    return (
        <Button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={12}
            h={12}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </Button>
    );
};

export default function AmetricaFooter() {
    const { t } = useTranslation()
    return (
        <Box
            bg={useColorModeValue('gray.50', 'black')}
            px={10}
            color={useColorModeValue('black', 'gray.200')}>
            <Container as={Stack} maxW={'12xl'} py={10} alignItems={"center"} justifyContent={"center"}>
                <Flex
                    align={'center'}
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        mr: 8,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        ml: 8,
                    }}>
                    <Logo />
                </Flex>
                <Text pt={6} fontSize={'sm'} textAlign={'center'}>
                    {t('FooterReserved')}
                </Text>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} spacing={{ base: 10, sm: 10, md: 40 }} mt={20} alignItems={"center"} justifyContent={"center"}>
                    <Stack direction={'column'} spacing={6}>
                        <SocialButton label={'Twitter'} href={'https://x.com/ametricaio'}>
                            <FaTwitter />
                        </SocialButton>
                        <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/company/ametrica'}>
                            <FaLinkedin />
                        </SocialButton>
                        <SocialButton label={'Instagram'} href={'https://www.instagram.com/ametricaio/'}>
                            <FaInstagram />
                        </SocialButton>
                    </Stack>
                    <Stack align={'left'}>
                        <ListHeader>
                            <Text color={"teal"}>
                                {t('FooterProduct')}
                            </Text>
                        </ListHeader>

                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/market-dynamics`}>
                            Market Dynamics
                        </Box>
                        <Stack direction={'row'} align={'center'} spacing={2}>
                            <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/ametrica-gpt`}>
                                AmetricaGPT
                            </Box>
                        </Stack>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/currency-overview`}>
                            Currency Overview
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/pair-analytics`}>
                            Pair Analytics
                        </Box>
                    </Stack>
                    <Stack align={'left'}>
                        <ListHeader>
                            <Text color={"teal"}>
                                {t('FooterCompany')}
                            </Text>
                        </ListHeader>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/about`}>
                            {t('FooterCompanyAbout')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/premium`}>
                            {t('FooterCompanyPremium')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/learn-fx`}>
                            {t('FooterCompanyLearnFX')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/fx/community`}>
                            {t('FooterCompanyCommunity')}
                        </Box>
                    </Stack>
                    <Stack align={'left'}>
                        <ListHeader>
                            <Text color={"teal"}>
                                {t('FooterLegal')}
                            </Text>
                        </ListHeader>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/ametrica-fx/impressum`}>
                            {t('FooterLegalImpressum')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/ametrica-fx/privacy-policy`}>
                            {t('FooterLegalPrivacy')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/ametrica-fx/cookies-policy`}>
                            {t('FooterLegalCookies')}
                        </Box>
                        <Box as="a" href={`${process.env.PUBLIC_URL}/#/ametrica-fx/terms-of-service`}>
                            {t('FooterLegalTerms')}
                        </Box>
                    </Stack>

                </SimpleGrid>
            </Container>
            <Box py={10}>
                <Text pt={6} fontSize={'sm'} textAlign={'left'}>
                    ©2024 Ametrica
                </Text>
                <Text pt={6} fontSize={'sm'} textAlign={'left'}>
                    {t('FooterLegalContext')}</Text>
            </Box>
        </Box>
    )
}
import React, { useState } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Icon,
    Link,
    Switch,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

function SignInModal({ isOpen, onClose }) {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const bgForm = useColorModeValue("white", "navy.800");
    const titleColor = useColorModeValue("gray.700", "blue.500");
    const colorIcons = useColorModeValue("gray.700", "white");
    const bgIcons = useColorModeValue("transparent", "navy.700");
    const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px)"
                />
                <ModalContent bg={bgForm} boxShadow={useColorModeValue("0px 5px 24px rgba(0, 0, 0, 0.05)", "unset")}>
                    <Flex
                        direction='column'
                        p='40px'
                        borderRadius='15px'
                        mx={{ base: "100px" }}
                        m={{ base: "20px", md: "auto" }}
                        boxShadow='none'>
                        <Text
                            fontSize='xl'
                            color={textColor}
                            fontWeight='bold'
                            textAlign='center'
                            mb='22px'>
                            Continue With
                        </Text>
                        <HStack spacing='15px' justify='center' mb='22px'>
                            <Flex
                                justify='center'
                                align='center'
                                w='75px'
                                h='75px'
                                borderRadius='8px'
                                border={useColorModeValue("1px solid", "0px")}
                                borderColor='gray.200'
                                cursor='pointer'
                                transition='all .25s ease'
                                bg={bgIcons}
                                _hover={{ bg: bgIconsHover }}>
                                <Link href='#'>
                                    <Icon
                                        as={FaGoogle}
                                        color={colorIcons}
                                        w='30px'
                                        h='30px'
                                        _hover={{ filter: "brightness(120%)" }}
                                    />
                                </Link>
                            </Flex>
                        </HStack>
                        <Text
                            fontSize='lg'
                            color='gray.400'
                            fontWeight='bold'
                            textAlign='center'
                            mb='22px'>
                            or
                        </Text>
                        <FormControl>
                            {/* Input fields and other controls */}
                        </FormControl>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            mt='0px'>
                            <Text color={textColor} fontWeight='medium'>
                                Don't have an account?
                                <Link
                                    color={titleColor}
                                    as='span'
                                    ms='5px'
                                    href='#'
                                    fontWeight='bold'>
                                    Sign Up
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </ModalContent>
            </Modal>
        </>
    );
}

export default SignInModal;

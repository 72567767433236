'use client'

import {Stack, Flex, Button, Text, VStack, useBreakpointValue, useColorModeValue} from '@chakra-ui/react'
import AboutBanner from "assets/img/AboutBanner.png"
import AboutBannerLight from "assets/img/AboutBannerLight.png"
import { useTranslation } from 'react-i18next';
export default function AboutHero() {
  const { t } = useTranslation()
  return (
    <Flex
      w={'full'}
      h={'50vh'}
      backgroundImage={
          useColorModeValue(AboutBannerLight, AboutBanner)
       }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
      borderRadius={10}
      >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-b, blackAlpha.100, transparent)'}>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
        <Text
            color={useColorModeValue("black", "white")}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl',sm: '4xl', md: '5xl' })}>
            {t('AboutHeroTitle')}</Text> 
          <Text
            color={useColorModeValue("black", "white")}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl',sm: '3xl', md: '3xl' })}>
            {t('AboutHeroSubTitle')}</Text>
        </Stack>
      </VStack>
    </Flex>
  )
}
import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    InputGroup,
    InputRightElement,
    IconButton,
    Box,
    Text, useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';

const cloudFunctionURL = 'https://europe-west3-ametrica-fx.cloudfunctions.net/ametricafx-auth';

const AmetricaLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [error, setError] = useState('');
    const { t } = useTranslation()
    const handleLogin = async () => {
        setError('');
        setLoading(true);
        
        try {
            const res = await axios.post(cloudFunctionURL, {
                type : "login",
                email: email,
                password: password,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            localStorage.setItem('_Attributes', res.data.jwt);
            history.push('/fx/market-dynamics')
            window.location.reload();
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message); // Capture and set the error message from the API response
            } else {
                setError(t('AmetricaLoginErrorMessage'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl>
            {error && (
                <Box mb="24px">
                    <Text color="red" fontSize="sm" textAlign="center">
                        {error}
                    </Text>
                </Box>
            )}
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
            </FormLabel>
            <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='text'
                placeholder={t('AmetricaSignUpEmailPlaceHolder')}
                mb='24px'
                size='md'
                value={email}
                onChange={handleEmailChange}
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
            {t('AmetricaSignUpPasswordHeader')}
            </FormLabel>
            <InputGroup>
                <Input
                    variant="auth"
                    fontSize="sm"
                    ms="4px"
                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                    placeholder={t('AmetricaSignUpPasswordPlaceHolder')}
                    mb="16px"
                    size="md"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <InputRightElement width="3.5rem">
                    <IconButton
                        h="1.75rem"
                        size="sm"
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} // Toggle icon based on showPassword state
                        onClick={togglePasswordVisibility}
                    />
                </InputRightElement>
            </InputGroup>
            <Button
                bg={useColorModeValue('white', 'white')}
                borderRadius={"5rem"}
                border={"1px solid"}
                w='100%'
                h='45'
                maxW='100%'
                fontSize='12px'
                color={useColorModeValue("#1D9BF0", 'black')}
                fontWeight='bold'
                mb='24px'
                onClick={handleLogin} // Call handleLogin when button is clicked
                disabled={loading} // Disable button while loading
            >
                {loading ? 'LOADING...' : t('SignUpStackSignIn')}
            </Button>
        </FormControl>
    );
};

export default AmetricaLogin;

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Box, Image, Button, Stack, Heading, useMediaQuery} from '@chakra-ui/react';
import Hero1 from "assets/img/Hero1.png";
import Hero2 from "assets/img/Hero2.png";
import Hero3 from "assets/img/Hero3.png";
import PairAnalyticsOscilator from "../../PairAnalyticsComponents/PairAnalyticsOscilator";
import {useTranslation} from "react-i18next";

const CustomCarousel = () => {
    const [isLargerThanMD] = useMediaQuery("(min-width: 48em)"); // 48em corresponds to the "md" breakpoint in Chakra UI
    const handleClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/fx/ametrica-gpt`;
        window.location.href = navPath; // Redirects to the specified path
    };
    const handleSignUpClick = () => {
        // Call the openModal function passed from the parent component
        const navPath = `${process.env.PUBLIC_URL}/#/ametrica-fx/signup`;
        window.location.href = navPath; // Redirects to the specified path
    };
    const { t } = useTranslation()
    return (
        <Box mt={10}>
            <Carousel showThumbs={false} autoPlay infiniteLoop interval={9000} showStatus={false}>
                <Box position="relative">
                    <Image src={Hero3} alt="Slide 1" boxSize="650px" borderRadius="15px" />
                    <Stack maxH={"450px"} position="absolute" top="35%" left="50%" transform="translate(-50%, -50%)" spacing={4}>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '2xl', md: '4xl' }}
                            color={"white"}
                            lineHeight={'100%'}>
                            {t('LandingCarousel1')} {' '}
                        </Heading>
                        {isLargerThanMD && <PairAnalyticsOscilator selectedPair={"EURUSD"} />}
                    </Stack>

                </Box>
                <Box position="relative">
                    <Image src={Hero1} alt="Slide 2" boxSize="650px" borderRadius="15px" />
                    <Stack position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" spacing={4}>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            color={"white"}
                            lineHeight={'100%'}>
                            {t('LandingCarousel2Header')} {' '}
                        </Heading>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '3xl' }}
                            color={"white"}
                            lineHeight={'100%'}>
                            {t('LandingCarousel2Desc')} {' '}
                        </Heading>
                        <Button colorScheme="teal" size="md" onClick={handleClick}>{t('LandingCarousel2Desc')} </Button>
                    </Stack>
                </Box>
                <Box position="relative">
                    <Image src={Hero2} alt="Slide 3" boxSize="650px" borderRadius="15px" />
                    <Stack position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" spacing={4}>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            color={"white"}
                            lineHeight={'100%'}>
                            {t('LandingCarousel3Header')}{' '}
                        </Heading>
                        <Button colorScheme="teal" size="md" onClick={handleSignUpClick}>{t('LandingCarousel3Button')}</Button>
                    </Stack>
                </Box>
            </Carousel>
        </Box>
    );
};

export default CustomCarousel;

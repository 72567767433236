import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import en from "../src/translation/en.json"
import tr from '../src/translation/tr.json';

const resources = {
  en: { translation: en },
  tr: { translation: tr },
};



i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || (window.navigator.language.includes('tr') ? 'en' : 'en'),
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });



export default i18n;


